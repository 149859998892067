import { Api } from "@cp/lib";
import reportsData from "./static/reports";
import reportData from "./static/report";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);
const mApi = new Api(`${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`);
const hApi = new Api(`${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`);

export default {
  namespaced: true,

  state: {
    all: [],
    report: {},
    clientPastFirstYear: false,
    reportDateRange: {},
    scheduledReports: {
      forUser: [],
      forClient: [],
      reportOptions: [],
      frequencyOptions: [],
      recipientOptions: [],
      error: null,
    },
  },

  mutations: {
    set(state, { key, value }) {
      state[key] = value;
    },

    setClientYear(state, { client_year_number, date_range }) {
      state.clientPastFirstYear = client_year_number > 0;
      state.reportDateRange = date_range;
    },

    setScheduledReportsForUser(state, reports) {
      state.scheduledReports.forUser = reports;
    },

    setScheduledReportsForClient(state, reports) {
      state.scheduledReports.forClient = reports;
    },

    setScheduledReportOptions(state, options) {
      let surveyReports = options.services.ingage
        .filter(x => x.name.startsWith("Surveys by"))
        .map(x => ({
          text: x.name,
          value: x.id,
        }));
      let priorityReports = options.services.ingage
        .filter(x => x.name.startsWith("Priorities by"))
        .map(x => ({
          text: x.name,
          value: x.id,
        }));
      let responseRateReports = options.services.ingage
        .filter(x => x.name.startsWith("Response Rate by"))
        .map(x => ({
          text: x.name,
          value: x.id,
        }));
      let otherReports = options.services.ingage
        .filter(
          x =>
            !x.name.startsWith("Surveys by") ||
            !x.name.startsWith("Priorities by") ||
            !x.name.startsWith("Response Rate by")
        )
        .map(x => ({
          text: x.name,
          value: x.id,
        }));

      state.scheduledReports.reportOptions = [
        { header: "Survey Reports" },
        ...surveyReports,
        { divider: true },
        { header: "Priority Reports" },
        ...priorityReports,
        { divider: true },
        { header: "Response Rate Reports" },
        ...responseRateReports,
        { divider: true },
        { header: "Other" },
        ...otherReports,
      ];

      state.scheduledReports.frequencyOptions = options.frequency_options.map(
        x => ({
          text: x.name,
          value: x.slug,
        })
      );
    },

    setScheduledReportsRecipients(state, recipients) {
      state.scheduledReports.recipientOptions = recipients.map(
        ({ value, label }) => ({
          value,
          text: label,
        })
      );
    },
  },

  actions: {
    async fetchAll({ commit }) {
      await bApi
        .authorize()
        .get("/reports")
        .then(({ data, client_year_number, date_range }) => {
          commit("set", { key: "all", value: data });
          commit("setClientYear", {
            client_year_number: client_year_number,
            date_range: date_range,
          });
        });
    },

    fetchOne({ commit }, { slug, year = "current_year" }) {
      return bApi
        .authorize()
        .get(`/reports/${slug}`, {
          [year]: true,
        })
        .then(data => commit("set", { key: "report", value: data }));
    },

    download(context, { slug, ...query }) {
      const queryText = Object.entries(query)
        .map(([k, v]) => `${k}=${v}`)
        .join("&");
      bApi
        .authorize()
        .download(
          `/reports/${slug}.xlsx${queryText ? `?${queryText}` : ""}`,
          `${slug}.xlsx`
        );
    },

    fetchScheduledReportsForUser({ commit }) {
      return mApi
        .authorize()
        .get("/scheduled_reports")
        .then(data =>
          commit(
            "setScheduledReportsForUser",
            data.scheduled_reports
              .filter(x => x.service_id == process.env.VUE_APP_SERVICE_ID)
              .map(x => ({
                ...x,
                report_for: "You",
              }))
          )
        );
    },

    fetchScheduledReportsForClient({ commit, rootGetters }) {
      const userId = rootGetters["auth/userId"];
      return mApi
        .authorize()
        .get("/scheduled_reports/client_scheduled_reports")
        .then(data =>
          commit(
            "setScheduledReportsForClient",
            data.scheduled_reports
              .filter(
                x =>
                  x.service_id == process.env.VUE_APP_SERVICE_ID &&
                  x.user_id != userId
              )
              .map(x => ({
                ...x,
                report_for: `${x.first_name} ${x.last_name} (${x.email})`,
              }))
          )
        );
    },

    fetchScheduledReportOptions({ commit }) {
      return mApi
        .authorize()
        .get("/reports")
        .then(data => commit("setScheduledReportOptions", data));
    },

    fetchRecipients({ commit }, query) {
      console.log("WHAT");
      return hApi
        .authorize()
        .get("/search/employees", {
          filters: {
            q_text: query,
            include_details: true,
          },
        })
        .then(({ data }) => commit("setScheduledReportsRecipients", data));
    },

    createScheduledReport({ state, dispatch }, scheduledReport) {
      state.scheduledReports.error = null;

      return mApi
        .authorize()
        .post("/scheduled_reports", scheduledReport)
        .then(data => {
          this._vm.$CpEvent.$emit("snackAlert", {
            message: "Scheduled report has been created",
          });
          this._vm.$CpEvent.$emit("scheduledReportSuccess", scheduledReport);
          dispatch("fetchScheduledReportsForUser");
          dispatch("fetchScheduledReportsForClient");
        })
        .catch(error => {
          state.scheduledReports.error = error.response.data.report[0];
        });
    },

    updateScheduledReport({ dispatch }, scheduledReport) {
      return mApi
        .authorize()
        .put(`/scheduled_reports/${scheduledReport.id}`, scheduledReport)
        .then(data => {
          this._vm.$CpEvent.$emit("snackAlert", {
            message: "Scheduled report has been updated",
          });
          this._vm.$CpEvent.$emit("scheduledReportSuccess", scheduledReport);
          dispatch("fetchScheduledReportsForUser");
          dispatch("fetchScheduledReportsForClient");
        });
    },

    deleteScheduledReport({ dispatch }, scheduledReport) {
      return mApi
        .authorize()
        .delete(`/scheduled_reports/${scheduledReport.id}`)
        .then(data => {
          this._vm.$CpEvent.$emit("snackAlert", {
            message: "Scheduled report has been removed",
          });
          dispatch("fetchScheduledReportsForUser");
          dispatch("fetchScheduledReportsForClient");
        });
    },
  },
};
