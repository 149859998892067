import { Bar, mixins } from "vue-chartjs";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import GetsMinMax from "@mixins/GetsMinMax";
// import RoundedBarChart from '@/lib/RoundedBarChart';

Chart.plugins.unregister(ChartDataLabels);

export default {
  extends: Bar,

  mixins: [mixins.reactiveProp, GetsMinMax],

  props: {
    chartData: { type: Object, required: false, default: null },
    items: { type: Object, required: true },
    target: { type: Number, required: true },
    plugins: { type: Array, default: () => [ChartDataLabels] },
    type: { type: String, default: "" },
    steps: { type: Number, required: false, default: 5 },
  },

  computed: {
    values() {
      return [this.items.min, this.items.max];
    },

    primaryValues() {
      return this.items.data.map(item => item.current_value);
    },

    comparisonValues() {
      return this.items.data.map(item => item.previous_value);
    },

    datasetPrimary() {
      let data = {
        xAxisID: "bar-x-axis",
        backgroundColor:
          this.type == "progressTracking" ? "#0070a8" : "#008A09",
        order: 3,
        barThickness: 20,
        data: this.primaryValues,
      };

      if (this.type != "progressTracking") {
        data.icons = this.items.data.map(() => "★");
        data.datalabels = {
          align: "bottom",
          anchor: "end",
          color: "#fff",
          font: {
            size: 17,
          },
          formatter: function(value, context) {
            return context.dataset.icons[context.dataIndex];
          },
          display: context => {
            return context.dataset.data[context.dataIndex] >= this.target;
          },
        };
      }

      return data;
    },

    datasetComparison() {
      if (
        !this.comparisonValues ||
        this.comparisonValues.filter(x => x != null).length <= 0
      ) {
        return null;
      }

      return {
        xAxisID: "bar-x-axis",
        backgroundColor:
          this.type == "progressTracking" ? "#009a44" : "#6b6b70",
        order: 3,
        // categoryPercentage: 0.3,
        // barPercentage: 0.7,
        barThickness: 8,
        data: this.comparisonValues,
      };
    },

    datasets() {
      let dataset = [];

      if (this.target != 0) {
        dataset.push(
          {
            type: "line",
            xAxisID: "line-x-axis",
            backgroundColor: "transparent",
            borderColor: "#0070A8",
            borderWidth: 1,
            order: 1,
            showLine: true,
            strokeColor: "#000",
            data: this.items.data.map(() => this.target),
            icons: this.items.data.map(() => ""),
            datalabels: {
              align: "right",
              anchor: "top",
              offset: -4,
              color: "#0070A8",
              font: {
                family: "icomoon",
                size: 9,
              },
              formatter: function(value, context) {
                return context.dataset.icons[context.dataIndex];
              },
              display: context => {
                return context.dataIndex < 1;
              },
            },
          },
          {
            type: "line",
            xAxisID: "line-x-axis",
            backgroundColor: "transparent",
            borderColor: "#fff",
            borderWidth: 3,
            order: 2,
            showLine: true,
            data: this.items.data.map(() => this.target),
          }
        );
      }

      dataset.unshift(this.datasetPrimary);

      if (
        this.comparisonValues &&
        this.comparisonValues.filter(x => x != null).length > 0
      ) {
        dataset.unshift(this.datasetComparison);
      }

      return dataset;
    },

    datacollection() {
      return {
        labels: this.items.data.map(item => item.label),
        datasets: this.datasets,
      };
    },

    options() {
      let options = {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        elements: { point: { radius: 0, hoverRadius: 0 } },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                min: this.min,
                max: this.max,
                stepSize: this.steps,
                fontColor: "black",
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              id: "bar-x-axis",
              ticks: {
                fontColor: "black",
              },
              gridLines: {
                display: false,
              },
            },
            {
              id: "line-x-axis",
              offset: false,
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      };

      if (this.type == "progressTracking") {
        options.scales.yAxes[0].ticks.callback = function(
          value,
          index,
          values
        ) {
          return value + "%";
        };

        options.tooltips = {
          enabled: false,
        };
      }

      return options;
    },
  },

  watch: {
    items() {
      this.updateChart();
    },
  },

  mounted() {
    window.setTimeout(() => {
      this.updateChart();
    }, 200);
  },

  methods: {
    updateChart() {
      this.$nextTick(() => {
        this.renderChart(this.datacollection, this.options);
      });
    },
  },
};
