<template>
  <main>
    <cp-app :navItems="links" @ready="setPreferences" />
  </main>
</template>

<script>
import { auth } from "@cp/lib/Client.js";
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["identity"]),
    ...mapState({ translations: state => state.translations }),
    ...mapGetters("dashboard", ["isOnboarding"]),
    ...mapGetters("auth", ["userIs"]),

    links() {
      let links = [
        { text: "Dashboard", to: "/", icon: "view-dashboard", exact: true },
      ];

      if (!this.isOnboarding) {
        links.push(
          {
            text: "Priorities",
            to: "/priorities",
            icon: "format-list-bulleted-type",
          },
          { text: "Reporting", to: "/reporting", cpIcon: "histogram" }
        );
      }

      if (this.userIs(["engage-program-manager", "intouch-admin"])) {
        links.push({
          text: "Company Profile Assessment",
          href: process.env.VUE_APP_DARKHAVEN_UI_PATH + "/profile",
          target: "_blank",
          icon: "clipboard-account-outline",
        });
      }
      return links;
    },
  },

  methods: {
    setPreferences() {
      if (this.$route.query.locale) {
        this.translations.language_preference = this.$route.query.locale;
      } else if (this.$route.name == "survey" && this.$route.params.slug) {
        // TODO - are we still sending /survey links?!!
        this.$store.dispatch(
          "fetchLanguagePreference",
          this.$route.params.slug
        );
      } else if (this.identity.locale) {
        this.translations.language_preference = this.identity.locale;
      }

      this.$CpEvent.$emit("user-loaded");
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800|Lato:300,300i,400,700,900|Roboto+Condensed:300,400,700&display=swap");

.cp-banner {
  z-index: 51 !important;
}

.app-footer {
  width: calc(100% - 24px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
