<template>
  <div>
    <v-tabs v-model="key" grow hide-slider>
      <v-tab v-for="tab in tabs" :key="tab.key">
        {{ tab.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="key">
      <v-tab-item v-for="tab in tabs" :key="tab.key">
        <div class="ing-feedback-body">
          <template v-if="items[tab.key] && items[tab.key].length > 0">
            <div v-for="(item, i) in items[tab.key]" :key="i">
              <div class="font-weight-bold">
                <template v-if="item.created_month">
                  <span>{{ item.created_month }}</span>
                  •
                </template>
                {{
                  item.region_name == "(Insufficient Data)"
                    ? "Region Confidential"
                    : item.region_name
                }}
                •
                {{
                  item.position_name == "(Insufficient Data)"
                    ? "Position Confidential"
                    : item.position_name
                }}
                <template v-if="item.user_status === 'separated'">
                  • <span class="text--red">Separated</span>
                </template>
              </div>
              <div>
                {{ item.text }}
              </div>
              <v-divider v-if="i != items[tab.key].length - 1" class="my-2" />
            </div>
          </template>
          <div v-else>
            <h4>No feedback available for selected filters.</h4>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, required: true },
    items: { type: Object, required: true },
    start: { type: Number, default: null },
  },

  data() {
    return {
      key: this.start,
    };
  },
};
</script>

<style lang="scss" scoped>
.ing-feedback-body {
  margin-top: 16px;
  max-height: 220px;
  overflow-y: auto;
}
</style>
