<template>
  <cp-card
    flush-body
    dense
    collapse-actions
    actions-icon="mdi-filter-variant"
    actions-header-text="Filters"
    class="card--engagement-breakdown card--dashboard"
    :class="{ 'elevation-0': flat }"
    :filter-count="filterCounts"
  >
    <template #title>
      <h3 :class="{ 'text-subtitle-1': withRegionsToggle }">
        {{ title }}
        <cp-tooltip right>
          <template #activator="{on}">
            <cp-btn small icon color="primary" v-on="on">
              <cp-icon small icon="info-outlined" />
            </cp-btn>
          </template>
          <slot name="tooltip"></slot>
        </cp-tooltip>
      </h3>
    </template>

    <template #actions>
      <slot name="filters"></slot>
    </template>

    <v-row v-if="noPositionData && noRegionDataAtAll">
      <v-col class="pa-4 ml-2">
        <h4>
          Sorry, but there have not been enough responses to this Priority to
          provide a breakdown.
        </h4>
      </v-col>
    </v-row>

    <div
      v-else-if="
        breakdownByRegions.data.length || breakdown.position_categories.length
      "
      class="dashboard__content"
    >
      <v-row>
        <v-col lg="6" md="6" sm="12">
          <div class="content__position-categories" style="position: relative;">
            <no-data-dialog :show="noPositionData" />
            <h2>Position Categories</h2>
            <bar-chart-horizontal :items="breakdownPositionCategories" />
          </div>
        </v-col>
        <v-col lg="6" md="6" sm="12" style="position: relative;">
          <h2>Regions</h2>
          <no-data-dialog
            :show="
              (!showAllRegions && noRegionData) ||
                (showAllRegions && noRegionDataAtAll)
            "
          />
          <div style="overflow-x: auto;">
            <div
              :style="
                `
                  width: ${breakdownByRegions.data.length * 64}px;
                  min-width: calc(100% - 24px);
                  position:relative;
                `
              "
            >
              <bar-chart
                ref="chart"
                :items="breakdownByRegions"
                :target="breakdown.sbx || 0"
                :height="250"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <h4 v-else class="loading-text">Loading...</h4>

    <template v-if="legendItems != null">
      <Legend :items="legendItems" />
    </template>
    <template v-else>
      <div class="d-flex justify-center chart-legend chart-legend--border">
        <span class="footer__info">
          <span class="info__circle info__circle--your-score"></span>
          Current Score
        </span>
        <span class="footer__info">
          <span class="info__circle info__circle--previous-score"></span>
          Previous Score
        </span>
        <span class="footer__info">
          <span class="info__circle info__circle--sb-index"></span>
          SB Index
        </span>
      </div>
    </template>
  </cp-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Breakdown" },
    breakdown: { type: Object, required: true },
    withRegionsToggle: { type: Boolean, default: false },
    legendItems: { type: Array, required: false, default: null },
    flat: { type: Boolean, default: false },
    filters: { type: Object, default: () => {} },
  },

  data() {
    return {
      showAllRegions: true,
    };
  },

  computed: {
    breakdownPositionCategories() {
      return {
        min: this.minLimit(this.breakdown.ranges.position_categories.min),
        max: this.maxLimit(this.breakdown.ranges.position_categories.max),
        data: [
          ...this.breakdown.position_categories.map(
            ({ name, current, previous }) => ({
              label: name,
              current_value: Math.round(current.aiq) || null,
              previous_value: Math.round(previous.aiq) || null,
              sbx: Math.round(current.sbx) || null,
            })
          ),
        ],
      };
    },

    allRegionData() {
      return {
        min: this.minLimit(this.breakdown.ranges.regions.min),
        max: this.maxLimit(this.breakdown.ranges.regions.max),
        data: [
          ...this.breakdown.regions.map(({ name, current, previous }) => ({
            label: name,
            current_value: Math.round(current.aiq) || null,
            previous_value: Math.round(previous.aiq) || null,
          })),
        ],
      };
    },

    breakdownByRegions() {
      if (this.showAllRegions) {
        return this.allRegionData;
      }

      return this.allRegionData.data.filter(
        region => region.value !== null && region.value < this.breakdown.sbx
      );
    },

    barChartWidth() {
      return this.breakdownByRegions.data.length * 45;
    },

    noPositionData() {
      return (
        !this.breakdownPositionCategories.data.filter(
          ({ current_value }) => current_value
        ).length &&
        !this.breakdownPositionCategories.data.filter(
          ({ previous_value }) => previous_value
        ).length
      );
    },

    noRegionData() {
      return (
        !this.breakdownByRegions.data.filter(
          ({ current_value }) => current_value
        ).length &&
        !this.breakdownByRegions.data.filter(
          ({ previous_value }) => previous_value
        ).length
      );
    },

    noRegionDataAtAll() {
      return (
        !this.allRegionData.data.filter(({ current_value }) => current_value)
          .length &&
        !this.allRegionData.data.filter(({ previous_value }) => previous_value)
          .length
      );
    },

    filterCounts() {
      let selections = [];

      Object.keys(this.filters).forEach(key => {
        selections.push(!!this.filters[key]);
      });

      return selections.filter(x => x).length;
    },
  },

  methods: {
    maxLimit(value) {
      let max = Math.round(value) + 5;
      return max >= 100 ? 100 : max;
    },

    minLimit(value) {
      let min = Math.round(value) - 5;
      return min <= 0 ? 0 : min;
    },
  },

  mounted() {
    if (this.noRegionData && !this.noRegionDataAtAll) {
      this.showAllRegions = true;
    }
  },
};
</script>

<style lang="scss">
.loading-text {
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}
</style>
