import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-fullscreen-modal',{on:{"show":_vm.fetch},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.button)?_c('cp-btn',_vm._g({attrs:{"rounded":"","outlined":""}},on),[_vm._v(" Select Action Item ")]):_c('div',{staticClass:"pattern__single-line-click"},[(_vm.actionPlanItem.user_assigned)?_c('a',_vm._g({},on),[_c('cp-card',{staticClass:"background--gray",attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"single-line-click__text"},[_vm._v(" You must select an Action Item ")])]),_c(VCol,{staticClass:"single-line-click__last",attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"last__icon",attrs:{"icon":"chevron-right"}})],1)],1)],1)],1):_c('a',{staticClass:"disabled"},[_c('cp-card',{staticClass:"background--gray",attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"single-line-click__text"},[_vm._v("None yet selected")])])],1)],1)],1)])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('cp-modal',{attrs:{"title":"Choose Action Item"},on:{"back":close,"close":close}},[_c('cp-card',{staticClass:"action-item-detail"},[_c('div',{staticClass:"padding-20"},[_c('h6',[_vm._v("Select an Action Item from the list below.")])]),_c('cp-card',{staticClass:"summary-values gray-background",attrs:{"dense":"","flushBody":"","outlined":""}},[_c('cp-card',{staticClass:"gray-background action-items single-select",attrs:{"dense":"","flushBody":"","outlined":""}},_vm._l((_vm.actionItems),function(item){return _c('cp-card',{key:item.id,staticClass:"single-select__option",attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,[_c('label',[_c(VRow,{attrs:{"align":"center","justify":"start","dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"option__icon-type",attrs:{"icon":"swift-bunny-icon"}})],1),_c(VCol,{staticClass:"multi-select__option__description no-select"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)]),_c(VCol,{attrs:{"cols":"auto"}},[_c('action-item-detail',{attrs:{"item":item,"arrow-only":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('cp-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.start(item)}}},[_vm._v(" Start Action Item ")])]},proxy:true}],null,true)})],1)],1)],1)}),1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }