<template>
  <div v-show="show" class="c-modal is-active">
    <div class="c-modal__inner u-rounded">
      <div class="c-modal__form">
        <svg class="c-modal__close o-icon o-icon--close" @click="close">
          <use xlink:href="#o-icon--close" />
        </svg>
        <div class="c-modal__title">
          <h4 class="o-heading o-heading--medium">{{ title }}</h4>
        </div>
        <div class="c-modal__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    show: { type: Boolean, default: false },
  },

  created() {
    const escapeHandler = e => {
      if (e.key === "Escape" && this.show) {
        this.close();
      }
    };

    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
