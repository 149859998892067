import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-fullscreen-modal',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.arrowOnly)?_c('cp-btn',_vm._g({attrs:{"icon":""}},on),[_c('cp-icon',{staticClass:"option__icon-arrow",attrs:{"icon":"chevron-right"}})],1):(_vm.textArrowOnWhite)?_c('a',_vm._g({},on),[_c('cp-card',{staticClass:"background--gray",attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,{staticClass:"single-line-click__first",attrs:{"cols":"auto"}},[_c('div',{staticClass:"swift-letter-boxed"},[_c('cp-icon',{attrs:{"icon":"swift-bunny-icon"}})],1)]),_c(VCol,[_c('div',{staticClass:"single-line-click__text"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),_c(VCol,{staticClass:"single-line-click__last",attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"last__icon",attrs:{"icon":"chevron-right"}})],1)],1)],1)],1):_c('div',{staticClass:"pattern__single-line-click"},[_c('a',_vm._g({},on),[_c('cp-card',{staticClass:"background--gray",attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,[_c('div',{staticClass:"single-line-click__text"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),_c(VCol,{staticClass:"single-line-click__last",attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"last__icon",attrs:{"icon":"chevron-right"}})],1)],1)],1)],1)])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('cp-modal',{attrs:{"title":"Action Item Detail"},on:{"back":close,"close":close}},[_c('cp-card',{staticClass:"action-item-detail"},[_c('div',{staticClass:"padding-20"},[_c('h6',[_vm._v("Action Item")]),_c('h6',{staticClass:"alt-text"},[_vm._v(_vm._s(_vm.item.title))])]),_c('cp-card',{staticClass:"gray-background",attrs:{"dense":"","flushBody":"","outlined":""}},[_c('h6',[_vm._v("Description")]),_c('h6',{staticClass:"alt-text",domProps:{"innerHTML":_vm._s(_vm.item.body)}}),(_vm.item.attachments.length)?[_c('hr'),_c('h6',[_vm._v("Downloadable Resources")]),_c('div',{staticClass:"pattern__single-line-click"},_vm._l((_vm.item.attachments),function(attachment,index){return _c('a',{key:index,attrs:{"href":attachment.url,"target":"_blank"}},[_c('cp-card',{attrs:{"dense":"","flushBody":"","outlined":""}},[_c(VRow,{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c(VCol,{staticClass:"single-line-click__first",attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"first__icon",attrs:{"icon":"swift-bunny-icon"}})],1),_c(VCol,[_c('div',{staticClass:"single-line-click__text"},[_vm._v(" "+_vm._s(attachment.name)+" ")])]),_c(VCol,{staticClass:"single-line-click__last",attrs:{"cols":"auto"}},[_c('cp-icon',{staticClass:"last__icon",attrs:{"icon":"chevron-right"}})],1)],1)],1)],1)}),0)]:_vm._e()],2),(_vm.$slots.actions)?_c('div',{staticClass:"card__footer"},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c('div',{staticClass:"ml-auto"},[_c('cp-btn',{attrs:{"color":"primary","text":""},on:{"click":close}},[_vm._v(" Cancel ")]),_vm._t("actions")],2)])],1)],1):_vm._e()],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }