<template>
  <cp-fullscreen-modal @show="fetch">
    <template #activator="{on}">
      <cp-btn v-if="button" rounded outlined v-on="on">
        Select Action Item
      </cp-btn>
      <div v-else class="pattern__single-line-click">
        <a v-if="actionPlanItem.user_assigned" v-on="on">
          <cp-card class="background--gray" dense flushBody outlined>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col>
                <div class="single-line-click__text">
                  You must select an Action Item
                </div>
              </v-col>
              <v-col class="single-line-click__last" cols="auto">
                <cp-icon class="last__icon" icon="chevron-right" />
              </v-col>
            </v-row>
          </cp-card>
        </a>
        <a v-else class="disabled">
          <cp-card class="background--gray" dense flushBody outlined>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col>
                <div class="single-line-click__text">None yet selected</div>
              </v-col>
            </v-row>
          </cp-card>
        </a>
      </div>
    </template>
    <template #default="{close}">
      <cp-modal title="Choose Action Item" @back="close" @close="close">
        <cp-card class="action-item-detail">
          <div class="padding-20">
            <h6>Select an Action Item from the list below.</h6>
          </div>
          <cp-card
            class="summary-values gray-background"
            dense
            flushBody
            outlined
          >
            <cp-card
              class="gray-background action-items single-select"
              dense
              flushBody
              outlined
            >
              <cp-card
                v-for="item in actionItems"
                :key="item.id"
                class="single-select__option"
                dense
                flushBody
                outlined
              >
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col>
                    <label>
                      <v-row align="center" justify="start" dense>
                        <v-col cols="auto">
                          <cp-icon
                            icon="swift-bunny-icon"
                            class="option__icon-type"
                          />
                        </v-col>
                        <v-col
                          class="multi-select__option__description no-select"
                        >
                          {{ item.title }}
                        </v-col>
                      </v-row>
                    </label>
                  </v-col>
                  <v-col cols="auto">
                    <action-item-detail :item="item" arrow-only>
                      <template #actions>
                        <cp-btn color="primary" outlined @click="start(item)">
                          Start Action Item
                        </cp-btn>
                      </template>
                    </action-item-detail>
                  </v-col>
                </v-row>
              </cp-card>
            </cp-card>
          </cp-card>
        </cp-card>
      </cp-modal>
    </template>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    actionPlanItem: { type: Object, required: true },
    button: { type: Boolean, default: false },
  },

  computed: {
    ...mapState({
      actionItems: state => state.actionPlans.actionItems,
    }),
  },

  methods: {
    fetch() {
      this.$store.dispatch("fetchActionItems", {
        topic: this.actionPlanItem.topic.id,
      });
    },

    start(item) {
      this.$store.dispatch("updateActionPlanItem", {
        data: {
          action_plan_item: { action_item_id: item.id, status: "in_progress" },
        },
        id: this.actionPlanItem.id,
      });
    },
  },
};
</script>
