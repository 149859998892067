<template>
  <cp-card class="mt-4">
    <section class="content">
      <v-row>
        <v-col :sm="12" :md="6">
          <cp-card color="grey lighten-3" dense flat>
            <h6 class="mb-4" v-html="assignedOnText"></h6>
            <h6 v-if="assignment.completed_at" class="mb-3">
              Completed
              <span class="font-weight-bold">
                {{ completedOn }}
              </span>
            </h6>
            <h6 v-else class="mb-3">
              Due by
              <span class="font-weight-bold">{{ dueBy }}</span>
              |
              <span v-html="daysRemaining"></span>
            </h6>
            <h6>
              <span class="font-weight-bold">{{
                assignment.target_regions.length
              }}</span>
              {{ "Region" | pluralize(assignment.target_regions.length) }}
              Targeted
              <cp-tooltip right>
                <template #activator="{on}">
                  <cp-btn small icon class="ml-0 mb-1" v-on="on">
                    <cp-icon icon="info-outlined" small color="#0070A8" />
                  </cp-btn>
                </template>
                <div
                  v-for="(region, index) in assignment.target_regions"
                  :key="index"
                >
                  {{ region.name }}
                  <br />
                </div>
              </cp-tooltip>
            </h6>
            <h6>
              <span class="font-weight-bold">{{
                assignment.target_positions.length
              }}</span>
              Position
              {{ "Category" | pluralize(assignment.target_positions.length) }}
              Targeted
              <cp-tooltip right>
                <template #activator="{on}">
                  <cp-btn small icon class="ml-0 mb-1" v-on="on">
                    <cp-icon icon="info-outlined" small color="#0070A8" />
                  </cp-btn>
                </template>
                <div
                  v-for="(target_position,
                  index) in assignment.target_positions"
                  :key="index"
                >
                  {{ target_position.name }}
                  <br />
                </div>
              </cp-tooltip>
            </h6>
          </cp-card>

          <v-row class="action-item-description mt-3">
            <v-col>
              <h3 class="font-weight-bold">Action Item Description</h3>
              <h6 class="alt-text" v-html="assignment.action_item.body"></h6>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <template v-if="assignment.action_item.attachments.length">
                <h3 class="font-weight-bold">Downloadable Resources</h3>
                <single-row-link
                  v-for="(attachment, index) in assignment.action_item
                    .attachments"
                  :key="index"
                  :href="attachment.url"
                  target="_blank"
                  :title="attachment.name"
                  class="my-2"
                  showSBIcon
                />
              </template>
            </v-col>
          </v-row>

          <template v-if="hasKeynoteBody || hasKeynoteAttachment">
            <v-divider class="mt-3 mb-2" />
            <v-row>
              <v-col>
                <h3 class="font-weight-bold">
                  Additional Instructions From {{ creator.full_name }}
                </h3>
                <div
                  v-if="hasKeynoteBody"
                  class="alt-text"
                  v-html="assignment.key_note.body"
                ></div>
              </v-col>
            </v-row>

            <v-row v-if="hasKeynoteAttachment">
              <v-col>
                <single-row-link
                  :href="assignment.key_note.attachment.url"
                  target="_blank"
                  :title="assignment.key_note.attachment.name"
                  class="my-2"
                />
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col :sm="12" :md="6">
          <div class="activities">
            <v-row class="activities__header" align="center">
              <v-col class="pa-2 pl-3">
                <h4>Assignment Activity Log</h4>
              </v-col>

              <v-col class="text-right pa-1 pr-3">
                <cp-dialog
                  v-if="!readonly && [creator.id, assignee.id].includes(userId)"
                  width="500"
                  header-background
                >
                  <template #activator>
                    <h4 class="activities__header__create-note">
                      <v-icon>add</v-icon>
                      Add New Note
                    </h4>
                  </template>
                  <template #title>
                    Add a New Note
                  </template>
                  <template #body>
                    <p>
                      Leaving a note is a great way to keep track of the
                      progress of your assignment and they're viewable by anyone
                      with access to this assignment.
                    </p>
                    <notes-field v-model="newNote" />
                  </template>
                  <template #actions="{close}">
                    <v-col cols="auto" class="ml-auto">
                      <cp-btn color="error" text @click="close">
                        Cancel
                      </cp-btn>
                    </v-col>
                    <v-col cols="auto">
                      <cp-btn
                        :disabled="saving"
                        color="success"
                        @click="createNote(close)"
                      >
                        {{ saving ? "Saving..." : "Create Note" }}
                      </cp-btn>
                    </v-col>
                  </template>
                </cp-dialog>
              </v-col>
            </v-row>
            <v-expansion-panels
              v-if="assignment.activity_log_items.length"
              class="activities__list-wrapper"
              :accordion="true"
              :flat="true"
              :tile="true"
            >
              <v-expansion-panel
                v-for="activity in assignment.activity_log_items"
                :key="activity.id"
                v-model="assignment.activity_log_items"
                :readonly="!activity.content"
                class="activities__activity"
              >
                <v-expansion-panel-header
                  class="activities__activity__header"
                  :hide-actions="!activity.content"
                  :class="{ 'no-content': !activity.content }"
                  expand-icon="mdi-menu-down"
                >
                  <v-row align="end" no-gutters>
                    <v-col>
                      <h4>{{ activity.created_at }}</h4>
                      <div class="mt-2">
                        <v-icon
                          v-if="activity.content && activity.content.file.name"
                          style="margin-bottom: -3px;"
                        >
                          mdi-paperclip
                        </v-icon>
                        <span
                          class="font-weight-bold"
                          :class="{
                            'activities__header__title-with-icon':
                              activity.content && activity.content.file,
                          }"
                        >
                          {{ activity.title }}
                        </span>
                      </div>
                    </v-col>
                    <v-col align="right">
                      {{ activity.user }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-if="activity.content"
                  class="activities__activity__content"
                >
                  <cp-card color="grey lighten-3" flat class="mt-3" dense>
                    {{ activity.content.body }}
                    <template v-if="activity.content.file">
                      <a
                        :href="activity.content.file.url"
                        target="_blank"
                        class="mt-2 display-block"
                      >
                        {{ activity.content.file.name }}
                      </a>
                    </template>
                  </cp-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <h1 v-else class="text-center pa-3">No activity yet</h1>
          </div>
        </v-col>
      </v-row>
    </section>
  </cp-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    assignment: { type: Object, default: null },
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      newNote: {
        body: null,
        attachment: null,
      },
    };
  },

  computed: {
    ...mapState({
      saving: state => state.assignments.saving,
    }),
    ...mapGetters("auth", ["userId"]),

    creator() {
      return this.assignment.creator;
    },

    assignee() {
      return this.assignment.assignee;
    },

    assignedOnText() {
      let creatorMailLink = this.buildEmail(
        this.creator.full_name,
        this.creator.email
      );
      let assigneeMailLink = this.buildEmail(
        this.assignee.full_name,
        this.assignee.email
      );

      if (
        this.creator.id == this.assignee.id &&
        this.creator.id == this.userId
      ) {
        return `You assigned yourself on <span class="font-weight-bold">${this.assignedOn}</span>`;
      }

      if (this.creator.id == this.userId) {
        return `You assigned ${assigneeMailLink} on <span class="font-weight-bold">${this.assignedOn}</span>`;
      }

      if (this.assignee.id == this.userId) {
        return `You were assigned this by ${creatorMailLink} on <span class="font-weight-bold">${this.assignedOn}</span>`;
      }

      return `${creatorMailLink} assigned this to ${assigneeMailLink} on <span class="font-weight-bold">${this.assignedOn}</span>`;
    },

    daysRemaining() {
      let currentDate = moment().endOf("day");
      let dueDate = moment(this.assignment.due_by).endOf("day");
      let days = dueDate.diff(currentDate, "days");

      if (days >= 0) {
        return `<span class="font-weight-bold">${days}</span> ${this.$pluralize(
          "day",
          Math.abs(days)
        )} remaining`;
      } else {
        return `<span class="font-weight-bold">${Math.abs(
          days
        )}</span> ${this.$pluralize("day", Math.abs(days))} overdue`;
      }
    },

    completedOn() {
      return moment(this.assignment.completed_at).format("MM/DD/YYYY");
    },

    assignedOn() {
      return moment(this.assignment.assigned_at).format("MM/DD/YYYY");
    },

    dueBy() {
      return moment(this.assignment.due_by).format("MM/DD/YYYY");
    },

    hasKeynoteBody() {
      return this.assignment.key_note.body ? true : false;
    },

    hasKeynoteAttachment() {
      if (!this.assignment.key_note.attachment) return false;

      return this.assignment.key_note.attachment.name ? true : false;
    },
  },

  methods: {
    buildEmail(name, email) {
      return `<a href='mailto:${email}'>${name}</a>`;
    },

    createNote(closeDialog) {
      this.$store
        .dispatch("createNote", {
          data: {
            assignment: {
              note_attributes: {
                body: this.newNote.body,
                attachment_attributes: {
                  file: this.newNote.attachment,
                },
              },
            },
          },
          assignmentId: this.assignment.id,
        })
        .then(() => {
          if (closeDialog) {
            closeDialog();
          }
          this.newNote = {
            body: null,
            attachment: null,
          };
        });
    },
  },
};
</script>

<style lang="scss">
.activities {
  .activities__header {
    border-bottom: 1px solid $gray-2;
    margin: 0;
    background-color: $gray-7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 600;
    color: $gray-4;
  }

  .activities__header__create-note {
    color: $blue--main;
    cursor: pointer;
    display: inline-block;
    font-size: 0.85rem;

    .v-icon {
      color: $blue--main;
      margin-top: -1px;
    }
  }

  .activities__list-wrapper {
    max-height: 500px;
    overflow-y: scroll;
    border-bottom: 4px solid $blue--main;
  }

  .activities__activity {
    border-bottom: 1px solid $gray-2;
    padding: 10px;
  }

  .activities__activity__header {
    align-items: flex-end;
    padding: 0;
    color: $gray-4;
    &.v-expansion-panel-header {
      min-height: auto;
    }
    &.no-content {
      cursor: default;

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  .activities__header__title-with-icon {
    position: relative;
    bottom: -4px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

.cp-card--gray.pattern__clickable--icon-slide {
  border: 1px solid $gray-7;
  .cp-icon {
    fill: $gray-5;
  }
  &:hover {
    border: 1px solid $gray-3;
    text-decoration: none;
  }
}
</style>
