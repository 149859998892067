<template>
  <v-row no-gutters align="center" class="mt-n4 mb-2">
    <v-col v-if="$slots['header']">
      <h3 class="page-header my-0 header-name">
        <span class="name"><slot name="header"/></span>
      </h3>
    </v-col>
    <v-col v-if="$slots['page-info']" align="right">
      <cp-btn
        icon
        class="page-info-trigger"
        :class="{ 'info-is-active': infoActive }"
        @click="infoActive = !infoActive"
      >
        <cp-icon icon="info-outlined" />
      </cp-btn>
      <v-dialog v-model="infoActive" width="900" origin="top right 0">
        <v-card>
          <v-card-title>
            <slot name="page-info-title"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="page-info"></slot>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import VueCookies from "vue-cookies";
export default {
  data: () => ({
    infoActive: false,
  }),
  created() {
    if (VueCookies.get(`viewed_info_for_${this.$route.name}`) != "true") {
      let $this = this;
      setTimeout(function() {
        $this.infoActive = true;
        VueCookies.set(`viewed_info_for_${$this.$route.name}`, "true");
      }, 1000);
    }
  },
};
</script>

<style scoped lang="scss">
@keyframes attention-ping {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 0.4;
    transform: scale(3);
  }

  80% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.page-info-trigger {
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: rgba($white, 0.4);
  }

  &.info-is-active {
    background-color: $white;

    &:after {
      transform: scale(1);
      transform-origin: center center;
      opacity: 0;
      animation: attention-ping 2s infinite;
      content: "";
      display: block;
      background-color: rgba($white, 0.3);
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    svg {
      fill: $blue--main;
    }
  }

  svg {
    fill: $white;
  }
}
</style>
