import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{attrs:{"title-color":_vm.assignment.completed_at ? '#009a44' : '#0070A8'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('cp-icon',{attrs:{"icon":_vm.assignment.completed_at ? 'assignment-complete' : 'assignment',"x-large":"","color":"rgba(255, 255, 255, .5)"}}),_c('div',{staticClass:"d-inline-block ml-4",staticStyle:{"vertical-align":"middle"}},[_c('h6',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.assignment.action_item.title)+" ")]),_c('div',{staticClass:"text-body-2 white--text"},[_vm._v(" Addressing Priority: "),_c('a',{staticClass:"white--text font-weight-bold",attrs:{"href":("/priorities/" + (_vm.assignment.topic.id)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.assignment.topic.body)+" ")])])])]},proxy:true},{key:"actions",fn:function(){return [(_vm.canEdit)?_c('router-link',{attrs:{"to":{
        name: 'assignments.edit',
        params: { id: _vm.assignment.id },
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var navigate = ref.navigate;
return [_c('cp-btn',{attrs:{"color":"white","text":"","rounded":""},on:{"click":navigate}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)]}}],null,false,188124609)}):_vm._e(),(_vm.assignment.completed_at)?_c(VIcon,{attrs:{"color":"white","large":""}},[_vm._v(" mdi-check-decagram ")]):_vm._e()]},proxy:true}])},[_c('section',{staticClass:"content pa-0"},[(
        _vm.assignment.follow_up_statuses.includes(_vm.assignment.current_status) &&
          _vm.assignment.status != 'follow_up_completed'
      )?_c('timeline',{staticClass:"mt-5",attrs:{"id":"timeline","steps":_vm.assignment.follow_up_statuses,"current-step-index":_vm.assignment.follow_up_statuses.indexOf(_vm.assignment.current_status),"current-step-detail-text":_vm.timelineDetailText}}):(
        !['follow_up_skipped', 'follow_up_completed'].includes(
          _vm.assignment.status
        )
      )?_c('timeline',{staticClass:"mt-5",attrs:{"id":"timeline","steps":_vm.assignment.statuses,"current-step-index":_vm.assignment.statuses.indexOf(_vm.assignment.current_status),"current-step-detail-text":_vm.timelineDetailText}}):_vm._e(),(_vm.shouldShowScoreSummary)?_c('assignment-results',{attrs:{"assignment":_vm.assignment}}):_vm._e(),_c(VRow,{staticClass:"pa-4 pt-0",attrs:{"align":"center"}},[_c(VCol,{attrs:{"align":"right"}},[(_vm.assignment.status == 'refused')?_c('h1',[_vm._v(" "+_vm._s(_vm.assignee.full_name)+" has refused this assignment. "),(_vm.isProgramManager)?_c('span',[_vm._v("Edit this assignment to select a new Action Team Member.")]):_vm._e()]):_vm._e(),(_vm.canAccept)?[_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"error","text":"","large":""}},[_vm._v(" Refuse ")])]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Refusing Assignment ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" While not required, please considering leaving a note to inform your Program Manager why you won't be accepting this assignment. "),_c('cp-textarea',{staticClass:"mt-2 mb-2",attrs:{"outlined":"","label":"Reason for Refusal","hide-details":""},model:{value:(_vm.statusUpdateNote),callback:function ($$v) {_vm.statusUpdateNote=$$v},expression:"statusUpdateNote"}})]},proxy:true},{key:"actions",fn:function(ref){
      var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":"","large":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.updateStatus('refused', close)}}},[_vm._v(" Refuse Assignment ")])],1)]}}],null,false,2858048298)}),_c('cp-btn',{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.updateStatus('in_progress')}}},[_vm._v(" Accept ")])]:_vm._e(),(_vm.canSubmit)?[_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"primary","large":""}},[_vm._v(" Submit for Approval ")])]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Submitting Assignment ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Are you sure you're ready to mark your assignment completed, and notify your Program Manager? ")]},proxy:true},{key:"actions",fn:function(ref){
      var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus('awaiting_review', close)}}},[_vm._v(" Submit Assignment ")])],1)]}}],null,false,1724918844)})]:_vm._e(),(_vm.canApprove || _vm.canStartFollowup)?[_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":"","title":"Rejecting Assignment"},scopedSlots:_vm._u([(_vm.canApprove)?{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"error","text":"","large":""}},[_vm._v(" Reject ")])]},proxy:true}:null,{key:"title",fn:function(){return [_vm._v(" Rejecting Assignment ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" While not required, please considering leaving a note to inform your Action Team Member why you aren't approving this assignment. "),_c('cp-textarea',{staticClass:"mt-2 mb-2",attrs:{"outlined":"","label":"Reason for Rejecting","hide-details":""},model:{value:(_vm.statusUpdateNote),callback:function ($$v) {_vm.statusUpdateNote=$$v},expression:"statusUpdateNote"}})]},proxy:true},{key:"actions",fn:function(ref){
      var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":"","large":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.updateStatus('reject', close)}}},[_vm._v(" Reject Assignment ")])],1)]}}],null,true)}),_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":"","title":"Completing the Assignment"},scopedSlots:_vm._u([(_vm.canApprove)?{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"primary","large":""}},[_vm._v(" Approve ")])]},proxy:true}:null,{key:"title",fn:function(){return [_vm._v(" Completing the Assignment ")]},proxy:true},{key:"body",fn:function(ref){
      var close = ref.close;
return [_c(VStepper,{staticClass:"elevation-0",model:{value:(_vm.followUpStep),callback:function ($$v) {_vm.followUpStep=$$v},expression:"followUpStep"}},[_c(VStepperItems,[_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"1"}},[_c('p',[_vm._v(" Once you have approved the Assignment as complete, you may choose to conduct an optional Follow-Up Survey. This brief survey is sent only to employees in the targeted regions and positions and asks them to evaluate the Priority which was impacted by this Assignment. The purpose of the Follow-Up Survey is to determine if the actions taken had the desired impact. ")]),_c(VRow,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus(
                              'ready_for_follow_up',
                              _vm.advanceStepper
                            )}}},[_vm._v(" Approve Assignment ")])],1)],1)],1),_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"2"}},[_c('p',[_vm._v(" If you'd like to conduct a follow-up survey, we recommend waiting a period of "+_vm._s(_vm.assignment.action_item.follow_up_delay)+" days before the survey goes out. This allows time for your employees to experience the results of this Assignment before being asked to measure it again. You may enter a custom time period, however, if you'd rather adjust the send date. ")]),_c('cp-input',{attrs:{"autofocus":"","label":"Custom Days to delay","type":"number","min":"1","placeholder":"Number of Days","outlined":"","hide-details":"","oninput":"validity.valid||(value='');"},model:{value:(_vm.followUpDelay),callback:function ($$v) {_vm.followUpDelay=$$v},expression:"followUpDelay"}}),_c('h1',{staticClass:"mt-3 mb-3"},[_vm._v(" Follow-Up Survey Send Date: "),_c('strong',[_vm._v(_vm._s(_vm.followUpDay))])]),_c(VRow,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus('awaiting_follow_up', close)}}},[_vm._v(" Schedule Follow-Up ")])],1)],1)],1)],1)],1)]}}],null,true)})]:_vm._e(),(_vm.canStartFollowup)?[_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"error","text":"","large":""}},[_vm._v(" Skip Follow-Up ")])]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Skip Follow-Up? ")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Skipping the follow-up process will place this assignment into the history of this Priority. Are you sure you want to do this? ")]},proxy:true},{key:"actions",fn:function(ref){
                            var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":"","large":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.updateStatus('follow_up_skipped', close)}}},[_vm._v(" Yes, Skip Follow-Up ")])],1)]}}],null,false,3958151772)}),_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":"","title":"Configuring the Follow-Up"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"primary","large":""}},[_vm._v(" Configure Follow-Up ")])]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Configuring the Follow-Up ")]},proxy:true},{key:"body",fn:function(ref){
                            var close = ref.close;
return [_c('p',[_vm._v(" If you'd like to conduct a follow-up survey, we recommend waiting a period of "+_vm._s(_vm.assignment.action_item.follow_up_delay)+" days before the survey goes out. This allows time for your employees to experience the results of this Assignment before being asked to measure it again. You may enter a custom time period, however, if you'd rather adjust the send date. ")]),_c('cp-input',{attrs:{"autofocus":"","label":"Custom Days to delay","type":"number","min":"1","placeholder":"Number of Days","outlined":"","hide-details":"","oninput":"validity.valid||(value='');"},model:{value:(_vm.followUpDelay),callback:function ($$v) {_vm.followUpDelay=$$v},expression:"followUpDelay"}}),_c('h1',{staticClass:"mt-3 mb-3"},[_vm._v(" Follow-Up Survey Send Date: "),_c('strong',[_vm._v(_vm._s(_vm.followUpDay))])]),_c(VRow,[_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus('awaiting_follow_up', close)}}},[_vm._v(" Schedule Follow-Up ")])],1)],1)]}}],null,false,2071287945)})]:_vm._e(),(_vm.readyForFinalReview)?[_c('cp-dialog',{attrs:{"width":"500","header-background":"","inline-activator":"","title":"Filing Away the Assignment"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('cp-btn',{attrs:{"color":"primary","large":""}},[_vm._v(" Acknowledge Results ")])]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Filing Away the Assignment ")]},proxy:true},{key:"body",fn:function(){return [_c('h1',[_vm._v(" You will always be able to review the results of this assignment underneath the History section of this Priority. ")])]},proxy:true},{key:"actions",fn:function(ref){
                            var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatus('follow_up_completed', close)}}},[_vm._v(" Complete Assignment Follow-Up ")])],1)]}}],null,false,4017097222)})]:_vm._e(),(_vm.showAdvanceBtn)?[_c('cp-btn',{attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.advanceFollowUpProcess()}}},[_vm._v(" Advance Time (QA ONLY) ")])]:_vm._e()],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }