<template>
  <div>
    <img
      src="@/assets/sb-loader.gif"
      style="display: block; margin: auto; width: 100px; height: 100px;"
    />
  </div>
</template>

<script>
export default {};
</script>
