<template>
  <div class="assignments--show">
    <assignment-header v-if="assignmentReady" :assignment="assignment" />
    <assignment-detail v-if="assignmentReady" :assignment="assignment" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "AssignmentsShow",

  props: {
    priorityId: { required: true },
    assignmentId: { required: true },
  },

  computed: {
    ...mapGetters("auth", ["userIs"]),
    isProgramManager() {
      return this.userIs("engage-program-manager");
    },

    ...mapState({
      assignment: state => state.assignments.assignment,
    }),

    assignmentReady() {
      return Object.keys(this.assignment).length >= 1;
    },
  },

  created() {
    this.$store.dispatch("fetchAssignment", this.assignmentId);
  },
};
</script>
