import Vue from "vue";
import App from "./App.vue";
import { CarrotPatchV2, router, store, vuetify } from "@cp/install";
import VuePluralize from "vue-pluralize";
import "./styles/global.scss";
import "./styles/ingage-ui.scss";

Vue.use(VuePluralize);
Vue.use(CarrotPatchV2);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount("#app");
