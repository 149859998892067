import { Api } from "@cp/lib";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  namespaced: true,

  state: {
    summary: {
      date_range: {
        previous_year: {
          start_date: null,
        },
        current_year: {
          start_date: null,
        },
      },
    },
    onboarding_summary: {},
    breakdown: {
      position_categories: [],
      regions: [],
      ranges: {
        position_categories: { min: 0, max: 100 },
        regions: { min: 0, max: 100 },
      },
    },
    feedback: null,
    priorities: {
      strengths: [],
      opportunities: [],
    },
    surveys: [],
  },

  getters: {
    isOnboarding({ summary }) {
      return !!summary.onboarding;
    },
  },

  mutations: {
    setSummary(state, summary) {
      state.summary = summary;
    },

    setOnboardingSummary(state, summary) {
      state.onboarding_summary = summary;
    },

    setBreakdown(state, breakdown) {
      state.breakdown = breakdown;
    },

    setFeedback(state, feedback) {
      state.feedback = feedback;
    },

    setPriorities(state, priorities) {
      state.priorities = priorities;
    },

    setSurveys(state, { surveys }) {
      state.surveys = surveys.map(survey => survey.attributes);
    },
  },

  actions: {
    fetchSummary({ commit }, filters = {}) {
      bApi
        .authorize()
        .get(`/dashboard/summary`, { filters })
        .then(({ data }) => commit("setSummary", data));
    },

    fetchOnboardingSummary({ commit }) {
      bApi
        .authorize()
        .get(`/dashboard/onboarding`)
        .then(({ data }) => commit("setOnboardingSummary", data));
    },

    fetchBreakdown({ commit }, filters = {}) {
      bApi
        .authorize()
        .get("/dashboard/breakdown", {
          ...filters,
          type: filters.type || "engagement",
        })
        .then(({ data }) => commit("setBreakdown", data));
    },

    fetchFeedback({ commit }, filters = {}) {
      bApi
        .authorize()
        .get("/dashboard/feedback", filters)
        .then(data => {
          commit("setFeedback", data);
        });
    },

    fetchPriorities({ commit }, filters = {}) {
      bApi
        .authorize()
        .get("/dashboard/priorities", filters)
        .then(({ data }) => commit("setPriorities", data));
    },

    fetchSurveys({ commit }) {
      bApi
        .authorize()
        .get("/dashboard/feedback_surveys")
        .then(({ data }) => commit("setSurveys", { surveys: data }));
    },

    exportPriorities({}, filters = {}) {
      let filename = "Lifecycle Priority Report";

      filename += ` - ${
        filters.survey_slug ? filters.survey_name : "All Surveys"
      }`;
      filename += ` - ${
        filters.region_id ? filters.region_name : "All Regions"
      }`;
      filename += ` - ${new Date().toLocaleDateString("en-US")}.csv`;

      bApi
        .authorize()
        .download(
          "/reporting/dashboard/lifecycle-priority-report",
          filename,
          filters
        );
    },

    exportFeedback({}, filters = {}) {
      let filename = "Feedback Report";

      filename += ` - ${
        filters.survey_slug ? filters.survey_name : "All Surveys"
      }`;
      filename += ` - ${
        filters.region_id ? filters.region_name : "All Regions"
      }`;
      filename += ` - ${new Date().toLocaleDateString("en-US")}.csv`;

      bApi.authorize().download(`/dashboard/feedback.csv`, filename, filters);
    },
  },
};
