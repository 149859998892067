import { Api } from "@cp/lib";
import regionsData from "./static/regions";
import positionsData from "./static/positionCategories";
import moment from "moment";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  state: {
    regions: [],
    positions: [],
    clientReportSummary: {},
    clientReport: {},
    surveyLink: "",
  },

  mutations: {
    setClientReports(state, { clients }) {
      state.clientReportSummary = clients;
    },

    setClientReport(state, { report, surveyLink }) {
      state.surveyLink = surveyLink;
      state.clientReport = report;
    },

    setRegions(state, { regions }) {
      state.regions = regions.regions_list;
    },

    setPositions(state, { positions }) {
      state.positions = positions.position_category_list;
    },
  },

  actions: {
    fetchClientReports({ commit }, survey_slug = null) {
      bApi
        .authorize()
        .get("/reporting", { survey_slug: survey_slug })
        .then(({ data }) => commit("setClientReports", { clients: data }));
    },

    fetchClientReport({ commit }, { survey_slug = null, client_uuid }) {
      const surveyLink = `${process.env.VUE_APP_SITE_PATH}/survey/${survey_slug}/${client_uuid}`;

      bApi
        .authorize()
        .get(`/reporting/${survey_slug}/${client_uuid}`)
        .then(report => commit("setClientReport", { report, surveyLink }));
    },

    fetchRegions({ commit }, requestParams = null) {
      bApi
        .authorize()
        .get("/action-groups/regions", requestParams)
        .then(({ data }) => commit("setRegions", { regions: data }));
    },

    async fetchPositions(
      { commit },
      { regions = [], id = null, topicID = null }
    ) {
      await bApi
        .authorize()
        .get("/action-groups/position-categories", {
          regions,
          assignment_id: id,
          topic_id: topicID,
        })
        .then(({ data }) => commit("setPositions", { positions: data }));
    },

    fetchSurveyExport(context, { client_uuid, client_name, survey_slug }) {
      bApi
        .authorize()
        .download(
          `/reporting/${survey_slug}/${client_uuid}/export`,
          `${client_name}_covid_survey_export.csv`
        );
    },

    fetchAllClientResultsExport(context, { survey_slug }) {
      bApi
        .authorize()
        .download(
          `/reporting/${survey_slug}/specialty-survey-all-clients-responses-overview`,
          `${survey_slug}_all_client_survey_responses.csv`
        );
    },
  },
};
