<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3">
        <cp-card style="height: 100%;">
          <v-row no-gutters>
            <v-col cols="7">
              <h2 class="big-green-text mt-7 mb-2">
                {{ onboarding_summary.response_rate }}%
              </h2>
              <h4 class="card-subtitle">Response Rate</h4>
              <h4 class="card-subtitle-small">Target response rate 60%</h4>
            </v-col>
            <v-col cols="5" align="right">
              <donut-chart
                :outer-chart-data="onboarding_summary.response_rate"
                :display-current-value="false"
                :sb-index="60"
                :is-percent="true"
                :include-previous="false"
                main-color="rgba(0, 138, 9, 1)"
                small
                :thickness="75"
              />
            </v-col>
          </v-row>
        </cp-card>
      </v-col>

      <v-col
        v-for="(info, index) in infoCards"
        :key="index"
        cols="12"
        md="6"
        lg="3"
      >
        <cp-card style="position: relative; height: 100%;">
          <v-row no-gutters>
            <v-col cols="8">
              <h2 class="big-green-text mt-7 mb-2">{{ info.value }}</h2>
              <h4 class="card-subtitle">{{ info.subtitle }}</h4>
            </v-col>
            <v-col cols="4" align="right">
              <div class="card-info-lineart mt-3">
                <img :src="info.lineart.simple" />
              </div>
            </v-col>
          </v-row>
        </cp-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <cp-card flush-body class="card--engagement-overview">
          <v-row class="pt-0 pb-0 pa-4">
            <v-col sm="12" lg="4">
              <message-picker :onboarding-summary="onboarding_summary" />
            </v-col>
            <v-col sm="12" lg="8">
              <div class="day-tracker-chart">
                <div class="d-flex align-center mt-2 mb-4">
                  <h3 class="flex-grow-1">Completions by Day</h3>
                  <v-card flat color="#eeeeee" class="flex-shrink-1">
                    <v-card-text class="pt-2 pb-2 pl-0 pr-2">
                      <v-chip
                        label
                        color="#eeeeee"
                        class="font-weight-bold pr-1"
                      >
                        Survey Start:
                      </v-chip>
                      <v-chip label color="#ffffff" class="pl-1 pr-1 mr-3">
                        {{ onboarding_summary.survey_start_date }}
                      </v-chip>
                      <v-chip
                        label
                        color="#eeeeee"
                        class="font-weight-bold pr-1"
                      >
                        Survey End:
                      </v-chip>
                      <v-chip label color="#ffffff" class="pl-1 pr-1">
                        {{ onboarding_summary.survey_close_date }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </div>
                <div
                  v-if="onboarding_summary.chart_data != null"
                  class="day-tracker-chart__chart"
                  :class="{ 'fade-out': !chartDataAvailable }"
                >
                  <line-chart
                    ref="chart"
                    :primary-values="
                      onboarding_summary.chart_data.map(x =>
                        x.surveys_started === null ? null : x.surveys_started
                      )
                    "
                    :secondary-values="
                      onboarding_summary.chart_data.map(x =>
                        x.surveys_completed === null
                          ? null
                          : x.surveys_completed
                      )
                    "
                    :min="0"
                    :max="chartMax"
                    :height="280"
                    :steps="chartSteps"
                    :labels="onboardingChartLabels"
                  />
                  <div class="day-tracker-chart__chart__milestone-wrapper">
                    <div class="day-tracker-chart__chart__milestone start-date">
                      <div
                        class="day-tracker-chart__chart__milestone__marker dot"
                      ></div>
                      <div class="day-tracker-chart__chart__milestone__text">
                        Start
                      </div>
                    </div>

                    <div class="day-tracker-chart__chart__milestone reminder-1">
                      <div class="day-tracker-chart__chart__milestone__marker">
                        <v-icon>
                          mdi-bell
                        </v-icon>
                      </div>
                      <div class="day-tracker-chart__chart__milestone__text">
                        Reminder 1
                      </div>
                    </div>

                    <div class="day-tracker-chart__chart__milestone reminder-2">
                      <div class="day-tracker-chart__chart__milestone__marker">
                        <v-icon>
                          mdi-bell
                        </v-icon>
                      </div>
                      <div class="day-tracker-chart__chart__milestone__text">
                        Reminder 2
                      </div>
                    </div>

                    <div class="day-tracker-chart__chart__milestone reminder-3">
                      <div class="day-tracker-chart__chart__milestone__marker">
                        <v-icon>
                          mdi-bell
                        </v-icon>
                      </div>
                      <div class="day-tracker-chart__chart__milestone__text">
                        Reminder 3
                      </div>
                    </div>

                    <div class="day-tracker-chart__chart__milestone reminder-4">
                      <div class="day-tracker-chart__chart__milestone__marker">
                        <v-icon>
                          mdi-bell
                        </v-icon>
                      </div>
                      <div class="day-tracker-chart__chart__milestone__text">
                        Reminder 4
                      </div>
                    </div>

                    <div class="day-tracker-chart__chart__milestone end-date">
                      <div
                        class="day-tracker-chart__chart__milestone__marker dot"
                      ></div>
                      <div
                        class="day-tracker-chart__chart__milestone__text"
                        style="position: absolute; left: -35px"
                      >
                        End
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <Legend
            :items="legendItems"
            :class="{ 'fade-out': !chartDataAvailable }"
          />
        </cp-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="auto" class="flex-grow-0" style="width: 396px;">
        <cp-card align="center" class="full-height">
          <div class="cpa-wrapper">
            <h2>Company Profile</h2>
            <p v-if="onboarding_summary.profile_completion >= 100">
              Thank you for taking the time to complete your profile!
            </p>
            <p v-else>
              You still have some more work to do to complete your profile.
            </p>
            <donut-chart
              :outer-chart-data="onboarding_summary.profile_completion || 0"
              :allow-zero="true"
              :sb-index="null"
              :is-percent="true"
              :include-previous="false"
              main-color="rgba(0, 138, 9, 1)"
              :thickness="78"
              medium
            />
            <cp-btn
              v-if="onboarding_summary.profile_completion >= 100"
              :href="cpaURL"
              target="_blank"
              color="primary"
              large
              class="mt-4"
            >
              View or Edit Profile
            </cp-btn>
            <cp-btn
              v-else
              :href="cpaURL"
              target="_blank"
              color="primary"
              large
              class="mt-4"
            >
              Let's Finish the Profile!
            </cp-btn>
          </div>
        </cp-card>
      </v-col>

      <v-col
        cols="12"
        md="auto"
        class="flex-grow-1 flex-shrink-0"
        style="width: calc(100% - 396px)"
      >
        <cp-card dense flush-body class="full-height">
          <template #title>
            <h3>Progress by Percent Started/Completed</h3>
          </template>

          <template #actions>
            <v-select
              :class="{ 'fade-out': !chartDataAvailable }"
              v-model="activeBreakdown"
              dense
              :items="breakdowns"
            />
          </template>

          <div
            v-if="breakdown"
            class="pa-4 flex-grow-1"
            :class="{ 'fade-out': !chartDataAvailable }"
          >
            <bar-chart
              ref="chart"
              :type="'progressTracking'"
              :items="generatedChartData"
              :target="0"
              :height="350"
              :min="0"
              :max="100"
              :steps="25"
            />
          </div>
          <Legend
            :items="legendItemsPercent"
            class="flex-grow-0"
            :class="{ 'fade-out': !chartDataAvailable }"
          />
        </cp-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      breakdowns: [
        {
          text: "By Region",
          value: "regions",
        },
        {
          text: "By Position Category",
          value: "positions",
        },
      ],
      activeBreakdown: "regions",
    };
  },

  computed: {
    ...mapState({
      onboarding_summary: state => state.dashboard.onboarding_summary,
      breakdown: state => state.dashboard.onboarding_summary.breakdown,
    }),

    legendItems() {
      return [
        {
          color: "your-score",
          text: "Surveys Started",
        },
        {
          color: "sb-index",
          text: "Surveys Completed",
        },
      ];
    },

    legendItemsPercent() {
      return [
        {
          color: "your-score",
          text: "Percent Started",
        },
        {
          color: "sb-index",
          text: "Percent Completed",
        },
      ];
    },

    infoCards() {
      return [
        {
          value: this.onboarding_summary.surveys_sent,
          subtitle: "Surveys Sent",
          lineart: {
            fun: this.getImagePath("sent_fun.svg"),
            simple: this.getImagePath("sent_simple.svg"),
          },
        },
        {
          value: this.onboarding_summary.surveys_started,
          subtitle: "Surveys Started",
          lineart: {
            fun: this.getImagePath("started_fun.svg"),
            simple: this.getImagePath("started_simple.svg"),
          },
        },
        {
          value: this.onboarding_summary.surveys_completed,
          subtitle: "Surveys Completed",
          lineart: {
            fun: this.getImagePath("completed_fun.svg"),
            simple: this.getImagePath("completed_simple.svg"),
          },
        },
      ];
    },

    chartMax() {
      let max = Math.max.apply(
        Math,
        this.onboarding_summary.chart_data.map(function(x) {
          if (x.surveys_started < x.surveys_completed) {
            return x.surveys_completed;
          }
          return x.surveys_started;
        })
      );

      if (max < 100) {
        return 100;
      }

      return Math.ceil(max / 10) * 10;
    },

    chartSteps() {
      return this.chartMax / 5;
    },

    generatedChartData() {
      let items = {
        data: [],
        min: 0,
        max: 100,
      };

      if (this.breakdown == null) {
        return false;
      }

      items.data = this.breakdown[this.activeBreakdown].map(item => ({
        label: item.name,
        total: item.surveys_sent,
        current_value: Math.round(
          (item.survey_completed / item.surveys_sent) * 100
        ),
        previous_value: Math.round(
          (item.surveys_started / item.surveys_sent) * 100
        ),
      }));

      return items;
    },

    chartDataAvailable() {
      if (!this.generatedChartData) {
        return false;
      }

      return this.generatedChartData.data.length > 0;
    },

    onboardingChartLabels() {
      // decrement the day by one to prep for the loop below
      const date = moment(this.onboarding_summary.survey_start_date).subtract(
        1,
        "day"
      );
      return this.onboarding_summary.chart_data.map((x, i) => {
        date.add(1, "day");
        return date.format("MMM D");
      });
    },

    cpaURL() {
      return process.env.VUE_APP_DARKHAVEN_UI_PATH + "/profile";
    },
  },

  created() {
    this.$store.dispatch("dashboard/fetchOnboardingSummary");
  },

  methods: {
    getImagePath(image) {
      return require(`@/assets/onboarding-dashboard/${image}`);
    },

    exportFeedback() {
      this.$store.dispatch("dashboard/exportFeedback");
    },
  },
};
</script>

<style scoped lang="scss">
.big-green-text {
  font-size: 2.5rem;
  color: $green--main;
}

.card-subtitle {
  font-size: 1.2rem;
}

.card-subtitle-small {
  font-size: 0.75rem;
  font-weight: 700;
}

.card-info-lineart {
  width: 100px;
  height: 100px;
  position: relative;
  border: 2px solid $gray-4;
  border-radius: 100%;

  img {
    width: 70%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}

.day-tracker-chart {
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;

  h3 {
    font-size: 1.15rem;
  }

  .v-chip {
    height: 20px;

    &:hover:before {
      background-color: $white;
    }
  }
}

.day-tracker-chart__chart {
  position: relative;
  padding-top: 16px;
  z-index: 2;

  @media (max-width: 1440px) {
    margin-top: 30px;
  }

  .day-tracker-chart__chart__label {
    position: absolute;
    bottom: 5px;
    left: -5px;
    font-size: 0.8rem;
    color: $gray-5;
  }

  .day-tracker-chart__chart__milestone-wrapper {
    width: calc(100% - 36px);
    height: 95%;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 26px;
    z-index: -1;
  }

  .day-tracker-chart__chart__milestone {
    white-space: nowrap;
    height: 91%;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;

    .day-tracker-chart__chart__milestone__marker {
      display: inline-block;
      vertical-align: top;
      height: 98%;
      margin-top: 10px;
      width: 1px;
      border-left: 1px dashed $gray-2;

      .v-icon {
        position: absolute;
        top: 2px;
        left: -7px;
        color: $gray-2;
        font-size: 14px;
        // give some space between the icon and the vertical line
        background: #fff;
        border-bottom: 3px solid #fff;
      }

      &.dot:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: $gray-2;
        position: absolute;
        top: 6px;
        left: -3px;
      }
    }

    .day-tracker-chart__chart__milestone__text {
      display: inline-block;
      vertical-align: top;
      padding-left: 8px;
      color: $gray-4;
      font-weight: 500;
    }

    @media (max-width: 1440px) {
      &:not(:last-child) .day-tracker-chart__chart__milestone__text {
        transform: rotate(-15deg);
        transform-origin: 0 50%;
      }
    }

    &.start-date {
      width: 98.84%;
    }
    &.reminder-1 {
      width: 88.9%;
    }
    &.reminder-2 {
      width: 54.2%;
    }
    &.reminder-3 {
      width: 29.5%;
    }
    &.reminder-4 {
      width: 14.7%;
    }
    &.end-date {
      width: 0%;
      margin-right: -3px;
    }

    @media (max-width: 1440px) {
      &.start-date {
        width: 99.2%;
      }
      &.end-date {
        width: 0;
        margin-right: 0;
      }
    }
  }
}

.cpa-wrapper {
  width: 340px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}

.full-height {
  height: 100%;
}

// .fade-out {
//   // opacity: 0.35;
//   // pointer-events: none;
// }
</style>
