export const responseKeysForQuestion = function(question) {
  switch (question.kind) {
    case "agreement-importance":
    case "Agreement/Importance":
      return ["agreement", "importance"];
    case "agreement":
    case "Agreement":
      return ["agreement"];
    case "importance":
    case "Importance":
      return ["importance"];
    case "likelihood":
    case "Likelihood":
      return ["likelihood"];
    case "text-entry":
    case "Text Entry":
      return ["text_entry"];
    case "numeric-entry":
    case "Numeric Entry":
      return ["numeric_entry"];
    case "yes-no":
    case "Yes/No":
      return ["yes_no"];
    case "multiple-select":
    case "Multiple Select":
      return ["multiple_select"];
    case "multiple-choice":
    case "Multiple Choice":
      return ["multiple_choice"];
    default:
      return [];
  }
};

export const questionKeyPairs = function(question) {
  return responseKeysForQuestion(question).map(key =>
    questionKeyPair(question.id, key)
  );
};

export const questionKeyPair = function(questionId, key) {
  return `${questionId}|${key}`;
};

export const compileLocalResponse = function(question, key, value) {
  const response = { type: question.parent_id ? "child_question" : "question" };
  response[key] = value;

  return { key: questionKeyPair(question.id, key), value: response };
};
