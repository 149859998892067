// coppied from ./Api.js
export function mergeAttributes(items, extraAttributes = {}) {
  if (Array.isArray(items)) {
    return items.map(mergeAttributes);
  }

  let id = items.id;

  if (Array.isArray(id)) {
    id = id.join(",");
  }

  return {
    id: id,
    ...items.attributes,
    ...extraAttributes,
  };
}
