<template>
  <v-card
    color="#8B5DCB"
    rounded
    class="dynamic-box"
    flat
    style="height: 100%; width: 100%;"
  >
    <v-card-text>
      <v-row
        align="center"
        class="flex-column flex-sm-row-reverse flex-lg-column"
      >
        <v-col>
          <h2 class="flex-grow-0 font-weight-bold">
            {{ selectedMessage.title }}
          </h2>
          <p class="flex-grow-0" v-html="selectedMessage.body"></p>
          <cp-btn
            v-if="selectedMessage.cta"
            :href="selectedMessage.cta.url"
            target="_blank"
            color="white"
            large
            class="mt-4"
          >
            {{ selectedMessage.cta.title }}
          </cp-btn>
        </v-col>
        <v-col
          v-if="selectedMessage.image"
          class="dynamic-box__image"
          cols="6"
          sm="3"
          md="2"
          lg="12"
        >
          <img
            :src="
              require(`@/assets/onboarding-dashboard/${selectedMessage.image}`)
            "
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

const today = moment().startOf("day");
const messages = [
  // before the survey starts
  {
    test: ({ currentDay }) => currentDay < 0,
    title: "Welcome!",
    body:
      "We're so excited to see you get started.<br><br> Until your baseline survey starts, please check out the link to your Company Profile Assessment below.",
    cta: {
      title: "Company Profile Assessment",
      url: process.env.VUE_APP_DARKHAVEN_UI_PATH + "/profile",
    },
  },

  // first day
  {
    test: ({ currentDay }) => currentDay === 0,
    title: "And we're off!",
    body:
      "We sent the first email this morning. You should start to see results coming in, but it can help if you send an additional message to remind everyone to check their emails and complete the survey.",
    image: "first_day.svg",
  },

  // reminders 1-3 day before
  {
    test: ({ currentDay }) => [1, 8, 13].includes(currentDay),
    title: "Get Ready!",
    body:
      "We're going to send a reminder tomorrow. Everyone who hasn't completed the survey yet will get another email.",
    image: "get_ready.svg",
  },

  // reminders 1-3 day of
  {
    test: ({ currentDay }) => [2, 9, 14].includes(currentDay),
    title: "Let's see...",
    body:
      "We sent a reminder this morning. We should see more completions today.",
    image: "reminder_1.svg",
  },

  // reminder 4 day before
  {
    test: ({ currentDay }) => currentDay === 16,
    title: "Last reminder!",
    body:
      "We're going to send one last reminder email tomorrow. If there are people who haven't responded, you may want to reach out some other way.",
    image: "reminder_4.svg",
  },

  // reminder 4 day of
  {
    test: ({ currentDay }) => currentDay === 17,
    title: "The home stretch!",
    body:
      "We sent the last reminder email this morning. If there are still people who haven't responded, you may want to reach out some other way.",
    image: "home_stretch.svg",
  },

  // last day
  {
    test: ({ currentDay }) => currentDay === 20,
    title: "Last chance!",
    body:
      "The survey closes tonight. If there are still people who haven't responded, you may want to reach out some other way.",
    image: "last_day.svg",
  },

  // after the survey ends
  {
    test: ({ currentDay }) => currentDay > 20,
    title: "Survey Complete!",
    body:
      "We are digging through the feedback, and are looking forward to presenting your results soon!",
    image: "survey_complete.svg",
  },

  // 89% + completion default
  {
    test: ({ percentComplete }) => percentComplete >= 90,
    title: "Congratulations!",
    body: "More than 90% have responded to the baseline survey! Keep going!",
    image: "90_percent.svg",
  },

  // 80% + completion default
  {
    test: ({ percentComplete }) => percentComplete >= 80,
    title: "Congratulations!",
    body: "You've past the 80% goal for the baseline survey! Keep going!",
    image: "80_percent.svg",
  },

  // 70% + completion default
  {
    test: ({ percentComplete }) => percentComplete >= 70,
    title: "Congratulations!",
    body: "You're past the 70% completion point for the baseline survey!",
    image: "70_percent.svg",
  },

  // 60% + completion default
  {
    test: ({ percentComplete }) => percentComplete >= 60,
    title: "Congratulations!",
    body: "You're past the 60% completion point for the baseline survey!",
    image: "60_percent.svg",
  },

  // Default - All other days...
  {
    test: () => true,
    title: "Welcome!",
    body:
      "You can visit this dashboard any time during your onboarding and see how many of your Engagement surveys have been completed.",
    image: "congratulations_simple.svg",
  },
];

export default {
  props: {
    onboardingSummary: { type: Object, required: true, default: () => {} },
  },

  computed: {
    selectedMessage() {
      const startDate = moment(
        this.onboardingSummary.survey_start_date,
        "MM/DD/YYYY"
      );
      const currentDay = today.diff(startDate, "days");
      const percentComplete = parseInt(this.onboardingSummary.response_rate);
      return messages.find(m =>
        m.test({
          startDate,
          currentDay,
          percentComplete,
        })
      );
    },
  },
};
</script>

<style scoped lang="scss">
.dynamic-box {
  display: inline-block;
  text-align: center;

  .v-card__text {
    height: 100%;
  }

  h2 {
    color: $white;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    color: $white;
  }
}
.dynamic-box__content {
  display: flex;
  flex-direction: row-reverse;
}
.dynamic-box__image img {
  max-width: 100%;
  max-height: 150px;
  height: auto;
}
</style>
