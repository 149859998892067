import { Api } from "@cp/lib";
import VueCookies from "vue-cookies";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}`);

export default {
  state: {
    available_locales: [
      {
        text: "English",
        value: "en",
      },
    ],
    language_preference: "en",
    terms: {},
    landing_page: {},
    success_page: {},
    language_initialized: false,
  },

  mutations: {
    setAvailableLocales(state, locales) {
      state.available_locales = locales;
    },

    setTerms(state, terms) {
      state.terms = terms;
    },

    setLanguagePreference(state, locale) {
      state.language_preference = locale || "en";
      VueCookies.set("language_preference", state.language_preference);
    },

    setLanguageInitialized(state, status) {
      state.language_initialized = status;
    },
  },

  actions: {
    fetchAvailableLocales({ commit, state }) {
      bApi.get(`/en/v1/available-locales`).then(data => {
        commit("setAvailableLocales", data);
      });
    },

    fetchTerms({ commit, state, getters }, locale = "en") {
      return bApi.get(`/${locale}/v1/general-terms`).then(data => {
        commit("setTerms", data);
      });
    },

    updateLanguagePreference({ state, commit }, { survey_slug, locale }) {
      return bApi
        .post(`/${locale}/v1/users/language-preference`, {
          survey_slug: survey_slug,
          new_locale: locale,
        })
        .then(data => {
          commit("setLanguagePreference", data.locale);
        });
    },
  },
};
