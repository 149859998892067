<template>
  <div>
    <!-- <cp-header
      root-url="/"
      :logout-url="logoutUrl"
      :items="links"
      :user="user"
    /> -->
    <v-row align="center">
      <v-col :class="{ 'pb-0': hasInfo }">
        <portal-target name="header" />
      </v-col>
      <v-col v-if="hasInfo" align="right" class="pb-0">
        <cp-btn
          icon
          class="page-info-trigger"
          :class="{ 'info-is-active': infoActive }"
          @click="infoActive = !infoActive"
        >
          <cp-icon icon="info-outlined" />
        </cp-btn>

        <!-- <v-btn
                    icon
                    color="white"
                    class="page-info-trigger"
                    :class="{'info-is-active': infoActive}"
                    @click="infoActive = !infoActive"
                >
                    <v-icon :color="infoActive ? 'blue' : 'white'" large>
                        mdi-information-outline
                    </v-icon>
                    <cp-icon :color="infoActive ? 'blue' : 'white'" large icon="info-outlined" />
                </v-btn> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import VueCookies from "vue-cookies";

const links = [
  { text: "Dashboard", href: "/", access_needed: [] },
  { text: "Priorities", href: "/priorities", access_needed: [] },
  { text: "Reporting", href: "/reporting", access_needed: [] },
];

export default {
  data() {
    return {
      infoActive: false,
      logoutUrl: process.env.VUE_APP_LOGOUT_PATH,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.identity,
      onboarding: state => state.dashboard.summary.onboarding,
    }),

    panelOpen() {
      return this.infoActive ? 0 : null;
    },

    hasInfo() {
      return this.$slots["page-info"][0];
    },

    ...mapGetters("auth", ["clientIsAdmin"]),

    links() {
      if (this.onboarding) {
        return links.filter(link => link.text == "Dashboard");
      }

      let calculated_links = links;

      // if (!this.clientIsAdmin) {
      //   calculated_links = links.filter(link =>
      //     link.access_needed.every(item => this.user.roles.indexOf(item) !== -1)
      //   );
      // }

      return calculated_links.map(link => {
        const { text, href } = link;
        return {
          text,
          href,
          active: link.href.split("/")[1] == this.$route.path.split("/")[1],
        };
      });
    },
  },
};
</script>
