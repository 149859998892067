<template>
  <div>
    <cp-textarea
      v-model="note.body"
      outlined
      label="Notes"
      hide-details
      required
    />
    <div class="file-attachments mt-2">
      <input v-show="false" ref="file" type="file" @change="updateFile" />
      <h6 class="text-caption font-italic">
        File size limit: 1mb
        <br />
        Allowed file types:
        {{ allowedFileTypes.map(x => x.extension).join(", ") }}
      </h6>
      <cp-btn small text color="primary" class="ml-0" @click="selectFile">
        <cp-icon class="icon--margin-right" icon="paper-clip" />
        Attach File
      </cp-btn>
      <cp-dialog v-if="showFile" width="400" inline-activator header-background>
        <template #activator>
          <cp-btn small dense flushBody outlined>
            {{ note.attachment.name }}
            <cp-icon class="icon--margin-left" icon="cancel" />
          </cp-btn>
        </template>

        <template #title>
          Remove Attachment
        </template>

        <template #body>
          Are you sure you want to remove the file?
        </template>

        <template #actions="{close}">
          <v-spacer />
          <v-btn color="error" text @click="close">No</v-btn>
          <v-btn color="primary" @click="clearFile() && close()">Yes</v-btn>
        </template>
      </cp-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
  },

  data() {
    return {
      note: {
        body: this.value.body || null,
        attachment: this.value.attachment || null,
        destroyAttachmentId: this.value.destroyAttachmentId || null,
      },
      maxFileSize: 50000000, // in bytes, 50mb,
      allowedFileTypes: [
        {
          extension: "jpg",
          type: "image/jpeg",
        },
        {
          extension: "jpeg",
          type: "image/jpeg",
        },
        {
          extension: "bmp",
          type: "image/bmp",
        },
        {
          extension: "doc",
          type: "application/msword",
        },
        {
          extension: "docx",
          type:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
        {
          extension: "gif",
          type: "image/gif",
        },
        {
          extension: "odp",
          type: "application/vnd.oasis.opendocument.presentation",
        },
        {
          extension: "ods",
          type: "application/vnd.oasis.opendocument.spreadsheet",
        },
        {
          extension: "odt",
          type: "application/vnd.oasis.opendocument.text",
        },
        {
          extension: "png",
          type: "image/png",
        },
        {
          extension: "pdf",
          type: "application/pdf",
        },
        {
          extension: "ppt",
          type: "application/vnd.ms-powerpoint",
        },
        {
          extension: "pptx",
          type:
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        },
        {
          extension: "rtf",
          type: "application/rtf",
        },
        {
          extension: "svg",
          type: "image/svg+xml",
        },
        {
          extension: "tif",
          type: "image/tiff",
        },
        {
          extension: "txt",
          type: "text/plain",
        },
        {
          extension: "xls",
          type: "application/vnd.ms-excel",
        },
        {
          extension: "xlsx",
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        {
          extension: "zip",
          type: "application/zip",
        },
      ],
    };
  },

  computed: {
    showFile() {
      if (this.note.attachment && this.note.attachment.name) {
        return true;
      }

      return false;
    },
  },

  watch: {
    value: {
      handler(note) {
        if (this.isEqual(note, this.note)) {
          return;
        }

        this.note = {
          body: note.body || null,
          attachment: note.attachment || null,
          destroyAttachmentId: note.destroyAttachmentId || null,
        };
      },
      deep: true,
      immediate: true,
    },

    note: {
      handler(note) {
        if (this.isEqual(note, this.value)) {
          return;
        }

        this.$emit("input", this.note);
      },
      deep: true,
    },
  },

  methods: {
    selectFile() {
      this.$refs.file.click();
    },

    updateFile(e) {
      if (!e.target.files.length) {
        return;
      }

      let file = this.$refs.file.files[0];

      if (file.size > this.maxFileSize) {
        alert("Sorry, the chosen file is over 1mb.");
        return;
      }

      if (!this.allowedFileTypes.map(x => x.type).includes(file.type)) {
        alert("Sorry, the chosen file type is not allowed.");
        return;
      }

      this.note.destroyAttachmentId = null;
      this.note.attachment = file;
    },

    clearFile() {
      if (!this.note.attachment) {
        return;
      }

      this.note.destroyAttachmentId = this.note.attachment.id;
      this.note.attachment = null;
      this.$refs.file.value = "";
    },

    isEqual(note, oldNote) {
      return JSON.stringify(note) == JSON.stringify(oldNote);
    },
  },
};
</script>
