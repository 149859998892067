import { Api } from "@cp/lib";
import availableUsersData from "./static/availableUsers";
import actionItemsData from "./static/actionItems";
import assignmentData from "./static/assignment";
import messagesData from "./static/messages";
import { mergeAttributes } from "@/lib/utils.js";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  state: {
    assignment: {
      statuses: [],
      follow_up_statuses: [],
      current_status: null,
      activity_log_items: [],
      action_item: {
        body: null,
        attachments: [],
      },
      topic: {},
      creator: {
        name: null,
      },
      assignee: {
        name: null,
      },
      target_regions: [],
      target_positions: [],
      note: {
        id: null,
        body: null,
        attachment: {
          id: null,
          name: null,
        },
        destroyAttachmentId: null,
      },
      key_note: {
        id: null,
        body: null,
        attachment: {
          id: null,
          name: null,
        },
        destroyAttachmentId: null,
      },
    },
    availableUsers: {},
    actionItems: [],
    dueDate: null,
    keyNote: {},
    messages: [],
    saving: false,
  },

  mutations: {
    setAssignment(state, { item = null, included = null }) {
      state.assignment = mergeAttributes(item, {
        assignee: mergeAttributes(included.find(x => x.type == "assignee")),
        creator: mergeAttributes(included.find(x => x.type == "creator")),
      });
    },

    setAvailableUsers(state, { users }) {
      state.availableUsers = users;
    },

    setActionItems(state, { actionItems }) {
      state.actionItems = actionItems;
    },

    setDueDate(state, { dueDate }) {
      state.dueDate = dueDate;
    },

    setKeyNote(state, { keyNote }) {
      state.keyNote = keyNote ? keyNote.attributes : {};
    },

    setMessages(state, { messages }) {
      state.messages = messages;
    },

    addMessage(state, { message }) {
      state.messages.push(message);
    },
  },

  actions: {
    fetchAssignment({ commit }, id) {
      bApi
        .authorize()
        .get(`/assignments/${id}`)
        .then(({ data, included }) =>
          commit("setAssignment", { item: data, included: included })
        );
    },

    fetchAvailableUsers({ commit }, { topic, id = null }) {
      const uri = id
        ? `/assignments/${id}/available-users`
        : "/assignments/available-users";

      bApi
        .authorize()
        .get(uri, { topic_id: topic, assignment_id: id })
        .then(({ data }) => commit("setAvailableUsers", data));
    },

    fetchActionItems({ commit }, { topic, id = null }) {
      bApi
        .authorize()
        .get("/action-items", { topic_id: topic, assignment_id: id })
        .then(({ data }) =>
          commit("setActionItems", { actionItems: data.action_items })
        );
    },

    fetchDueDate({ commit }, id = null) {
      if (!id) return;

      bApi
        .authorize()
        .get(`/assignments/${id}/due-date`)
        .then(({ data }) => commit("setDueDate", { dueDate: data.due_date }));
    },

    fetchKeyNote({ commit }, id = null) {
      if (!id) return;

      bApi
        .authorize()
        .get(`/assignments/${id}/key-note`)
        .then(({ data }) => commit("setKeyNote", { keyNote: data }));
    },

    createAssignment(state, data) {
      state.saving = true;

      return bApi
        .authorize()
        .asFormData()
        .post("/assignments", data)
        .then(({ data: { attributes, id } }) => {
          window.location = `/priorities/${attributes.topic.id}/assignments/${id}`;
        })
        .catch(error => {
          state.saving = false;
        });
    },

    updateAssignment({ state, commit }, { data, id: assignmentId }) {
      const { status, topic_id: topicId } = data.assignment;
      state.saving = true;

      return bApi
        .authorize()
        .asFormData()
        .put(`/assignments/${assignmentId}`, data)
        .then(({ data, included }) => {
          if (status) {
            commit("setAssignment", { item: data, included: included });
            state.saving = false;
          } else {
            window.location = `/priorities/${topicId}/assignments/${assignmentId}`;
          }
        })
        .catch(error => {
          state.saving = false;
        });
    },

    advanceFollowUpProcess({ state, commit }, assignmentId) {
      state.saving = true;

      bApi
        .authorize()
        .post(`/assignments/${assignmentId}/advance-follow-up-process`)
        .then(({ data, included }) => {
          state.saving = false;
          commit("setAssignment", { item: data, included: included });
        })
        .catch(error => {
          state.saving = false;
        });
    },

    async createNote({ state, commit }, { data, assignmentId }) {
      state.saving = true;

      await bApi
        .authorize()
        .asFormData()
        .post(`/assignments/${assignmentId}/notes`, data)
        .then(({ data, included }) => {
          state.saving = false;
          commit("setAssignment", { item: data, included: included });
        })
        .catch(error => {
          state.saving = false;
        });
    },

    fetchMessages({ commit }, assignmentId) {
      bApi
        .authorize()
        .get(`/assignments/${assignmentId}/messages`)
        .then(({ data }) => commit("setMessages", data));
    },

    createMessage({ commit }, data) {
      bApi
        .authorize()
        .post("/comments", data)
        .then(({ data }) => commit("addMessage", data));
    },
  },
};
