<template>
  <v-col lg="4" cols="12">
    <div class="survey-question__input">
      <div class="input input--text-box">
        <v-row
          class="binary-radio"
          no-gutters
          justify="center"
          justify-lg="end"
        >
          <div class="binary-radio__option">
            <input :id="positiveKey" v-model="value" type="radio" value="1" />
            <label :for="positiveKey" class="positive">
              <cp-icon class="no  no-pointer-events" icon="binary-default" />
              <cp-icon class="yes  no-pointer-events" icon="binary-selected" />
              {{ $store.state.translations.terms.yes }}
            </label>
          </div>
          <div class="binary-radio__option">
            <input :id="negativeKey" v-model="value" type="radio" value="0" />
            <label :for="negativeKey" class="negative">
              <cp-icon class="no  no-pointer-events" icon="binary-default" />
              <cp-icon class="yes  no-pointer-events" icon="binary-selected" />
              {{ $store.state.translations.terms.no }}
            </label>
          </div>
        </v-row>
      </div>
    </div>
  </v-col>
</template>

<script>
import InteractsWithResponse from "@mixins/InteractsWithResponse";

const responseKey = "yes_no";

export default {
  mixins: [InteractsWithResponse],

  data() {
    return {
      responseKey,
      value: this.responseValue(responseKey),
    };
  },

  computed: {
    key() {
      return `${Math.random()
        .toString(36)
        .substring(7)}-question-${this.question.id}`;
    },

    positiveKey() {
      return `${this.key}-positive`;
    },

    negativeKey() {
      return `${this.key}-negative`;
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.addResponse(this.value);
      }
    },
  },
};
</script>
