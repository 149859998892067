<template>
  <div class="distribution__scale">
    <v-row justify="center">
      <div
        v-for="(item, key) in likelihood"
        :key="key"
        class="scale__legend-item"
      >
        <cp-icon
          icon="radio-selected"
          :class="`icon__fill--${kebabCase(key)}`"
        />
        {{ titleCase(key) }}
        <span v-if="total">{{ item.roundedPercentage }}%</span>
      </div>
    </v-row>
    <v-row class="scale__bar" justify="center">
      <div v-if="!total">
        Insufficient Data
      </div>
      <template v-else>
        <div
          v-for="(item, key) in likelihood"
          :key="key"
          :style="`width: ${item.percentage}%;`"
        >
          <span :class="`scale__bar--${kebabCase(key)}`"></span>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    distribution: { type: Object, required: true },
  },

  data() {
    return {
      likelihood: {
        very_unlikely: {
          percentage: 0,
          roundedPercentage: 0,
        },
        unlikely: {
          percentage: 0,
          roundedPercentage: 0,
        },
        neutral: {
          percentage: 0,
          roundedPercentage: 0,
        },
        likely: {
          percentage: 0,
          roundedPercentage: 0,
        },
        very_likely: {
          percentage: 0,
          roundedPercentage: 0,
        },
      },
    };
  },

  computed: {
    total() {
      return Object.keys(this.distribution).reduce(
        (carry, key) => carry + this.distribution[key],
        0
      );
    },
  },

  watch: {
    distribution() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      for (const [key, value] of Object.entries(this.likelihood)) {
        this.likelihood[key] = this.getPercentages(key);
      }

      this.normalizeTotal();
    },

    getPercentages(key) {
      if (!this.distribution[key]) {
        return { percentage: 0, roundedPercentage: 0 };
      }

      const percentage = (this.distribution[key] / this.total) * 100;

      return { percentage, roundedPercentage: Math.round(percentage) };
    },

    normalizeTotal() {
      let total = 0;

      for (const [key, value] of Object.entries(this.likelihood)) {
        total += this.likelihood[key].roundedPercentage;
      }

      let difference = 100 - total;

      let largestKey = Object.keys(this.likelihood).reduce((a, b) =>
        this.likelihood[a].roundedPercentage >
        this.likelihood[b].roundedPercentage
          ? a
          : b
      );

      if (difference !== 0) {
        this.likelihood[largestKey].roundedPercentage += difference;
      }
    },

    titleCase(string) {
      return string
        .replace("_", " ")
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },

    kebabCase(string) {
      return (
        string &&
        string
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map(x => x.toLowerCase())
          .join("-")
      );
    },
  },
};
</script>
