import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{staticClass:"card--engagement-breakdown card--dashboard",class:{ 'elevation-0': _vm.flat },attrs:{"flush-body":"","dense":"","collapse-actions":"","actions-icon":"mdi-filter-variant","actions-header-text":"Filters","filter-count":_vm.filterCounts},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{class:{ 'text-subtitle-1': _vm.withRegionsToggle }},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('cp-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('cp-btn',_vm._g({attrs:{"small":"","icon":"","color":"primary"}},on),[_c('cp-icon',{attrs:{"small":"","icon":"info-outlined"}})],1)]}}])},[_vm._t("tooltip")],2)],1)]},proxy:true},{key:"actions",fn:function(){return [_vm._t("filters")]},proxy:true}],null,true)},[(_vm.noPositionData && _vm.noRegionDataAtAll)?_c(VRow,[_c(VCol,{staticClass:"pa-4 ml-2"},[_c('h4',[_vm._v(" Sorry, but there have not been enough responses to this Priority to provide a breakdown. ")])])],1):(
      _vm.breakdownByRegions.data.length || _vm.breakdown.position_categories.length
    )?_c('div',{staticClass:"dashboard__content"},[_c(VRow,[_c(VCol,{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('div',{staticClass:"content__position-categories",staticStyle:{"position":"relative"}},[_c('no-data-dialog',{attrs:{"show":_vm.noPositionData}}),_c('h2',[_vm._v("Position Categories")]),_c('bar-chart-horizontal',{attrs:{"items":_vm.breakdownPositionCategories}})],1)]),_c(VCol,{staticStyle:{"position":"relative"},attrs:{"lg":"6","md":"6","sm":"12"}},[_c('h2',[_vm._v("Regions")]),_c('no-data-dialog',{attrs:{"show":(!_vm.showAllRegions && _vm.noRegionData) ||
              (_vm.showAllRegions && _vm.noRegionDataAtAll)}}),_c('div',{staticStyle:{"overflow-x":"auto"}},[_c('div',{style:(("\n                width: " + (_vm.breakdownByRegions.data.length * 64) + "px;\n                min-width: calc(100% - 24px);\n                position:relative;\n              "))},[_c('bar-chart',{ref:"chart",attrs:{"items":_vm.breakdownByRegions,"target":_vm.breakdown.sbx || 0,"height":250}})],1)])],1)],1)],1):_c('h4',{staticClass:"loading-text"},[_vm._v("Loading...")]),(_vm.legendItems != null)?[_c('Legend',{attrs:{"items":_vm.legendItems}})]:[_c('div',{staticClass:"d-flex justify-center chart-legend chart-legend--border"},[_c('span',{staticClass:"footer__info"},[_c('span',{staticClass:"info__circle info__circle--your-score"}),_vm._v(" Current Score ")]),_c('span',{staticClass:"footer__info"},[_c('span',{staticClass:"info__circle info__circle--previous-score"}),_vm._v(" Previous Score ")]),_c('span',{staticClass:"footer__info"},[_c('span',{staticClass:"info__circle info__circle--sb-index"}),_vm._v(" SB Index ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }