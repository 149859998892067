import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{staticClass:"mt-4"},[_c('section',{staticClass:"content"},[_c(VRow,[_c(VCol,{attrs:{"sm":12,"md":6}},[_c('cp-card',{attrs:{"color":"grey lighten-3","dense":"","flat":""}},[_c('h6',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.assignedOnText)}}),(_vm.assignment.completed_at)?_c('h6',{staticClass:"mb-3"},[_vm._v(" Completed "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.completedOn)+" ")])]):_c('h6',{staticClass:"mb-3"},[_vm._v(" Due by "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.dueBy))]),_vm._v(" | "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.daysRemaining)}})]),_c('h6',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.assignment.target_regions.length))]),_vm._v(" "+_vm._s(_vm._f("pluralize")("Region",_vm.assignment.target_regions.length))+" Targeted "),_c('cp-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('cp-btn',_vm._g({staticClass:"ml-0 mb-1",attrs:{"small":"","icon":""}},on),[_c('cp-icon',{attrs:{"icon":"info-outlined","small":"","color":"#0070A8"}})],1)]}}])},_vm._l((_vm.assignment.target_regions),function(region,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(region.name)+" "),_c('br')])}),0)],1),_c('h6',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.assignment.target_positions.length))]),_vm._v(" Position "+_vm._s(_vm._f("pluralize")("Category",_vm.assignment.target_positions.length))+" Targeted "),_c('cp-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('cp-btn',_vm._g({staticClass:"ml-0 mb-1",attrs:{"small":"","icon":""}},on),[_c('cp-icon',{attrs:{"icon":"info-outlined","small":"","color":"#0070A8"}})],1)]}}])},_vm._l((_vm.assignment.target_positions),function(target_position,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(target_position.name)+" "),_c('br')])}),0)],1)]),_c(VRow,{staticClass:"action-item-description mt-3"},[_c(VCol,[_c('h3',{staticClass:"font-weight-bold"},[_vm._v("Action Item Description")]),_c('h6',{staticClass:"alt-text",domProps:{"innerHTML":_vm._s(_vm.assignment.action_item.body)}})])],1),_c(VRow,[_c(VCol,[(_vm.assignment.action_item.attachments.length)?[_c('h3',{staticClass:"font-weight-bold"},[_vm._v("Downloadable Resources")]),_vm._l((_vm.assignment.action_item
                  .attachments),function(attachment,index){return _c('single-row-link',{key:index,staticClass:"my-2",attrs:{"href":attachment.url,"target":"_blank","title":attachment.name,"showSBIcon":""}})})]:_vm._e()],2)],1),(_vm.hasKeynoteBody || _vm.hasKeynoteAttachment)?[_c(VDivider,{staticClass:"mt-3 mb-2"}),_c(VRow,[_c(VCol,[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" Additional Instructions From "+_vm._s(_vm.creator.full_name)+" ")]),(_vm.hasKeynoteBody)?_c('div',{staticClass:"alt-text",domProps:{"innerHTML":_vm._s(_vm.assignment.key_note.body)}}):_vm._e()])],1),(_vm.hasKeynoteAttachment)?_c(VRow,[_c(VCol,[_c('single-row-link',{staticClass:"my-2",attrs:{"href":_vm.assignment.key_note.attachment.url,"target":"_blank","title":_vm.assignment.key_note.attachment.name}})],1)],1):_vm._e()]:_vm._e()],2),_c(VCol,{attrs:{"sm":12,"md":6}},[_c('div',{staticClass:"activities"},[_c(VRow,{staticClass:"activities__header",attrs:{"align":"center"}},[_c(VCol,{staticClass:"pa-2 pl-3"},[_c('h4',[_vm._v("Assignment Activity Log")])]),_c(VCol,{staticClass:"text-right pa-1 pr-3"},[(!_vm.readonly && [_vm.creator.id, _vm.assignee.id].includes(_vm.userId))?_c('cp-dialog',{attrs:{"width":"500","header-background":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('h4',{staticClass:"activities__header__create-note"},[_c(VIcon,[_vm._v("add")]),_vm._v(" Add New Note ")],1)]},proxy:true},{key:"title",fn:function(){return [_vm._v(" Add a New Note ")]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v(" Leaving a note is a great way to keep track of the progress of your assignment and they're viewable by anyone with access to this assignment. ")]),_c('notes-field',{model:{value:(_vm.newNote),callback:function ($$v) {_vm.newNote=$$v},expression:"newNote"}})]},proxy:true},{key:"actions",fn:function(ref){
                  var close = ref.close;
return [_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"color":"error","text":""},on:{"click":close}},[_vm._v(" Cancel ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('cp-btn',{attrs:{"disabled":_vm.saving,"color":"success"},on:{"click":function($event){return _vm.createNote(close)}}},[_vm._v(" "+_vm._s(_vm.saving ? "Saving..." : "Create Note")+" ")])],1)]}}],null,false,2374815260)}):_vm._e()],1)],1),(_vm.assignment.activity_log_items.length)?_c(VExpansionPanels,{staticClass:"activities__list-wrapper",attrs:{"accordion":true,"flat":true,"tile":true}},_vm._l((_vm.assignment.activity_log_items),function(activity){return _c(VExpansionPanel,{key:activity.id,staticClass:"activities__activity",attrs:{"readonly":!activity.content},model:{value:(_vm.assignment.activity_log_items),callback:function ($$v) {_vm.$set(_vm.assignment, "activity_log_items", $$v)},expression:"assignment.activity_log_items"}},[_c(VExpansionPanelHeader,{staticClass:"activities__activity__header",class:{ 'no-content': !activity.content },attrs:{"hide-actions":!activity.content,"expand-icon":"mdi-menu-down"}},[_c(VRow,{attrs:{"align":"end","no-gutters":""}},[_c(VCol,[_c('h4',[_vm._v(_vm._s(activity.created_at))]),_c('div',{staticClass:"mt-2"},[(activity.content && activity.content.file.name)?_c(VIcon,{staticStyle:{"margin-bottom":"-3px"}},[_vm._v(" mdi-paperclip ")]):_vm._e(),_c('span',{staticClass:"font-weight-bold",class:{
                          'activities__header__title-with-icon':
                            activity.content && activity.content.file,
                        }},[_vm._v(" "+_vm._s(activity.title)+" ")])],1)]),_c(VCol,{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(activity.user)+" ")])],1)],1),(activity.content)?_c(VExpansionPanelContent,{staticClass:"activities__activity__content"},[_c('cp-card',{staticClass:"mt-3",attrs:{"color":"grey lighten-3","flat":"","dense":""}},[_vm._v(" "+_vm._s(activity.content.body)+" "),(activity.content.file)?[_c('a',{staticClass:"mt-2 display-block",attrs:{"href":activity.content.file.url,"target":"_blank"}},[_vm._v(" "+_vm._s(activity.content.file.name)+" ")])]:_vm._e()],2)],1):_vm._e()],1)}),1):_c('h1',{staticClass:"text-center pa-3"},[_vm._v("No activity yet")])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }