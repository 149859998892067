<template>
  <div class="survey-slider-wrapper">
    <div
      ref="scale"
      class="survey-slider"
      tabindex="0"
      @keyup.left="moveLeft"
      @keyup.right="moveRight"
      @focus="isActive = true"
      @blur="isActive = false"
    >
      <ol class="survey-slider__steps no-select">
        <div class="survey-slider__steps__highlight"></div>
        <li
          v-for="(option, step) in availableOptions"
          :key="step"
          class="survey-slider__steps__step"
          @touchstart="activateStep($event, step)"
          @mousedown="activateStep($event, step)"
          @touchend="deactivateStep"
          @mouseup="deactivateStep"
        >
          {{ step }}
        </li>
      </ol>
      <div
        ref="marker"
        class="survey-slider__marker"
        :class="{ 'is-visible': markerVisible, 'is-active': markerActive }"
      >
        <h5 class="survey-slider__marker__label">{{ markerLabel }}</h5>
        <div class="survey-slider__marker__icon">
          <img
            v-for="(option, step) in availableOptions"
            :key="step"
            :src="`/images/survey/${option.src}`"
            :class="{ 'is-active': activeStep == step }"
          />
        </div>
      </div>
      <div class="survey-slider__label">
        {{ $store.state.translations.terms[type] }}
      </div>
    </div>

    <input
      ref="input"
      type="hidden"
      :name="`questions[][${type}]`"
      :value="activeStep"
    />
  </div>
</template>

<script>
import interact from "interactjs";
import InteractsWithResponse from "@mixins/InteractsWithResponse";

export default {
  mixins: [InteractsWithResponse],

  props: {
    type: { type: String, required: true },
  },

  data() {
    return {
      responseKey: this.type,
      activeStep: null,
      isActive: false,
      markerVisible: false,
      markerActive: false,
      markerPosition: 0,
      positions: {
        "1": 1,
        "2": 56,
        "3": 111,
        "4": 166,
        "5": 222,
      },
    };
  },

  computed: {
    availableOptions() {
      return this.options[this.type];
    },

    markerLabel() {
      if (!this.activeStep) {
        return;
      }

      return this.availableOptions[this.activeStep].label;
    },

    markerPositionFromActiveStep() {
      return this.positions[this.activeStep || 0];
    },

    activeStepFromMarkerPosition() {
      if (this.markerPosition < this.positions[2] - 30) {
        return 1;
      } else if (
        this.markerPosition >= this.positions[2] - 30 &&
        this.markerPosition < this.positions[3] - 30
      ) {
        return 2;
      } else if (
        this.markerPosition >= this.positions[3] - 30 &&
        this.markerPosition < this.positions[4] - 30
      ) {
        return 3;
      } else if (
        this.markerPosition >= this.positions[4] - 30 &&
        this.markerPosition < this.positions[5] - 30
      ) {
        return 4;
      } else if (this.markerPosition >= this.positions[5] - 30) {
        return 5;
      }

      return 1;
    },

    options() {
      return {
        agreement: {
          "1": {
            label: this.$store.state.translations.terms.agreement_1,
            src: "totally_disagree.svg",
          },
          "2": {
            label: this.$store.state.translations.terms.agreement_2,
            src: "disagree.svg",
          },
          "3": {
            label: this.$store.state.translations.terms.agreement_3,
            src: "neutral_agree.svg",
          },
          "4": {
            label: this.$store.state.translations.terms.agreement_4,
            src: "agree.svg",
          },
          "5": {
            label: this.$store.state.translations.terms.agreement_5,
            src: "totally_agree.svg",
          },
        },
        importance: {
          "1": {
            label: this.$store.state.translations.terms.importance_1,
            src: "totally_unimportant.svg",
          },
          "2": {
            label: this.$store.state.translations.terms.importance_2,
            src: "unimportant.svg",
          },
          "3": {
            label: this.$store.state.translations.terms.importance_3,
            src: "neutral_important.svg",
          },
          "4": {
            label: this.$store.state.translations.terms.importance_4,
            src: "important.svg",
          },
          "5": {
            label: this.$store.state.translations.terms.importance_5,
            src: "totally_important.svg",
          },
        },
        likelihood: {
          "1": {
            label: this.$store.state.translations.terms.likelihood_1,
            src: "totally_disagree.svg",
          },
          "2": {
            label: this.$store.state.translations.terms.likelihood_2,
            src: "disagree.svg",
          },
          "3": {
            label: this.$store.state.translations.terms.likelihood_3,
            src: "neutral_agree.svg",
          },
          "4": {
            label: this.$store.state.translations.terms.likelihood_4,
            src: "agree.svg",
          },
          "5": {
            label: this.$store.state.translations.terms.likelihood_5,
            src: "totally_agree.svg",
          },
        },
      };
    },
  },

  mounted() {
    const responseValue = this.responseValue(this.responseKey);
    if (responseValue) {
      this.activeStep = responseValue;
      this.moveMarker(null, this.positions[this.activeStep]);
      this.deactivateStep();
    }

    interact(this.$refs.scale).draggable({
      lockAxis: "x",
      inertia: true,
      onmove: this.moveStep,
      onend: this.snapToClosestStep,
    });
  },

  methods: {
    activateStep(event, activeStep) {
      this.moveStep(event, this.positions[activeStep]);
    },

    deactivateStep() {
      setTimeout(() => {
        this.markerActive = false;
      }, 300);
    },

    snapToClosestStep() {
      this.moveStep(null, this.markerPositionFromActiveStep);
      this.markerActive = false;
    },

    moveLeft() {
      const position = Math.max((this.activeStep || 2) - 1, 1);

      this.moveStep(null, this.positions[position]);
      this.deactivateStep();
    },

    moveRight() {
      const position = Math.min((this.activeStep || 0) + 1, 5);

      this.moveStep(null, this.positions[position]);
      this.deactivateStep();
    },

    moveStep(event, position = false) {
      this.moveMarker(event, position);
      this.activeStep = this.activeStepFromMarkerPosition;
      this.addResponse(this.activeStep);
    },

    moveMarker(event, position = false) {
      const marker = this.$refs.marker;
      const x = position || this.markerPosition + event.dx;

      this.markerVisible = true;
      this.markerActive = true;

      if (x >= this.positions[1] && x <= this.positions[5]) {
        marker.style.webkitTransform = marker.style.transform =
          "translate(" + x + "px)";
        this.markerPosition = x;
      }
    },
  },
};
</script>
