<template>
  <cp-select
    v-model="code"
    :items="options"
    hide-details
    :dense="dense"
    class="v-input"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, default: null },
    dense: { type: Boolean, default: true },
  },

  data() {
    return {
      options: [
        { text: "All SWIFT Factors", value: null },
        { text: "Satisfaction", value: "S" },
        { text: "Workplace", value: "W" },
        { text: "Integrity", value: "I" },
        { text: "Future", value: "F" },
        { text: "Teamwork", value: "T" },
      ],
    };
  },

  computed: {
    code: {
      get() {
        return this.value;
      },
      set(code) {
        this.$emit("input", code);
      },
    },
  },
};
</script>
