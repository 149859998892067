<template>
  <v-col lg="8" cols="12">
    <div class="survey-question__input">
      <div class="input input--text-box">
        <v-autocomplete
          v-if="isAutocomplete"
          v-model="value"
          :items="autocompleteData"
          flat
          hide-no-data
          hide-details
          solo-inverted
        />
        <textarea
          v-else
          v-model="value"
          :placeholder="$store.state.translations.terms.comment_here"
          class="input__field"
        ></textarea>
      </div>
    </div>
  </v-col>
</template>

<script>
import InteractsWithResponse from "@mixins/InteractsWithResponse";
import { Api } from "@cp/lib";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);
const responseKey = "text_entry";

export default {
  mixins: [InteractsWithResponse],

  props: {
    autocompleteDataEndpoint: { type: String, required: false, default: null },
  },

  data() {
    return {
      responseKey,
      value: this.responseValue(responseKey),
      autocompleteData: [],
    };
  },

  computed: {
    isAutocomplete() {
      if (this.autocompleteDataEndpoint) {
        return true;
      }

      return false;
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.addResponse(this.value);
      }
    },
  },

  mounted() {
    if (this.isAutocomplete) {
      bApi.get(this.autocompleteDataEndpoint).then(data => {
        this.autocompleteData = data;
      });
    }
  },
};
</script>
