<template>
  <survey-bumper-content :survey="survey" page-context="conclusion" />
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      language_preference: state => state.translations.language_preference,
      survey: state => state.survey.survey,
    }),

    isWebsiteMiniSurvey() {
      return this.survey.slug == "website-mini-survey";
    },
  },

  mounted() {
    if (this.isWebsiteMiniSurvey) {
      let survey_result_url = `${process.env.VUE_APP_SITE_PATH}/surveys/demo-result?survey_slug=${this.$route.params.slug}`;
      window.location.href = survey_result_url;
      return;
    }
  },
};
</script>
