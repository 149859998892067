<template>
  <cp-card
    dense
    flush-body
    collapse-actions
    actions-icon="mdi-filter-variant"
    actions-header-text="Filters"
    class="mt-4"
  >
    <template #title>
      Reporting
      <v-tooltip right max-width="400px" color="rgba(0, 0, 0, 0.7)">
        <template #activator="{on}">
          <cp-btn
            style="margin-left: 0px;"
            small
            icon
            color="primary"
            v-on="on"
          >
            <cp-icon icon="info-outlined" />
          </cp-btn>
        </template>
        <span>
          Use the dropdown menu in the header to select different reports. You
          can look through a preview of the results here or download them for
          viewing in your spreadsheet program of choice with the "Export"
          button.
        </span>
      </v-tooltip>
    </template>

    <template #actions>
      <cp-btn
        text
        rounded
        small
        color="primary"
        @click="reportSchedulerOpen = true"
        class="mr-4"
      >
        <v-icon class="mr-1">mdi-timetable</v-icon>
        Automated Reports
      </cp-btn>

      <v-select
        v-if="clientPastFirstYear"
        v-model="activeYear"
        :items="timePeriodOptions"
        hide-details
        max-height="auto"
        class="select-with-headers mr-8"
        dense
      />
      <v-select
        v-model="selected"
        :items="reportOptions"
        hide-details
        max-height="auto"
        class="select-with-headers"
        dense
      >
        <template slot="item" slot-scope="{ item }">
          <div class="select__add-icon">
            <v-row no-gutters align="center" class="grouped">
              <div class="add-icon__option">
                {{ item.text }}
              </div>
            </v-row>
          </div>
        </template>
      </v-select>
      <cp-btn
        text
        rounded
        small
        color="primary"
        :class="{
          'ing-disable-export': exportDisabled,
        }"
        :disabled="exportDisabled"
        @click="downloadReport"
      >
        <cp-icon xSmall class="mr-2" icon="share" color="currentColor" />
        Export
      </cp-btn>
    </template>
    <v-card
      v-if="!reportLoading && (report.data == null || !report.data.length)"
    >
      <v-card-text class="text-center">
        No results found for this report.
      </v-card-text>
    </v-card>

    <cp-table
      v-else
      ref="reportDataTable"
      class="reporting-table-wrapper"
      :headers="report.headers"
      :items="report.data"
      add-header-tooltips
      :loading="reportLoading"
    >
      <template slot="item-columns" slot-scope="{ item }">
        <td
          v-for="(header, i) in report.headers"
          :key="`header-${header.value}-${i}`"
          :class="header.size ? `table-col-${header.size}` : ''"
        >
          {{ item[header.value] }}
        </td>
      </template>
    </cp-table>

    <cp-dialog
      :show-model.sync="reportSchedulerOpen"
      max-width="1200"
      header-background
      scrollable
      hide-scroll-indicator
      persistent
    >
      <template #title>
        Automated Report Scheduling
      </template>

      <template #body>
        <cp-report-scheduler
          ref="reportScheduler"
          :scheduled-reports="reportsModel.scheduledReports"
          @searchUsers="$store.dispatch('reports/fetchRecipients', $event)"
          @deleteSchedule="
            $store.dispatch('reports/deleteScheduledReport', $event)
          "
          @create="$store.dispatch('reports/createScheduledReport', $event)"
          @save="$store.dispatch('reports/updateScheduledReport', $event)"
          :error="reportsModel.scheduledReports.error"
          @clearError="reportsModel.scheduledReports.error = null"
        />
      </template>
    </cp-dialog>
  </cp-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ReportingIndex",

  data() {
    return {
      selected: null,
      activeYear: "current_year",
      reportLoading: false,
      reportSchedulerOpen: false,
    };
  },

  computed: {
    ...mapState({
      reportsModel: state => state.reports,
      reports: state => state.reports.all,
      report: state => state.reports.report,
      clientPastFirstYear: state => state.reports.clientPastFirstYear,
      reportDateRange: state => state.reports.reportDateRange,
    }),
    ...mapGetters("auth", ["userId"]),

    exportDisabled() {
      return (
        this.reportLoading ||
        (!this.reportLoading &&
          (this.report.data == null || !this.report.data.length))
      );
    },

    reportGroups() {
      const reportGroups = new Map();

      this.reports.forEach(({ title: text, slug: value, type }) => {
        if (!reportGroups.has(type)) {
          reportGroups.set(type, []);
        }

        reportGroups.get(type).push({ text, value });
      });

      return reportGroups;
    },

    reportOptions() {
      const reports = [];

      Array.from(this.reportGroups).forEach(([header, items], index) => {
        if (header) {
          reports.push({ header });
        }
        reports.push(...items);
        if (index + 1 < this.reportGroups.size) {
          reports.push({ divider: true });
        }
      });

      return reports;
    },

    timePeriodOptions() {
      let options = [];

      if (this.reportDateRange.current_year)
        options.push({
          text: this.reportDateRange.current_year,
          value: "current_year",
        });
      if (this.reportDateRange.previous_year)
        options.push({
          text: this.reportDateRange.previous_year,
          value: "previous_year",
        });
      if (this.reportDateRange.year_before_last)
        options.push({
          text: this.reportDateRange.year_before_last,
          value: "year_before_last",
        });

      return options;
    },
  },

  watch: {
    selected(slug) {
      this.reportLoading = true;
      this.$store
        .dispatch("reports/fetchOne", { slug: slug, year: this.activeYear })
        .then(() => {
          this.reportLoading = false;
        });
      this.$emit("report-changed");
    },

    activeYear() {
      this.reportLoading = true;
      this.$store
        .dispatch("reports/fetchOne", {
          slug: this.selected,
          year: this.activeYear,
        })
        .then(() => {
          this.reportLoading = false;
        });
      this.$emit("report-changed");
    },

    reportSchedulerOpen(isOpen) {
      if (isOpen) {
        this.$store.dispatch("reports/fetchScheduledReportsForUser");
        this.$store.dispatch("reports/fetchScheduledReportsForClient");
        this.$store.dispatch("reports/fetchScheduledReportOptions");
      }
    },
  },

  async created() {
    await this.$store.dispatch("reports/fetchAll");
    this.selected = this.reports[0].slug;
    this.$CpEvent.$on("scheduledReportSuccess", this.resetScheduler);
  },

  methods: {
    downloadReport() {
      this.$store.dispatch("reports/download", {
        slug: this.selected,
        [this.activeYear]: true,
      });
    },

    resetScheduler(scheduledReport) {
      this.$refs.reportScheduler.tab =
        this.userId == scheduledReport.user_id ? 0 : 1;
      this.$refs.reportScheduler.cancel();
    },
  },

  beforeDestroy() {
    this.$CpEvent.$off("scheduledReportSuccess");
  },
};
</script>

<style lang="scss" scoped>
.ing-disable-export {
  opacity: 0.25;
  color: #0070a8;

  svg.icon {
    fill: #0070a8;
  }
}
</style>
