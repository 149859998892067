<template>
  <div class="d-flex justify-center chart-legend chart-legend--border">
    <span v-for="(item, index) in items" :key="index" class="footer__info">
      <span :class="`info__circle info__circle--${item.color}`"></span>
      {{ item.text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true, default: null },
  },
};
</script>
