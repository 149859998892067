<template>
  <div class="assignments">
    <cp-card dense class="mb-4">
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="text-subtitle-1 font-weight-bold">
          {{ priority.body }}
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <cp-btn text color="primary" @click="showBreakdown = !showBreakdown">
            <v-icon>mdi-chevron-down</v-icon>
            Scores
          </cp-btn>
        </v-col>
      </v-row>
      <v-row v-if="showBreakdown" no-gutters>
        <v-col><priority-breakdown :priority-id="priorityId" flat/></v-col>
      </v-row>
    </cp-card>

    <cp-card title-color="#0070A8">
      <template #title>
        <cp-icon icon="assignment" x-large color="rgba(255, 255, 255, .5)" />
        <span class="white--text ml-4">Assignment Details</span>
      </template>

      <template #actions>
        <cp-tooltip left>
          <template #activator="{on}">
            <cp-btn icon v-on="on">
              <cp-icon small color="white" icon="info-outlined" />
            </cp-btn>
          </template>
          An Assignment is taking a priority, pairing it with the Action Item
          you'd like to take to address it, and then assigning it to someone in
          your company.
          <br />
          This person, your Action Team Member, will be responsible for
          facilitating the Action Item in the regions and positions you select.
          You'll be able to track their progress and provide support through the
          system.
        </cp-tooltip>
      </template>

      <v-row class="my-2 mb-4">
        <v-col>
          <select-imitation
            v-model="form.regions"
            class="regions"
            :errors="errors.regions"
            :items="regions"
            label="Target Regions"
            entity="Regions"
            :headers="[
              { text: 'Region', value: 'name', class: 'regions' },
              { text: 'Score', value: 'score', class: 'score' },
            ]"
            include-search
            disabled-text="Region already assigned"
            @closed="validate('regions')"
            @input="validate('regions')"
          />
        </v-col>
        <v-col>
          <select-imitation
            v-model="form.positions"
            class="position-categories"
            :errors="errors.positions"
            :message="
              removedPositions.length
                ? 'Your region changes have removed one or more target positions.'
                : ''
            "
            :items="positions"
            label="Target Position Categories"
            entity="Positions"
            :headers="[
              { text: 'Position', value: 'position' },
              { text: 'Score', value: 'score', class: 'score' },
            ]"
            disabled-text="Position already assigned"
            @closed="validate('positions')"
            @input="clearRemovedPositions() && validate('positions')"
          />
        </v-col>
      </v-row>

      <select-imitation
        class="assignee mb-6"
        :errors="errors.users"
        :items="allUsers"
        label="Action Team Member (Assignee)"
        entity="Users"
        @active="focusUserSearch"
        @closed="validate('users')"
      >
        <template v-if="user" #selection>
          <v-col>
            <v-row class="card-content" align="center" no-gutters>
              <v-col cols="auto">
                <cp-icon class="profile" icon="profile" />
              </v-col>
              <v-col>{{ user.first_name }} {{ user.last_name }}</v-col>
              <v-col>{{ user.position }}</v-col>
              <v-col>{{ user.region }}</v-col>
              <v-col cols="auto">
                <cp-btn icon @click.stop="form.users = []">
                  <cp-icon icon="cancel" />
                </cp-btn>
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template #contents="{close}">
          <v-autocomplete
            ref="userSearch"
            v-model="searchedUser"
            :items="allUsers"
            :search-input.sync="userSearch"
            autocomplete="off"
            append-icon="search"
            class="search-field pa-2"
            single-line
            color="primary"
            hide-details
            hide-no-data
            item-text="search"
            item-value="uuid"
            label="Search"
            return-object
          >
            <template #item="{item: user}">
              <v-row
                class="current-selection__wrapper"
                justify="space-between"
                align="center"
                dense
              >
                <v-col>{{ user.first_name }} {{ user.last_name }}</v-col>
                <v-col>{{ user.position }}</v-col>
                <v-col>{{ user.region }}</v-col>
              </v-row>
            </template>
          </v-autocomplete>
          <cp-accordion>
            <v-expansion-panel
              v-for="(users, category) in availableUsers"
              :key="category"
            >
              <v-expansion-panel-header color="grey lighten-3">
                {{ category }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <cp-table
                  v-model="form.users"
                  :headers="[
                    { text: 'First', value: 'first_name' },
                    { text: 'Last', value: 'last_name' },
                    { text: 'Position', value: 'position' },
                  ]"
                  :items="users"
                  selectable
                  single-select
                  :search="userSearch"
                  disable-pagination
                  hide-default-footer
                  @input="validate('users')"
                >
                  <template #item-columns="{item}">
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.position }}</td>
                  </template>
                </cp-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </cp-accordion>
        </template>
      </select-imitation>

      <cp-card
        class="mb-6"
        :class="{ 'cp-card--error': errors.actionItem }"
        dense
        outlined
      >
        <h4 class="text-subtitle-1 font-weight-bold">
          Recommended Action Items
        </h4>
        <p v-if="errors.actionItem" class="text--error">
          An Action Item must be selected.
        </p>
        <v-radio-group v-model="form.actionItem" hide-details class="mt-0">
          <v-container>
            <v-row
              v-for="item in actionItems"
              :key="item.id"
              align="center"
              dense
            >
              <v-col cols="auto">
                <v-radio :value="item.id" on-icon="mdi-check-circle" />
              </v-col>
              <v-col>
                <single-row-link
                  :title="item.title"
                  showSBIcon
                  @click="
                    $store.dispatch('action_items/openDetailDialog', item)
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-radio-group>
      </cp-card>

      <v-row>
        <v-col cols="auto">
          <cp-date-picker
            v-model="form.dueDate"
            label="Due On"
            outlined
            show-current
            name="dueDate"
            @input="validate('dueDate')"
            @blur="validate('dueDate')"
          />
        </v-col>
        <v-col>
          <notes-field v-model="form.note" />
        </v-col>
      </v-row>

      <v-container>
        <v-row>
          <v-spacer />
          <!-- <cp-btn text color="primary" @click="back">Cancel</cp-btn> -->
          <cp-btn
            :disabled="saving"
            color="primary"
            @click="save"
            :loading="saving"
          >
            {{
              saving
                ? "Saving..."
                : `${assignmentId ? "Save" : "Create"} Assignment`
            }}
          </cp-btn>
        </v-row>
      </v-container>
    </cp-card>

    <action-item-detail-dialog />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "AssignmentsCreate",

  props: {
    priorityId: { required: true },
    assignmentId: { default: null },
  },

  provide() {
    return {
      getErrors: this.getErrors,
    };
  },

  data() {
    return {
      showBreakdown: false,
      showRegions: false,
      showPositions: false,
      showUsers: false,
      userSearch: null,
      searchedUser: null,
      removedPositions: [],
      form: {
        regions: [],
        positions: [],
        users: [],
        actionItem: null,
        dueDate: moment().format("YYYY-MM-DD"),
        note: {
          id: null,
          body: null,
          attachment: {
            id: null,
          },
          destroyAttachmentId: null,
        },
      },
      errors: {
        regions: null,
        positions: null,
        users: null,
        actionItem: null,
        dueDate: null,
      },
      validations: {
        regions: ["required"],
        positions: ["required"],
        users: ["required"],
        actionItem: ["required"],
        dueDate: ["required"],
      },
    };
  },

  computed: {
    ...mapState({
      priority: state => state.priorities.priority,
      item: state => state.assignments.actionPlanItem,
      regions: state => state.engage.regions,
      positions: state => state.engage.positions,
      availableUsers: state => state.assignments.availableUsers,
      actionItems: state => state.assignments.actionItems,
      dueDate: state => state.assignments.dueDate,
      keyNote: state => state.assignments.keyNote,
      saving: state => state.assignments.saving,
    }),

    allUsers() {
      const users = [];
      Object.keys(this.availableUsers).forEach(category => {
        this.availableUsers[category].forEach(user => {
          user.search = `${user.first_name} ${user.last_name} ${user.position} ${user.region}`;
          users.push(user);
        });
      });

      return users;
    },

    user() {
      return this.form.users.length ? this.form.users[0] : null;
    },

    hasErrors() {
      return !!Object.values(this.errors).filter(errors => errors).length;
    },

    regionIds() {
      return this.form.regions.map(region => region.groupable_id);
    },
  },

  watch: {
    searchedUser(user) {
      if (user) {
        this.form.users = [user];
        this.$nextTick(() => {
          this.searchedUser = null;
        });
      }
    },

    "form.regions": function() {
      this.refreshPositions();
    },

    "form.actionItem": function() {
      this.validate("actionItem");
    },

    regions(regions) {
      if (!this.assignmentId) return;
      this.form.regions = regions.filter(region => region.selected);
    },

    positions(positions) {
      if (!this.assignmentId) return;
      this.form.positions = positions.filter(position => position.selected);
    },

    availableUsers(availableUsers) {
      if (!this.assignmentId) return;
      this.form.users = [];
      Object.keys(availableUsers).forEach(category => {
        availableUsers[category].forEach(user => {
          if (user.is_selected) {
            this.form.users.push(user);
          }
        });
      });
    },

    actionItems() {
      if (!this.assignmentId) return;
      const actionItem = this.actionItems.find(item => item.selected) || null;
      this.form.actionItem = actionItem ? actionItem.id : null;
    },

    dueDate() {
      if (!this.assignmentId) return;
      this.form.dueDate = moment(this.dueDate).format("YYYY-MM-DD");
    },

    keyNote() {
      if (!this.assignmentId) return;
      this.form.note = this.keyNote;
    },
  },

  created() {
    this.$store.dispatch("priorities/fetchOne", this.priorityId).then(() => {
      this.$store.dispatch("fetchRegions", {
        assignment_id: this.assignmentId,
        topic_id: this.priorityId,
      });

      this.$store.dispatch("fetchPositions", {
        id: this.assignmentId,
        topicID: this.priority.id,
      });

      this.$store.dispatch("fetchAvailableUsers", {
        topic: this.priorityId,
        id: this.assignmentId,
      });

      this.$store.dispatch("fetchActionItems", {
        topic: this.priorityId,
        id: this.assignmentId,
      });

      this.$store.dispatch("fetchDueDate", this.assignmentId);

      this.$store.dispatch("fetchKeyNote", this.assignmentId);
    });
  },

  methods: {
    refreshPositions() {
      this.$store
        .dispatch("fetchPositions", {
          regions: this.regionIds,
          id: this.assignmentId,
          topicID: this.priority.id,
        })
        .then(() => {
          const selected = this.form.positions;
          const available = this.positions.map(
            position => position.groupable_id
          );

          this.form.positions = selected.filter(position =>
            available.includes(position.groupable_id)
          );
          this.$nextTick(() => {
            this.removedPositions = selected.filter(
              position => !available.includes(position.groupable_id)
            );
          });
        });
    },

    clearRemovedPositions() {
      this.removedPositions.splice(0);
    },

    focusUserSearch() {
      this.$nextTick(() => {
        this.$refs.userSearch.focus();
      });
    },

    getErrors(field) {
      return this.errors[field];
    },

    validate(field) {
      const value = this.form[field];
      this.errors[field] = null;

      if (!value || (Array.isArray(value) && !value.length)) {
        this.errors[field] = ["This field is required."];
      }
    },

    validateAll() {
      Object.keys(this.validations).forEach(field => this.validate(field));
    },

    save() {
      this.validateAll();
      if (this.hasErrors) {
        return;
      }

      return this.assignmentId ? this.updateExisting() : this.addNew();
    },

    addNew() {
      this.$store.dispatch("createAssignment", this.formData());
    },

    updateExisting() {
      this.$store.dispatch("updateAssignment", {
        data: this.formData(),
        id: this.assignmentId,
      });
    },

    formData() {
      const form = this.form;
      let attachId = form.note.destroyAttachmentId || null;

      if (form.note.attachment) {
        attachId = form.note.attachment.id || null;
      }

      let attachmentData = {
        id: attachId,
        file: form.note.attachment || null,
      };

      if (form.note.destroyAttachmentId !== null) {
        attachmentData["_destroy"] = Number.isInteger(
          form.note.destroyAttachmentId
        )
          ? true
          : false;
      }

      return {
        assignment: {
          user_uuid: this.user.uuid,
          due_by: form.dueDate,
          action_item_id: form.actionItem,
          topic_id: this.priorityId,
          key_note_attributes: {
            body: form.note.body,
            attachment_attributes: attachmentData,
          },
          action_groups_attributes: [
            ...form.regions.map(region => ({
              id: region.id,
              old_score: region.score,
              type: "Region",
            })),
            ...form.positions.map(position => ({
              id: position.id,
              old_score: position.score,
              type: "PositionCategory",
            })),
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.assignments {
  &::v-deep .v-expansion-panel-content {
    overflow-y: auto;
    max-height: 300px;
    height: 70vh;
  }

  &::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
