<template>
  <div class="no-data-dialog" :class="{ 'is-visible': show }">
    <v-row align="center" justify="center">
      <cp-chip class="slightly-rounded dense" color="error">
        <cp-icon class="icon--margin-right" fill="white" icon="warning" />
        <strong>Insufficient Data</strong>
      </cp-chip>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.no-data-dialog {
  transition: opacity 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 101;
  font-weight: 500;
  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
  .row {
    height: 100%;
  }
}
</style>
