<template>
  <cp-card v-if="!priority.action_items.length">
    <loader />
  </cp-card>
  <div v-else>
    <v-row align="center" justify="end" class="mb-1">
      <v-col v-if="priority.action_items.length" class="ml-auto" cols="auto">
        <router-link
          #default="{navigate}"
          :to="{ name: 'priorities.history', params: { id: priority.id } }"
        >
          <cp-btn color="primary" text small @click="navigate">
            View History
          </cp-btn>
        </router-link>
        <router-link
          v-if="isProgramManager"
          #default="{navigate}"
          :to="{
            name: 'assignments.create',
            params: { priorityId: priority.id },
          }"
        >
          <cp-btn color="primary" rounded small @click="navigate">
            Create Assignment
          </cp-btn>
        </router-link>
      </v-col>
    </v-row>
    <cp-card class="priority-details">
      <v-row no-gutters>
        <v-col>
          <div class="detail">
            <h3>Priority</h3>
            <v-row class="detail__title" align="center" dense>
              <v-col v-if="priority.factor" cols="auto">
                <div
                  class="swift-letter-boxed"
                  :class="
                    `background--swift-${priority.factor.name.toLowerCase()}`
                  "
                >
                  {{ priority.factor.code }}
                </div>
              </v-col>
              <v-col>
                <h2>{{ priority.body }}</h2>
              </v-col>
            </v-row>
          </div>
          <div class="detail">
            <h3>Survey Question</h3>
            <h4>{{ priority.priority_question_body }}</h4>
          </div>
          <div class="detail">
            <h3>Appears on These Surveys</h3>
            <h4
              v-for="(survey, index) in priority.priority_question_surveys"
              :key="index"
            >
              {{ survey }}
            </h4>
          </div>
        </v-col>
        <v-col style="padding-left: 25px;" cols="auto">
          <donut-chart
            :outer-chart-data="Math.round(priority.aiq)"
            :inner-chart-data="Math.round(priority.previous_aiq)"
            :sb-index="Math.round(priority.sbx)"
            :is-percent="false"
            :thickness="82"
            main-color="rgba(0, 138, 9, 1)"
            chart-label="PRIORITY SCORE"
          />
          <br />
          <v-row class="chart-legend" justify="center">
            <span class="footer__info">
              <span class="info__circle info__circle--your-score"></span>
              Current Score
            </span>
            <span class="footer__info">
              <span class="info__circle info__circle--previous-score"></span>
              Previous Score
            </span>
          </v-row>
          <v-row class="chart-legend" justify="center">
            <span class="footer__info">
              <span class="info__circle info__circle--sb-index"></span>
              SB Index
            </span>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="currentAssignments.length" no-gutters>
        <v-col>
          <h3>Current Assignments</h3>
          <div
            v-for="assignment in currentAssignments"
            :key="assignment.id"
            class="pattern__single-line-click"
          >
            <router-link
              :to="{
                name: 'assignments.show',
                params: { priorityId: id, assignmentId: assignment.id },
              }"
              class="assignment"
            >
              <cp-card dense flushBody outlined class="background--gray">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="auto">
                    <cp-tooltip top>
                      <template #activator="{on}">
                        <div class="assignment-alert" v-on="on">
                          <cp-icon
                            v-if="assignment.action_required"
                            icon="alert-round"
                          />
                        </div>
                      </template>
                      <h1 v-if="assignment.assignment_requires_attention">
                        Awaiting Action From You
                      </h1>
                      <h1 v-if="assignment.unread_comments > 0">
                        {{ assignment.unread_comments }} Unread
                        {{ "Message" | pluralize(assignment.unread_comments) }}
                      </h1>
                      <h1 v-if="assignment.overdue">
                        Assignment Overdue
                      </h1>
                    </cp-tooltip>
                  </v-col>
                  <v-col>
                    <div class="single-line-click__text">
                      {{ assignment.action_item_title }}
                    </div>
                  </v-col>
                  <v-col style="max-width: 150px;">
                    <div class="single-line-click__text">
                      {{ assignment.assigned_to }}
                    </div>
                  </v-col>
                  <v-col style="max-width: 150px;">
                    <div class="single-line-click__text">
                      Due: {{ dueDate(assignment) }}
                    </div>
                  </v-col>
                  <v-col style="max-width: 150px;">
                    <div class="single-line-click__text">
                      {{ assignment.status_text }}
                    </div>
                  </v-col>
                  <v-col class="single-line-click__last" cols="auto">
                    <cp-icon class="last__icon" icon="chevron-right" />
                  </v-col>
                </v-row>
              </cp-card>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </cp-card>
    <br />

    <breakdown-charts
      title="Priority Breakdown"
      :breakdown="breakdown"
      :filters.sync="filters.breakdown"
      :legend-items="legendItems"
    >
      <template #tooltip>
        The Priority Breakdown shows you the engagement scores specific to this
        Priority, across your company, by both position category and region.
      </template>
    </breakdown-charts>
    <br />
    <cp-card v-if="priority.action_items.length" class="action-items">
      <v-row no-gutters>
        <v-col>
          <h3 class="text-subtitle-1 font-weight-bold">
            Recommended Action Items
          </h3>
          <single-row-link
            v-for="item in priority.action_items"
            :key="item.id"
            :title="item.title"
            class="my-2"
            showSBIcon
            @click="$store.dispatch('action_items/openDetailDialog', item)"
          />
        </v-col>
      </v-row>

      <action-item-detail-dialog />
    </cp-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "PrioritiesShow",

  props: {
    id: { required: true },
  },

  data() {
    return {
      filters: {
        breakdown: { type: "engagement", factor_code: null },
      },
      currentAssignments: [],
      filteredAssignments: ["follow_up_skipped", "follow_up_completed"],
    };
  },

  computed: {
    ...mapGetters("auth", ["userIs"]),
    isProgramManager() {
      return this.userIs("engage-program-manager");
    },

    ...mapState({
      priority: state => state.priorities.priority,
      breakdown: state => state.priorities.breakdown,
      dateRange: state => state.priorities.date_range,
    }),

    legendItems() {
      let previousScoreText = "Baseline Results";

      if (
        this.dateRange.previous_year &&
        this.dateRange.previous_year.start_date
      ) {
        let previousFirst = this.formattedYear(
          this.dateRange.previous_year.start_date
        );
        let previousLast = this.formattedYear(
          this.dateRange.previous_year.end_date
        );
        previousScoreText = `${previousFirst} - ${previousLast}`;
      }

      const currentDate = this.dateRange.current_year
        ? this.dateRange.current_year.start_date
        : new Date();

      return [
        {
          color: "your-score",
          text: `Current Score (${this.formattedYear(currentDate)} - Today)`,
        },
        {
          color: "previous-score",
          text: `Previous Score (${previousScoreText})`,
        },
        {
          color: "sb-index",
          text: "SB Index",
        },
      ];
    },
  },

  watch: {
    "filters.breakdown.factor_code": function() {
      this.fetchBreakdown();
    },

    "priority.assignments": function() {
      if (this.priority.assignments) {
        this.currentAssignments = this.priority.assignments.filter(
          x => !this.filteredAssignments.includes(x.status)
        );
      }
    },
  },

  created() {
    this.$store.dispatch("priorities/fetchOne", this.id).then(() => {
      this.$store.dispatch("priorities/fetchAssignments", {
        priority: this.priority,
      });
      this.fetchBreakdown();
    });
  },

  beforeDestroy() {
    this.clearPriority();
  },

  methods: {
    fetchBreakdown() {
      this.$store.dispatch("priorities/fetchBreakdown", {
        id: this.id,
        filters: this.filters.breakdown,
      });
    },

    back() {
      this.$router.push("/priorities");
    },

    dueDate(assignment) {
      return moment(assignment.due_by).format("L");
    },

    clearPriority() {
      this.$store.dispatch("priorities/clearPriority");
    },

    formattedYear(date) {
      return new Date(Date.parse(date)).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      });
    },
  },
};
</script>
