export default {
  data() {
    return {
      step: 5,
    };
  },

  computed: {
    values() {
      return [];
    },

    min() {
      let min =
        Math.floor(Math.min(...this.values) / this.step) * this.step - 5;

      return min <= 0 ? 0 : min;
    },

    max() {
      return Math.ceil(Math.max(...this.values) / this.step) * this.step;
    },
  },
};
