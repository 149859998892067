<template>
  <breakdown-charts
    title="Priority Breakdown"
    :breakdown="breakdown"
    :filters.sync="filters.breakdown"
    :flat="flat"
  >
    <template #tooltip>
      The Priority Breakdown shows you the engagement scores specific to this
      Priority, across your company, by both position category and region.
    </template>
  </breakdown-charts>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    priorityId: { type: [String, Number], required: true },
    flat: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        breakdown: { type: "engagement", factor_code: null },
      },
    };
  },

  computed: {
    ...mapState({
      breakdown: state => state.priorities.breakdown,
    }),
  },

  watch: {
    "filters.breakdown.factor_code": function() {
      this.fetchBreakdown();
    },
  },

  created() {
    this.fetchBreakdown();
  },

  methods: {
    fetchBreakdown() {
      this.$store.dispatch("priorities/fetchBreakdown", {
        id: this.priorityId,
        filters: this.filters.breakdown,
      });
    },

    formattedYear(date) {
      return new Date(Date.parse(date)).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      });
    },
  },
};
</script>
