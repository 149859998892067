<template>
  <v-col lg="8" cols="12">
    <div class="survey-question__input">
      <v-row align="center" no-gutters>
        <v-col md="6" cols="12">
          <div class="survey-question__inputs__bar">
            <Scale
              type="agreement"
              :question="question"
              :response="response"
              class="first-question"
            />
          </div>
        </v-col>

        <v-col md="6" cols="12">
          <div class="survey-question__inputs__bar">
            <Scale
              type="importance"
              :question="question"
              :response="response"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    question: { type: Object, required: true },
    response: { type: Object, required: true },
  },
};
</script>
