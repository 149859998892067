import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-card',{staticClass:"mt-4",attrs:{"dense":"","flush-body":"","collapse-actions":"","actions-icon":"mdi-filter-variant","actions-header-text":"Filters"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Reporting "),_c(VTooltip,{attrs:{"right":"","max-width":"400px","color":"rgba(0, 0, 0, 0.7)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('cp-btn',_vm._g({staticStyle:{"margin-left":"0px"},attrs:{"small":"","icon":"","color":"primary"}},on),[_c('cp-icon',{attrs:{"icon":"info-outlined"}})],1)]}}])},[_c('span',[_vm._v(" Use the dropdown menu in the header to select different reports. You can look through a preview of the results here or download them for viewing in your spreadsheet program of choice with the \"Export\" button. ")])])]},proxy:true},{key:"actions",fn:function(){return [_c('cp-btn',{staticClass:"mr-4",attrs:{"text":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){_vm.reportSchedulerOpen = true}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-timetable")]),_vm._v(" Automated Reports ")],1),(_vm.clientPastFirstYear)?_c(VSelect,{staticClass:"select-with-headers mr-8",attrs:{"items":_vm.timePeriodOptions,"hide-details":"","max-height":"auto","dense":""},model:{value:(_vm.activeYear),callback:function ($$v) {_vm.activeYear=$$v},expression:"activeYear"}}):_vm._e(),_c(VSelect,{staticClass:"select-with-headers",attrs:{"items":_vm.reportOptions,"hide-details":"","max-height":"auto","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"select__add-icon"},[_c(VRow,{staticClass:"grouped",attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"add-icon__option"},[_vm._v(" "+_vm._s(item.text)+" ")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('cp-btn',{class:{
        'ing-disable-export': _vm.exportDisabled,
      },attrs:{"text":"","rounded":"","small":"","color":"primary","disabled":_vm.exportDisabled},on:{"click":_vm.downloadReport}},[_c('cp-icon',{staticClass:"mr-2",attrs:{"xSmall":"","icon":"share","color":"currentColor"}}),_vm._v(" Export ")],1)]},proxy:true}])},[(!_vm.reportLoading && (_vm.report.data == null || !_vm.report.data.length))?_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_vm._v(" No results found for this report. ")])],1):_c('cp-table',{ref:"reportDataTable",staticClass:"reporting-table-wrapper",attrs:{"headers":_vm.report.headers,"items":_vm.report.data,"add-header-tooltips":"","loading":_vm.reportLoading},scopedSlots:_vm._u([{key:"item-columns",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.report.headers),function(header,i){return _c('td',{key:("header-" + (header.value) + "-" + i),class:header.size ? ("table-col-" + (header.size)) : ''},[_vm._v(" "+_vm._s(item[header.value])+" ")])})}}])}),_c('cp-dialog',{attrs:{"show-model":_vm.reportSchedulerOpen,"max-width":"1200","header-background":"","scrollable":"","hide-scroll-indicator":"","persistent":""},on:{"update:showModel":function($event){_vm.reportSchedulerOpen=$event},"update:show-model":function($event){_vm.reportSchedulerOpen=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Automated Report Scheduling ")]},proxy:true},{key:"body",fn:function(){return [_c('cp-report-scheduler',{ref:"reportScheduler",attrs:{"scheduled-reports":_vm.reportsModel.scheduledReports,"error":_vm.reportsModel.scheduledReports.error},on:{"searchUsers":function($event){return _vm.$store.dispatch('reports/fetchRecipients', $event)},"deleteSchedule":function($event){return _vm.$store.dispatch('reports/deleteScheduledReport', $event)},"create":function($event){return _vm.$store.dispatch('reports/createScheduledReport', $event)},"save":function($event){return _vm.$store.dispatch('reports/updateScheduledReport', $event)},"clearError":function($event){_vm.reportsModel.scheduledReports.error = null}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }