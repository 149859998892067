<template>
  <div class="dashboard">
    <template v-if="summary">
      <onboarding-dashboard v-if="summary.onboarding" />

      <template v-else>
        <v-row class="mt-3">
          <v-col cols="12" class="pt-0">
            <cp-card
              flush-body
              dense
              collapse-actions
              actions-icon="mdi-filter-variant"
              actions-header-text="Filters"
              class="card--engagement-overview card--dashboard"
              :filter-count="filterCounts('overview')"
            >
              <template #title>
                <h3>
                  Engagement Overview
                  <cp-tooltip right>
                    <template #activator="{on}">
                      <cp-btn small icon color="primary" v-on="on">
                        <cp-icon icon="info-outlined" />
                      </cp-btn>
                    </template>
                    Review your company’s key engagement stats. Compare current
                    metrics with past performance and the Swift Bunny Index (SB
                    Index), which is the industry benchmark based on all
                    clients. Use filters to narrow results to specific regions
                    and positions.
                  </cp-tooltip>
                </h3>
              </template>

              <template #actions>
                <cp-select
                  v-model="filters.overview.region_id"
                  :items="regionOptions"
                  dense
                  hide-details
                  class="v-input"
                />
                <cp-select
                  v-model="filters.overview.position_id"
                  :items="positionOptions"
                  dense
                  hide-details
                  class="v-input"
                />
              </template>

              <div class="engagement-overview__content dashboard__content">
                <template v-if="summary.engagement">
                  <no-data-dialog :show="summary.represented_employees < 5" />

                  <v-row>
                    <v-col class="content__score" md="auto" sm="12">
                      <h2>Your Engagement Score</h2>
                      <v-row
                        align="center"
                        justify-md="space-between"
                        justify-sm="center"
                      >
                        <donut-chart
                          :outer-chart-data="summary.engagement.current"
                          :inner-chart-data="summary.engagement.previous"
                          :sb-index="summary.engagement.sbx"
                          :large="true"
                          :is-percent="false"
                          main-label="Engagement"
                          inner-label="Previous"
                          main-color="rgba(0, 138, 9, 1)"
                        />
                        <div class="score--left">
                          <div class="score__attr">
                            <v-row>
                              <cp-icon icon="people" />
                              <div>
                                <span>{{ summary.employees || "NA" }}</span>
                                Employees
                              </div>
                            </v-row>
                          </div>
                        </div>
                      </v-row>
                    </v-col>

                    <v-col class="content__distribution" md="" sm="12">
                      <h2
                        style="display: inline-block; vertical-align: middle;"
                      >
                        Likelihood to Remain
                      </h2>
                      <cp-tooltip right>
                        <template #activator="{on}">
                          <cp-btn small icon color="primary" v-on="on">
                            <cp-icon icon="info-outlined" />
                          </cp-btn>
                        </template>
                        This distribution is based on employees’ responses to a
                        question on how likely they are to remain with the
                        company for the next year.
                      </cp-tooltip>
                      <distribution
                        :distribution="summary.likelihood_distribution"
                      />
                      <div class="score--right">
                        <v-row align="center" justify="space-around">
                          <div class="score__attr">
                            <v-row>
                              <cp-icon icon="timerange" />
                              <div>
                                <span>{{ summary.range || "NA" }}</span>
                                Time Range
                              </div>
                            </v-row>
                          </div>
                          <div class="score__attr">
                            <v-row>
                              <cp-icon icon="people" />
                              <div>
                                <span>{{ summary.employees || "NA" }}</span>
                                Employees
                              </div>
                            </v-row>
                          </div>
                        </v-row>
                      </div>
                      <v-row
                        class="distribution__donuts"
                        justify="space-between"
                      >
                        <donut-chart
                          :outer-chart-data="summary.response_rate.current"
                          :inner-chart-data="summary.response_rate.previous"
                          :sb-index="summary.response_rate.sbx"
                          main-color="rgba(0, 138, 9, 1)"
                          chart-label="RESPONSE RATE"
                        />

                        <donut-chart
                          :outer-chart-data="summary.turnover.current"
                          :inner-chart-data="summary.turnover.previous"
                          :sb-index="summary.turnover.sbx"
                          main-color="rgba(0, 138, 9, 1)"
                          chart-label="TURNOVER"
                        />

                        <div class="donut-static">
                          <div class="circle">
                            <cp-icon icon="tenure" />
                            <div class="primary-value">
                              <span class="value">
                                {{ summary.tenure.current || "NA" }}
                              </span>
                              <span class="label">Years</span>
                            </div>
                            <div class="secondary-value">
                              <span class="value">
                                {{ summary.tenure.sbx || "NA" }}
                              </span>
                              <span class="label">Years</span>
                            </div>
                          </div>
                          <div class="donut-label">
                            Current Tenure
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <loader v-else style="margin-top: 100px;" />
              </div>

              <Legend :items="legendItems" />
            </cp-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <breakdown-charts
              title="Engagement Breakdown"
              :breakdown="breakdown"
              :filters.sync="filters.breakdown"
              :legend-items="legendItems"
            >
              <template #tooltip>
                Compare engagement scores by position and region. Filter by
                SWIFT Factor to identify areas of strength and opportunity.
              </template>
              <template #filters>
                <surveys-filter
                  v-model="filters.breakdown.survey_slug"
                  :surveys="surveys"
                />
                <swift-factors-filter v-model="filters.breakdown.factor_code" />
              </template>
            </breakdown-charts>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6">
            <cp-card
              dense
              flush-body
              style="height: 100%;"
              collapse-actions-always
              actions-icon="mdi-filter-variant"
              actions-header-text="Filters"
              :filter-count="filterCounts('priorities')"
            >
              <template #title>
                <h3>
                  Current Priorities
                  <cp-tooltip right>
                    <template #activator="{on}">
                      <cp-btn small icon color="primary" v-on="on">
                        <cp-icon icon="info-outlined" />
                      </cp-btn>
                    </template>
                    Priorities are survey topics ranked according to their
                    Action Item Quotient (AIQ), a proprietary algorithm that
                    assigns a score between 1 and 100. The lower the AIQ, the
                    higher the opportunity; the higher the AIQ, the greater the
                    strength. Select export to download to a spreadsheet.
                  </cp-tooltip>
                  <cp-btn
                    small
                    depressed
                    rounded
                    color="primary"
                    @click="exportPriorities"
                    :disabled="!prioritiesAvailable"
                  >
                    Export <v-icon class="ml-2" small>mdi-export</v-icon>
                  </cp-btn>
                </h3>
              </template>

              <template #actions>
                <cp-select
                  v-model="filters.priorities.region_id"
                  :items="regionOptions"
                  dense
                  hide-details
                  class="v-input"
                />
                <surveys-filter
                  v-model="filters.priorities.survey_slug"
                  :surveys="surveys"
                />
              </template>

              <v-container v-if="prioritiesAvailable">
                <v-row>
                  <priority-list
                    :priorities="priorities.opportunities"
                    title="Top Opportunities"
                    tooltip="Improving these areas will have the greatest impact on increasing engagement."
                  />

                  <v-divider vertical />

                  <priority-list
                    :priorities="priorities.strengths"
                    title="Top Strengths"
                    tooltip="These are your highest performing areas. Consider ways to reinforce and sustain your strengths."
                  />
                </v-row>
              </v-container>
              <h4 v-else class="loading-text">
                No priority data available for selected filters.
              </h4>
            </cp-card>
          </v-col>

          <v-col cols="12" lg="6">
            <cp-card
              dense
              style="height: 100%;"
              collapse-actions-always
              actions-icon="mdi-filter-variant"
              actions-header-text="Filters"
              :filter-count="filterCounts('feedback')"
            >
              <template #title>
                <h3>
                  Employee Feedback
                  <cp-tooltip right>
                    <template #activator="{on}">
                      <cp-btn small icon color="primary" v-on="on">
                        <cp-icon icon="info-outlined" />
                      </cp-btn>
                    </template>
                    These comments are from the past 6 months - sorted most
                    recent to oldest - and are organized based on how likely
                    your employees are to remain with your company, according to
                    their survey responses. For simplicity, employees' comments
                    falling within the very likely or very unlikely categories
                    are merged with likely or unlikely, respectively. Comments
                    may be filtered by survey. Select export to download to a
                    spreadsheet.
                  </cp-tooltip>
                  <cp-btn
                    small
                    depressed
                    rounded
                    color="primary"
                    @click="exportFeedback"
                    :disabled="!feedbackAvailable"
                  >
                    Export <v-icon class="ml-2" small>mdi-export</v-icon>
                  </cp-btn>
                </h3>
              </template>

              <template #actions>
                <cp-select
                  v-model="filters.feedback.region_id"
                  :items="regionOptions"
                  dense
                  hide-details
                  class="v-input"
                  :clearable="!!filters.feedback.region_id"
                />
                <surveys-filter
                  v-model="filters.feedback.survey_slug"
                  :surveys="surveys"
                  :clearable="!!filters.feedback.survey_slug"
                />
              </template>

              <tabs
                v-if="feedback"
                :tabs="feedbackOptions"
                :items="filteredFeedback"
                :start="1"
              />
              <h4 v-else class="loading-text">Loading...</h4>
            </cp-card>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dashboard",

  data() {
    return {
      filters: {
        survey_slug: null,
        factor_code: null,
        overview: {
          region_id: null,
          position_id: null,
        },
        breakdown: {
          factor_code: null,
          survey_slug: null,
        },
        priorities: {
          survey_slug: null,
          region_id: null,
        },
        feedback: {
          survey_slug: null,
          region_id: null,
        },
      },
      feedbackOptions: [
        { text: "Unlikely to Remain", key: "unlikely_to_remain" },
        { text: "Neutral", key: "neutral" },
        { text: "Likely to Remain", key: "likely_to_remain" },
      ],
    };
  },

  computed: {
    ...mapState({
      summary: state => state.dashboard.summary,
      breakdown: state => state.dashboard.breakdown,
      feedback: state => state.dashboard.feedback,
      priorities: state => state.dashboard.priorities,
      regions: state => state.engage.regions,
      positions: state => state.engage.positions,
      surveys: state => state.dashboard.surveys,
      dashboard: state => state.dashboard,
    }),

    prioritiesAvailable() {
      return (
        this.priorities.strengths.length && this.priorities.opportunities.length
      );
    },

    feedbackAvailable() {
      if (!this.dashboard.feedback) return false;

      return (
        this.filteredFeedback.likely_to_remain.length ||
        this.filteredFeedback.neutral.length ||
        this.filteredFeedback.unlikely_to_remain.length
      );
    },

    regionOptions() {
      return [
        { text: "All Regions", value: null },
        ...this.regions.map(({ name, id }) => ({ text: name, value: id })),
      ];
    },

    surveyOptions() {
      return [
        { text: "All Surveys", value: null },
        ...this.surveys.map(({ name, slug }) => ({ text: name, value: slug })),
      ];
    },

    positionOptions() {
      return [
        { text: "All Positions", value: null },
        ...this.positions.map(({ name, id }) => ({ text: name, value: id })),
      ];
    },

    clientFirstYear() {
      return this.summary.date_range.previous_year.start_date == null;
    },

    legendItems() {
      let previousScoreText = "Baseline Results";

      if (this.summary.date_range.previous_year.start_date != null) {
        let previousFirst = this.formattedYear(
          this.summary.date_range.previous_year.start_date
        );
        let previousLast = this.formattedYear(
          this.summary.date_range.previous_year.end_date
        );
        previousScoreText = `${previousFirst} - ${previousLast}`;
      }

      return [
        {
          color: "your-score",
          text: `Current Score (${this.formattedYear(
            this.summary.date_range.current_year.start_date
          )} - Today)`,
        },
        {
          color: "previous-score",
          text: `Previous Score (${previousScoreText})`,
        },
        {
          color: "sb-index",
          text: "SB Index",
        },
      ];
    },

    filteredFeedback() {
      let baseFeedbackObject = {
        likely_to_remain: this.dashboard.feedback.likely_to_remain || [],
        neutral: this.dashboard.feedback.neutral || [],
        unlikely_to_remain: this.dashboard.feedback.unlikely_to_remain || [],
      };

      if (this.filters.feedback.region_id == null) {
        return baseFeedbackObject;
      }

      return {
        likely_to_remain: baseFeedbackObject.likely_to_remain.filter(
          x => x.region_id == this.filters.feedback.region_id
        ),
        neutral: baseFeedbackObject.neutral.filter(
          x => x.region_id == this.filters.feedback.region_id
        ),
        unlikely_to_remain: baseFeedbackObject.unlikely_to_remain.filter(
          x => x.region_id == this.filters.feedback.region_id
        ),
      };
    },
  },

  watch: {
    "filters.overview": {
      handler: function() {
        this.fetchSummary();
      },
      deep: true,
    },

    "filters.breakdown.survey_slug": function(slug) {
      if (!slug && this.filters.breakdown.factor_code) return;

      this.filters.breakdown.factor_code = null;
      this.fetchBreakdown();
    },

    "filters.breakdown.factor_code": function(code) {
      if (!code && this.filters.breakdown.survey_slug) return;

      this.filters.breakdown.survey_slug = null;
      this.fetchBreakdown();
    },

    "filters.feedback.survey_slug": function() {
      this.fetchFeedback();
    },

    "filters.priorities": {
      handler: function() {
        this.fetchPriorities();
      },
      deep: true,
    },
  },

  created() {
    this.fetchSummary();
    this.fetchBreakdown();
    this.fetchFeedback();
    this.fetchPriorities();
    this.$store.dispatch("fetchRegions");
    this.$store.dispatch("fetchPositions", {});
    this.$store.dispatch("dashboard/fetchSurveys");
  },

  methods: {
    fetchSummary() {
      this.$store.dispatch("dashboard/fetchSummary", {
        region_id: this.filters.overview.region_id,
        position_id: this.filters.overview.position_id,
      });
    },

    fetchBreakdown() {
      this.$store.dispatch("dashboard/fetchBreakdown", this.filters.breakdown);
    },

    fetchFeedback() {
      this.$store.dispatch("dashboard/fetchFeedback", {
        survey_slug: this.filters.feedback.survey_slug,
      });
    },

    fetchPriorities() {
      this.$store.dispatch(
        "dashboard/fetchPriorities",
        this.filters.priorities
      );
    },

    exportPriorities() {
      this.$store.dispatch("dashboard/exportPriorities", {
        ...this.filters.priorities,
        region_name: this.regionOptions.find(
          x => x.value == this.filters.priorities.region_id
        ).text,
        survey_name: this.surveyOptions.find(
          x => x.value == this.filters.priorities.survey_slug
        ).text,
      });
    },

    exportFeedback() {
      this.$store.dispatch("dashboard/exportFeedback", {
        ...this.filters.feedback,
        region_name: this.regionOptions.find(
          x => x.value == this.filters.feedback.region_id
        ).text,
        survey_name: this.surveyOptions.find(
          x => x.value == this.filters.feedback.survey_slug
        ).text,
      });
    },

    formattedYear(date) {
      return new Date(Date.parse(date)).toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      });
    },

    filterCounts(name) {
      if (!name || !this.filters[name]) {
        return 0;
      }

      let selections = [];

      Object.keys(this.filters[name]).forEach(key => {
        selections.push(!!this.filters[name][key]);
      });

      return selections.filter(x => x).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-text {
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}
</style>
