<template>
  <cp-select
    v-model="slug"
    :items="options"
    dense
    hide-details
    class="v-input"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, default: null },
    surveys: { type: Array, required: true },
  },

  computed: {
    slug: {
      get() {
        return this.value;
      },
      set(slug) {
        this.$emit("input", slug);
      },
    },

    options() {
      return [
        { text: "All Surveys", value: null },
        ...this.surveys.map(({ name, slug }) => ({ text: name, value: slug })),
      ];
    },
  },
};
</script>
