import { Dashboard, Survey, PrioritiesSwitch, ReportingIndex } from "@/views";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/surveys/:slug",
    name: "survey",
    // route level code-splitting
    // this generates a separate chunk (survey.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Survey,
    meta: {
      fullWidth: true,
    },
  },
  {
    path: "/profile",
    name: "survey.profile",
    component: Survey,
    beforeEnter(to, from, next) {
      window.location.href = process.env.VUE_APP_DARKHAVEN_UI_PATH + "/profile";
    },
    meta: {
      fullWidth: true,
      pageTitle: "Profile Survey",
    },
  },
  {
    path: "/priorities",
    name: "priorities.index",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Priorities" },
  },
  {
    path: "/priorities/:id",
    name: "priorities.show",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Priorities" },
  },
  {
    path: "/priorities/:id/history",
    name: "priorities.history",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Priority History" },
  },
  {
    path: "/priorities/:priorityId/assignments/:assignmentId/history",
    name: "assignments.history",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Assignment History" },
  },
  {
    path: "/priorities/:priorityId/assignments/create",
    name: "assignments.create",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Assignment" },
  },
  {
    path: "/priorities/:priorityId/assignments/:assignmentId/edit",
    name: "assignments.edit",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Assignment" },
  },
  {
    path: "/priorities/:priorityId/assignments/:assignmentId",
    name: "assignments.show",
    component: PrioritiesSwitch,
    meta: { pageTitle: "Assignment" },
  },
  {
    path: "/reporting",
    name: "reporting.index",
    component: ReportingIndex,
    meta: { pageTitle: "Reporting" },
  },
];

export default routes.map(route => {
  if (!route.props) {
    route.props = route => ({
      ...route.query,
      ...route.params,
    });
  } else if (route.props.viewMode) {
    const viewMode = route.props.viewMode;
    route.props = route => ({
      viewMode,
      ...route.query,
      ...route.params,
    });
  }
  return route;
});
