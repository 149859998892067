<template>
  <div class="bar-chart">
    <v-row dense justify="end">
      <v-col xs="4" sm="3" md="6" lg="4">
        <div class="bar-chart__left-text" :title="item.label">
          {{ item.label }}
        </div>
      </v-col>
      <v-col xs="7" sm="8" md="5" lg="7">
        <div class="bar-chart__bar">
          <div class="bar__top-value" :style="`left: ${item.percentage.sbx}%`">
            <cp-tooltip right>
              <template #activator="{on}">
                <div class="sb-index-tic-box" v-on="on">
                  <div class="sb-index-tic"></div>
                </div>
              </template>
              <span class="sb-index-box"></span>
              SB Index: {{ item.sbx }}
            </cp-tooltip>
          </div>
          <v-progress-linear
            :value="item.percentage.value"
            :height="12"
            color="#008A09"
            background-color="#eee"
            :background-opacity="1"
          />
          <v-progress-linear
            v-if="item.previousPercentage"
            :value="item.previousPercentage"
            :height="8"
            color="#6b6b70"
            background-color="#eee"
            :background-opacity="1"
            style="border-top: 1px solid white;"
          />
          <slot name="steps"></slot>
        </div>
      </v-col>
      <v-col cols="auto">
        <div class="bar-chart__right-value">
          {{ item.current_value || "NA" }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
