<template>
  <v-btn
    depressed
    block
    color="grey lighten-4"
    class="ing-single-row-link text-left"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <cp-icon
      v-if="showSBIcon"
      small
      color="#00778B"
      icon="swift-bunny-icon"
      class="mr-2"
    />
    {{ title }}
    <v-spacer />
    <v-icon class="ing-single-row-link__icon" large>mdi-chevron-right</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    showSBIcon: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.ing-single-row-link {
  text-transform: none;

  .ing-single-row-link__icon.v-icon.v-icon {
    transition: all 0.2s ease-in-out;
    left: 0;
  }

  &:hover {
    .ing-single-row-link__icon.v-icon.v-icon {
      color: $primary;
      left: 10px;
    }
  }
}
</style>
