import { Line } from "vue-chartjs";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.plugins.unregister(ChartDataLabels);

export default {
  extends: Line,

  props: {
    chartData: { type: Object, required: false, default: null },
    min: { type: Number, required: false, default: 0 },
    max: { type: Number, required: false, default: 1000 },
    steps: { type: Number, required: false, default: 250 },
    primaryValues: { type: Array, default: () => [] },
    secondaryValues: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
  },

  computed: {
    datasets() {
      return {
        labels: this.labels || [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
        ],
        datasets: [
          {
            fill: false,
            backgroundColor: "#009a44",
            borderColor: "#009a44",
            data: this.primaryValues,
            borderWidth: 2,
            order: 1,
            pointHitRadius: 14,
          },
          {
            fill: false,
            backgroundColor: "#0070a8",
            borderColor: "#0070a8",
            data: this.secondaryValues,
            pointBorderWidth: 5,
            borderWidth: 4,
            order: 0,
            pointHitRadius: 14,
          },
        ],
      };
    },

    options() {
      return {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: this.min,
                max: this.max,
                stepSize: this.steps,
              },
              gridLines: {
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                maxRotation: 40,
                minRotation: 40,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "x",
          intersect: false,
          titleFontSize: 13,
          bodyFontSize: 13,
          callbacks: {
            title: function(tooltipItems, data) {
              return `Day ${tooltipItems[0].label}`;
            },
            label: function(tooltipItem, data) {
              return ` ${tooltipItem.value} ${
                tooltipItem.datasetIndex == 0 ? "Started" : "Completed"
              }`;
            },
          },
        },
        legend: {
          display: false,
        },
      };
    },
  },

  watch: {
    primaryValues() {
      this.updateChart();
    },
  },

  mounted() {
    window.setTimeout(() => {
      this.updateChart();
    }, 200);
  },

  methods: {
    updateChart() {
      this.$nextTick(() => {
        this.renderChart(this.datasets, this.options);
      });
    },
  },
};
