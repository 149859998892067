<template>
  <cp-fullscreen-modal>
    <template #activator="{on}">
      <cp-btn v-if="arrowOnly" icon v-on="on">
        <cp-icon icon="chevron-right" class="option__icon-arrow" />
      </cp-btn>
      <a v-else-if="textArrowOnWhite" v-on="on">
        <cp-card dense flushBody outlined class="background--gray">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col class="single-line-click__first" cols="auto">
              <div class="swift-letter-boxed">
                <cp-icon icon="swift-bunny-icon" />
              </div>
            </v-col>
            <v-col>
              <div class="single-line-click__text">
                {{ item.title }}
              </div>
            </v-col>
            <v-col class="single-line-click__last" cols="auto">
              <cp-icon class="last__icon" icon="chevron-right" />
            </v-col>
          </v-row>
        </cp-card>
      </a>
      <div v-else class="pattern__single-line-click">
        <a v-on="on">
          <cp-card class="background--gray" dense flushBody outlined>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col>
                <div class="single-line-click__text">
                  {{ item.title }}
                </div>
              </v-col>
              <v-col class="single-line-click__last" cols="auto">
                <cp-icon class="last__icon" icon="chevron-right" />
              </v-col>
            </v-row>
          </cp-card>
        </a>
      </div>
    </template>
    <template #default="{close}">
      <cp-modal title="Action Item Detail" @back="close" @close="close">
        <cp-card class="action-item-detail">
          <div class="padding-20">
            <h6>Action Item</h6>
            <h6 class="alt-text">{{ item.title }}</h6>
          </div>

          <cp-card class="gray-background" dense flushBody outlined>
            <h6>Description</h6>
            <h6 class="alt-text" v-html="item.body"></h6>

            <template v-if="item.attachments.length">
              <hr />
              <h6>Downloadable Resources</h6>
              <div class="pattern__single-line-click">
                <a
                  v-for="(attachment, index) in item.attachments"
                  :key="index"
                  :href="attachment.url"
                  target="_blank"
                >
                  <cp-card dense flushBody outlined>
                    <v-row align="center" justify="space-between" no-gutters>
                      <v-col class="single-line-click__first" cols="auto">
                        <cp-icon class="first__icon" icon="swift-bunny-icon" />
                      </v-col>
                      <v-col>
                        <div class="single-line-click__text">
                          {{ attachment.name }}
                        </div>
                      </v-col>
                      <v-col class="single-line-click__last" cols="auto">
                        <cp-icon class="last__icon" icon="chevron-right" />
                      </v-col>
                    </v-row>
                  </cp-card>
                </a>
              </div>
            </template>
          </cp-card>

          <div v-if="$slots.actions" class="card__footer">
            <v-container>
              <v-row align="center">
                <div class="ml-auto">
                  <cp-btn color="primary" text @click="close">
                    Cancel
                  </cp-btn>
                  <slot name="actions"></slot>
                </div>
              </v-row>
            </v-container>
          </div>
        </cp-card>
      </cp-modal>
    </template>
  </cp-fullscreen-modal>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    arrowOnly: { type: Boolean, default: false },
    textArrowOnWhite: { type: Boolean, default: false },
  },
};
</script>
