<template>
  <div :class="wrapperClass">
    <div v-if="sbIndex != null" ref="sbIndex" class="sb-index-tic-wrapper">
      <cp-tooltip right>
        <template #activator="{on}">
          <div class="sb-index-tic-box" v-on="on">
            <div class="sb-index-tic"></div>
          </div>
        </template>
        <span class="sb-index-box"></span>
        SB Index: {{ sbIndex }}
      </cp-tooltip>
    </div>
    <doughnut ref="mainChart" class="donut-chart" />
    <doughnut ref="innerChart" class="donut-chart donut-chart--inner-ring" />
    <div class="donut-chart-inner-display">
      <div
        v-if="displayCurrentValue"
        class="donut-chart-inner-display__primary-value"
      >
        {{ currentValue }}
      </div>
      <div v-if="mainLabel" class="donut-chart-inner-display__primary-label">
        {{ outerChartData ? mainLabel : "" }}
      </div>
      <div
        v-if="includePrevious"
        class="donut-chart-inner-display__secondary-value"
      >
        {{ previousValue }}
      </div>
      <div v-if="innerLabel" class="donut-chart-inner-display__inner-label">
        {{ innerChartData ? innerLabel : "" }}
      </div>
    </div>
    <div v-if="chartLabel" class="donut-chart-label">{{ chartLabel }}</div>
  </div>
</template>

<script>
import { Doughnut, mixins } from "vue-chartjs";

export default {
  components: { Doughnut },

  extends: Doughnut,

  mixins: [mixins.reactiveProp],

  props: {
    displayCurrentValue: { type: Boolean, default: true },
    chartData: { type: Number, default: 0 },
    allowZero: { type: Boolean, default: false },
    outerChartData: { type: Number, default: 0 },
    innerChartData: { type: Number, default: 0 },
    sbIndex: { type: Number, default: 0 },
    mainLabel: { type: String, default: "" },
    innerLabel: { type: String, default: "" },
    mainColor: { type: String, default: "rgba(0, 200, 200, 1)" },
    innerColor: { type: String, default: "rgba(107, 107, 112, 1)" },
    large: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    isPercent: { type: Boolean, default: true },
    chartLabel: { type: String, default: "" },
    thickness: { type: Number, default: 83 },
    includePrevious: { type: Boolean, default: true },
  },

  data() {
    return {
      options: {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          display: false,
        },
        hover: {
          mode: null,
        },
        tooltips: {
          enabled: false,
          filter: function(tooltip) {
            if (tooltip.index == 1) {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };
  },

  computed: {
    currentValue() {
      if (!this.outerChartData) {
        return this.allowZero ? 0 : "NA";
      }

      return this.outerChartData + (this.isPercent ? "%" : "");
    },

    previousValue() {
      if (!this.innerChartData) {
        return this.allowZero ? 0 : "NA";
      }

      return this.innerChartData + (this.isPercent ? "%" : "");
    },

    inner() {
      return !!this.innerChartData;
    },

    wrapperClass() {
      let computedClass = "donut-chart-wrapper";
      if (this.large) {
        computedClass = computedClass + " donut-chart-is-large";
      } else if (this.medium) {
        computedClass = computedClass + " donut-chart-is-medium";
      } else if (this.small) {
        computedClass = computedClass + " donut-chart-is-small";
      }

      return computedClass;
    },

    mainChart() {
      return {
        datasets: [
          {
            data: [this.outerChartData, 100 - this.outerChartData],
            backgroundColor: [this.mainColor, "rgba(238, 238, 238, 1)"],
            borderWidth: [0, 0],
            hoverBorderWidth: [0, 0],
            borderColor: [this.mainColor, "rgba(238, 238, 238, 1)"],
          },
        ],
        labels: [this.mainLabel],
      };
    },

    innerChart() {
      return {
        datasets: [
          {
            data: [this.innerChartData, 100 - this.innerChartData],
            backgroundColor: [this.innerColor, "rgba(238, 238, 238, 1)"],
            borderWidth: [0, 0],
            hoverBorderWidth: [0, 0],
            borderColor: [this.innerColor, "rgba(238, 238, 238, 1)"],
          },
        ],
        labels: [this.innerLabel],
      };
    },
  },

  watch: {
    outerChartData() {
      this.renderCharts();
    },

    innerChartValue() {
      this.renderCharts();
    },

    sbIndex() {
      this.updateSBIndex();
    },
  },

  mounted() {
    this.renderCharts();
    this.updateSBIndex();
  },

  methods: {
    renderCharts: function() {
      this.renderOuterChart();

      if (this.includePrevious) {
        this.renderInnerChart();
      }
    },

    renderOuterChart() {
      let outerOptions = this.options;
      outerOptions.cutoutPercentage = this.thickness;
      this.$refs.mainChart.renderChart(this.mainChart, outerOptions);
    },

    renderInnerChart() {
      let innerOptions = this.options;
      innerOptions.cutoutPercentage = 87;
      this.$refs.innerChart.renderChart(this.innerChart, innerOptions);
    },

    updateSBIndex: function() {
      if (this.$refs.sbIndex == null) {
        return;
      }

      let rotationAmount = Math.floor((this.sbIndex / 100) * 360);
      this.$refs.sbIndex.style.transform = `rotate(${rotationAmount}deg)`;
    },
  },
};
</script>
