<template>
  <cp-card
    dense
    flush-body
    collapse-actions
    actions-icon="mdi-filter-variant"
    actions-header-text="Filters"
    class="mt-4"
  >
    <template #title>
      Priorities
      <cp-dialog width="700px" inline-activator>
        <template #activator="{on}">
          <cp-btn small icon color="primary" v-on="on" class="d-inline-block">
            <cp-icon icon="info-outlined" />
          </cp-btn>
        </template>

        <template #title>
          Using the Priorities Page
        </template>

        <template #body>
          <h4 class="h4 font-weight-bold">Score</h4>
          <p v-if="!clientFirstYear">
            Shows the score for your current year, unless the Change column has
            a
            <v-icon>mdi-gauge-low</v-icon>
            icon, in which case you'll see the score from your previous year.
          </p>
          <p v-else>
            Shows your current score for this Priority.
          </p>

          <template v-if="!clientFirstYear">
            <h4 class="h4 font-weight-bold">Change</h4>
            <p>
              Shows the amount your score has increased
              <v-icon color="success">mdi-menu-up</v-icon>
              or decreased
              <v-icon color="error">mdi-menu-down</v-icon>
              from your previous year. You'll see a 0
              <v-icon>mdi-minus</v-icon>
              if there was no change, and a
              <v-icon>mdi-gauge-low</v-icon>
              if we have not yet received enough responses to calculate a score
              for this Priority for your current year.
            </p>
          </template>

          <h4 class="h4 font-weight-bold">Priority</h4>
          <p>
            Every question that appears on our surveys is tied to a Priority.
            These are key areas we've identified that enable us to measure your
            employees' levels of engagement in various categories.
          </p>

          <h4 class="h4 font-weight-bold">Assignments</h4>
          <p>
            Assignments are targeted intiatives you can take to impact your
            Priorities' scores. We have several recommended Action Items, for
            each Priority, that give you step-by-step instructions. Click into
            any of the Priorities to learn more.
          </p>
        </template>
      </cp-dialog>
    </template>

    <template #actions>
      <v-switch
        v-if="isActionTeamMember"
        v-model="onlyShowCurrentUserAssignments"
        hide-details
        label="Show Only My Priorities/Assignments"
        class="ing-toggle mr-4"
      />
      <v-text-field
        v-model="search"
        placeholder="Search"
        hide-details
        dense
        append-icon="search"
        class="mr-2"
      />
      <cp-select
        v-model="filters.region_id"
        :items="regionOptions"
        dense
        hide-details
        class="mr-2 v-input"
      />
      <surveys-filter
        v-model="filters.survey_slug"
        :surveys="survey_filters"
        class="mr-2"
      />
      <swift-factors-filter v-model="filters.factor_code" />
    </template>

    <cp-table
      ref="table"
      :headers="computedHeaders"
      sort-by="aiq"
      :items="filteredPriorities"
      :search="search"
      class="priorities-table"
      item-url="/priorities/{id}"
      :loading-text="resultText"
    >
      <template #item-columns="{item, isExpanded, expand}">
        <td class="score">
          <div
            class="wrapper"
            :class="[`background--swift-${item.factor.name.toLowerCase()}`]"
          >
            <v-row align="center" justify="space-around" no-gutters>
              <span>
                {{ item.aiq || "-" }}
              </span>
            </v-row>
          </div>
        </td>
        <td
          v-if="!clientFirstYear"
          class="name"
          align="center"
          style="width: 100px;"
        >
          <span v-if="Number.isInteger(item.score_delta)">
            {{ item.score_delta }}
            <v-icon
              v-if="item.score_delta > 0"
              color="success"
              large
              right
              class="column-icon"
            >
              mdi-menu-up
            </v-icon>
            <v-icon
              v-else-if="item.score_delta < 0"
              color="error"
              large
              right
              class="column-icon"
            >
              mdi-menu-down
            </v-icon>
            <v-icon v-else large right class="column-icon">
              mdi-minus
            </v-icon>
          </span>
          <v-icon v-else class="column-icon column-icon--center">
            mdi-gauge-low
          </v-icon>
        </td>
        <td class="name">{{ item.body }}</td>
        <td
          v-if="item.assignment_count"
          class="assignments no-redirect"
          :class="{ 'expand-icon': isExpanded }"
          @click="showAssignments(item, isExpanded, expand)"
        >
          <cp-tooltip right>
            <template #activator="{on}">
              <div class="dot-wrapper">
                <span v-if="item.action_required" v-on="on"></span>
              </div>
            </template>
            <h1>Attention Required</h1>
          </cp-tooltip>

          {{ item.assignment_count }} Active
          <cp-icon icon="arrow-drop" />
        </td>
        <td v-else class="assignments">
          <div class="dot-wrapper"></div>
          -
        </td>
        <td class="actions">
          <cp-icon icon="chevron-right" />
        </td>
      </template>
      <template #expanded-item="{item}">
        <div class="assignments-expanded">
          <loader v-if="!item.assignments.length" />
          <router-link
            v-for="assignment in item.assignments.filter(
              x => !filteredAssignments.includes(x.status)
            )"
            :key="assignment.id"
            :to="{
              name: 'assignments.show',
              params: { priorityId: item.id, assignmentId: assignment.id },
            }"
            class="assignment"
          >
            <cp-card dense flushBody outlined>
              <table>
                <tbody>
                  <tr>
                    <cp-tooltip top>
                      <template #activator="{on}">
                        <td class="assignment__alert" v-on="on">
                          <cp-icon
                            v-if="assignment.action_required"
                            icon="alert-round"
                          />
                        </td>
                      </template>
                      <h1 v-if="assignment.assignment_requires_attention">
                        Awaiting Action From You
                      </h1>
                      <h1 v-if="assignment.unread_comments > 0">
                        {{ assignment.unread_comments }} Unread
                        {{ "Message" | pluralize(assignment.unread_comments) }}
                      </h1>
                      <h1 v-if="assignment.overdue">
                        Assignment Overdue
                      </h1>
                    </cp-tooltip>
                    <td class="assignment__details">
                      <v-row align="center" justify="space-between">
                        <v-col class="col" cols="12" md="6">
                          {{ assignment.action_item_title }}
                        </v-col>
                        <v-col class="col" cols="12" md="3">
                          {{ assignment.assigned_to }}
                        </v-col>
                        <v-col class="col" cols="12" md="3"
                          >Due: {{ dueBy(assignment) }}</v-col
                        >
                      </v-row>
                    </td>
                    <td class="assignment__status">
                      {{ assignment.status_text }}
                    </td>
                    <td class="assignment__actions">
                      <cp-icon icon="chevron-right" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </cp-card>
          </router-link>
        </div>
      </template>
    </cp-table>
  </cp-card>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Priorities",

  data() {
    return {
      search: null,
      expanded: [],
      loadingResults: false,
      filters: {
        factor_code: null,
        survey_slug: null,
        region_id: null,
      },
      filteredAssignments: ["follow_up_skipped", "follow_up_completed"],
      onlyShowCurrentUserAssignments: false,
    };
  },

  computed: {
    ...mapState({
      priorities: state => state.priorities.all,
      survey_filters: state => state.priorities.survey_filters,
      date_range: state => state.priorities.date_range,
      regions: state => state.engage.regions,
    }),

    ...mapGetters("auth", ["userIs", "userLocale"]),

    isActionTeamMember() {
      return this.userIs("engage-action-team-member");
    },

    filteredPriorities() {
      return this.priorities.filter(priority => {
        return (
          !this.filters.factor_code ||
          priority.factor.code === this.filters.factor_code
        );
      });
    },

    computedHeaders() {
      let headers = [
        { text: "Score", value: "aiq", class: "score" },
        { text: "Priority", value: "body", class: "name" },
        {
          text: "Assignments",
          value: "assignment_count",
          class: "assignments",
        },
        { text: "", value: "", class: "actions", sortable: false },
      ];

      if (!this.clientFirstYear) {
        headers.splice(1, 0, {
          text: "Change",
          value: "score_delta",
          class: "score",
        });
      }

      return headers;
    },

    clientFirstYear() {
      return (
        this.date_range.previous_year === undefined ||
        this.date_range.previous_year.start_date == null
      );
    },

    resultText() {
      if (this.filteredPriorities.length <= 0 && !this.loadingResults) {
        return "No displayable results.";
      }

      return "Loading...";
    },

    regionOptions() {
      return [
        { text: "All Regions", value: null },
        ...this.regions.map(({ name, id }) => ({ text: name, value: id })),
      ];
    },
  },

  watch: {
    onlyShowCurrentUserAssignments() {
      this.$refs.table.collapseAll();
      this.$store.dispatch("priorities/fetchAll", {
        filters: this.filters,
        current_user_only: this.onlyShowCurrentUserAssignments,
      });
    },

    filters: {
      handler: function() {
        this.loadingResults = true;
        this.$refs.table.collapseAll();
        this.$store
          .dispatch("priorities/fetchAll", {
            filters: this.filters,
            current_user_only: this.onlyShowCurrentUserAssignments,
          })
          .then(() => {
            this.loadingResults = false;
          });
      },
      deep: true,
    },
  },

  created() {
    this.$store.dispatch("fetchRegions");
  },

  mounted() {
    this.loadingResults = true;
    let self = this;

    // engage roles are mutually exclusive, so this should only set this to false if...
    // the user ONLY has the role engage-action-team-member
    if (this.isActionTeamMember) this.onlyShowCurrentUserAssignments = false;

    if (this.userLocale) {
      this.$store
        .dispatch("priorities/fetchAll", {
          current_user_only: this.onlyShowCurrentUserAssignments,
        })
        .then(() => {
          this.loadingResults = false;
        });
    } else {
      this.$CpEvent.$on("user-loaded", () => {
        self.$store
          .dispatch("priorities/fetchAll", {
            current_user_only: self.onlyShowCurrentUserAssignments,
          })
          .then(() => {
            self.loadingResults = false;
          });
      });
    }
  },

  methods: {
    dueBy(assignment) {
      return moment(assignment.due_by).format("L");
    },

    showAssignments(priority, isExpanded, expand) {
      const shouldExpand = !isExpanded;
      expand(shouldExpand);

      if (shouldExpand) {
        this.$store.dispatch("priorities/fetchAssignments", {
          priority: priority,
          current_user_only: this.onlyShowCurrentUserAssignments,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ing-toggle {
  width: auto !important;

  &::v-deep .v-label {
    font-size: 14px;
  }
}

.header-background {
  background: url("/images/engage-bk-x1.jpg") repeat-x;
}

.name {
  position: relative;
}

.column-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.column-icon--center {
  left: 0;
}

.cp-card::v-deep .cp-dialog__activator {
  margin-left: 5px;
}

td.actions {
  min-width: 2.5rem;
}

::v-deep .v-data-table__wrapper table {
  min-width: 700px;
  table-layout: initial;
}
</style>
