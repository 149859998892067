<template>
  <section class="regions select-imitation">
    <div
      class="outlined-select-imitation"
      :class="{ active, error: errors }"
      @click="active = !active"
    >
      <div class="border">
        <label>{{ label }}</label>
        <v-row align="center" justify="space-between" no-gutters>
          <slot name="selection">
            <div class="value">{{ selection }}</div>
          </slot>
          <v-col cols="auto">
            <cp-icon class="select-warning" icon="warning" />
            <cp-icon class="select-arrow" icon="arrow-drop" />
          </v-col>
        </v-row>
      </div>
      <div
        v-if="messageText"
        class="v-messages"
        :class="{ 'error--text': errors }"
        style="padding: 8px 12px 0;"
      >
        <div class="v-messages__message">{{ messageText }}</div>
      </div>
    </div>
    <cp-card dense flush-body v-show="active" class="card--selector">
      <slot name="contents" :close="close">
        <cp-search
          v-if="includeSearch"
          ref="search"
          v-model="search"
          class="pa-2"
        />
        <cp-table
          :value="selected"
          :headers="headers"
          :items="items"
          selectable
          :search="search"
          disable-pagination
          hide-default-footer
          v-bind="$attrs"
          @input="$emit('input', $event)"
        >
          <template #item-columns="{item}">
            <td class="regions">{{ item.name }}</td>
            <td class="score">{{ item.score }}</td>
          </template>
        </cp-table>
      </slot>
    </cp-card>
  </section>
</template>

<script>
export default {
  props: {
    errors: { type: Array, default: null },
    message: { type: String, default: null },
    items: { type: Array, default: () => [] },
    label: { type: String, default: null },
    entity: { type: String, default: null },
    headers: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
    includeSearch: { type: Boolean, default: false },
  },

  data() {
    return {
      active: false,
      search: null,
    };
  },

  computed: {
    selected() {
      return this.value;
    },

    selection() {
      if (this.selected.length) {
        return `${this.selected.length} ${this.entity} Selected`;
      }

      return "None Selected";
    },

    messageText() {
      if (this.errors && this.errors.length) {
        return this.errors[0];
      }

      return this.message;
    },
  },

  watch: {
    active(isActive, wasActive) {
      if (!isActive && wasActive) {
        this.$emit("closed");
      }

      if (isActive && !wasActive) {
        this.$emit("active");
        this.focusSearch();
      }
    },
  },

  created() {
    this.addClickOutsideListener();
    this.addEscapeListener();
  },

  methods: {
    close() {
      this.active = false;
    },

    focusSearch() {
      if (!this.includeSearch) {
        return;
      }

      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },

    addClickOutsideListener() {
      const listener = e => {
        if (e.target === this.$el || this.$el.contains(e.target)) {
          return;
        }

        this.close();
      };

      document.addEventListener("click", listener);
      this.$once("hook:beforeDestroy", () => {
        document.removeEventListener("click", listener);
      });
    },

    addEscapeListener() {
      const listener = e => {
        if (e.key === "Escape") {
          this.close();
        }
      };

      document.addEventListener("keydown", listener);
      this.$once("hook:beforeDestroy", () => {
        document.removeEventListener("keydown", listener);
      });
    },
  },
};
</script>

<style lang="scss">
.select-imitation.regions {
  position: relative;

  // TODO this is...not great
  .v-data-table-header tr:first-child th:first-child {
    padding-left: 4px;
    width: 42px !important;
    min-width: 42px !important;
  }

  .skinny-col.no-redirect {
    padding-left: 4px !important;
  }

  .outlined-select-imitation.error {
    background-color: transparent !important;
  }

  .card--selector {
    position: absolute;
    top: 42px;
    z-index: 2;
    width: 100%;
  }
}
</style>
