<template>
  <cp-dialog
    :show-model.sync="action_items.detail_dialog_open"
    max-width="1200"
    header-background
  >
    <template #title>
      Action Item Details
    </template>

    <template #body>
      <h6 class="text-subtitle-1 font-weight-bold mb-2">{{ item.title }}</h6>

      <cp-card class="gray-background" outlined>
        <h6 class="font-weight-bold">Description</h6>
        <h6 class="alt-text" v-html="item.body"></h6>

        <template v-if="item.attachments.length">
          <hr />
          <h6 class="font-weight-bold mt-1">Downloadable Resources</h6>
          <single-row-link
            v-for="(attachment, index) in item.attachments"
            :key="index"
            :href="attachment.url"
            target="_blank"
            :title="attachment.name"
            class="my-2"
            showSBIcon
          />
        </template>
      </cp-card>
    </template>
  </cp-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      action_items: state => state.action_items,
    }),

    item() {
      return this.action_items.instance;
    },
  },
};
</script>
