<template>
  <section class="content">
    <v-row class="assignment-results">
      <v-col class="cols" md="6" cols="12">
        <label>Region Result(s):</label>
        <v-row
          v-for="region in assignment.target_regions"
          :key="region.id"
          class="result"
          align="center"
          justify="space-between"
          no-gutters
        >
          <div class="name">{{ region.name }}</div>
          <div>
            <span class="value">{{ region.old_score }}</span>
            <span class="change" :class="changeType(region)">
              <cp-icon
                v-if="changeType(region) == 'neutral'"
                icon="stock-no-change"
              />
              <cp-icon v-else :icon="`stock-arrow-${changeType(region)}`" />
              {{ region.new_score || "--" }}
            </span>
          </div>
        </v-row>
      </v-col>
      <v-col class="cols" md="6" cols="12">
        <label>Position Result(s):</label>
        <v-row
          v-for="position in assignment.target_positions"
          :key="position.id"
          class="result"
          align="center"
          justify="space-between"
          no-gutters
        >
          <div class="name">{{ position.name }}</div>
          <div>
            <span class="value">{{ position.old_score }}</span>
            <span class="change" :class="changeType(position)">
              <cp-icon
                v-if="changeType(position) == 'neutral'"
                icon="stock-no-change"
              />
              <cp-icon v-else :icon="`stock-arrow-${changeType(position)}`" />
              {{ position.new_score || "--" }}
            </span>
          </div>
        </v-row>
      </v-col>
      <v-col class="cols" cols="12">
        <h1 class="mt-3">
          The score summary above shows what your target regions and positions
          were at when this assignment was started, next to what we recorded
          after your follow-up surveys were completed.
        </h1>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    assignment: { type: Object, default: null },
  },

  methods: {
    changeType(item) {
      if (!item.new_score) {
        return "neutral";
      }

      if (item.new_score > item.old_score) {
        return "up";
      } else if (item.new_score < item.old_score) {
        return "down";
      }

      return "neutral";
    },
  },
};
</script>
