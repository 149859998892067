<template>
  <div class="timeline-wrapper">
    <div
      class="timeline-inner"
      :class="{ 'has-current-step-text': currentStepDetailText }"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="timeline__block"
        :class="{ 'is-active': index <= currentStepIndex }"
      >
        <template v-if="currentStepDetailText">
          <h1
            v-if="index == currentStepIndex"
            class="timeline__block__current-step-text active-index"
          >
            {{ currentStepDetailText }}
          </h1>
          <h1 v-else class="timeline__block__current-step-text no-content">
            &nbsp;
          </h1>
        </template>
        <div class="timeline__block__dot"></div>
        <div class="timeline__block__text">{{ step }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [
        "Assigned",
        "In Progress",
        "Ready for Review",
        "Complete",
      ],
    },
    currentStepIndex: { type: Number, default: 0 },
    currentStepDetailText: { type: String, default: null },
  },
};
</script>

<style lang="scss">
.timeline-wrapper {
  width: 100%;
}

.timeline-inner {
  display: flex;
  padding: 16px;
  text-align: center;

  &.has-current-step-text {
    padding-top: 40px;
  }
}

.timeline__block {
  flex: 1 1 0px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 9px;
    right: 50%;
    background-color: $gray-4;
    z-index: 1;
  }

  &.is-active:before {
    background-color: $green--main;
  }

  &:first-child:before {
    display: none;
  }
}

.timeline__block__dot {
  width: 20px;
  height: 20px;
  background-color: $gray-4;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  z-index: 2;

  .is-active & {
    background-color: $green--main;
  }
}

.timeline__block__text {
  font-size: 14px;
}

.timeline__block__current-step-text {
  position: absolute;
  top: -40px;
  left: 100%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 3;
  color: $white;
  background-color: $green--main;
  border-radius: 4px;
  padding: 2px 8px;

  &.active-index:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $green--main transparent transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -5px;
  }

  &.no-content {
    visibility: hidden;
  }
}
</style>
