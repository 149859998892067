import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"mt-n4 mb-2",attrs:{"no-gutters":"","align":"center"}},[(_vm.$slots['header'])?_c(VCol,[_c('h3',{staticClass:"page-header my-0 header-name"},[_c('span',{staticClass:"name"},[_vm._t("header")],2)])]):_vm._e(),(_vm.$slots['page-info'])?_c(VCol,{attrs:{"align":"right"}},[_c('cp-btn',{staticClass:"page-info-trigger",class:{ 'info-is-active': _vm.infoActive },attrs:{"icon":""},on:{"click":function($event){_vm.infoActive = !_vm.infoActive}}},[_c('cp-icon',{attrs:{"icon":"info-outlined"}})],1),_c(VDialog,{attrs:{"width":"900","origin":"top right 0"},model:{value:(_vm.infoActive),callback:function ($$v) {_vm.infoActive=$$v},expression:"infoActive"}},[_c(VCard,[_c(VCardTitle,[_vm._t("page-info-title")],2),_c(VCardText,[_vm._t("page-info")],2)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }