import { Api } from "@cp/lib";
const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  namespaced: true,

  state: {
    instance: {
      attachments: [],
    },
    collection: [],
    detail_dialog_open: false,
  },

  mutations: {
    setInstance(state, item) {
      state.instance = item;
    },

    setCollection(state, items) {
      state.collection = items;
    },

    setDetailDialog(state, isOpen) {
      state.detail_dialog_open = isOpen;
    },

    resetInstance(state) {
      state.instance = {
        attachments: [],
      };
    },
  },

  actions: {
    openDetailDialog({ commit }, item) {
      commit("setInstance", item);
      commit("setDetailDialog", true);
    },

    closeDetailDialog({ commit }) {
      commit("resetInstance");
      commit("setDetailDialog", false);
    },
  },
};
