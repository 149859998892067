<template>
  <div>
    <PrioritiesIndex />

    <cp-fullscreen-modal
      :show.sync="open"
      :title="modal.title"
      @fullscreenModalClosed="$router.push(backPath)"
    >
      <component :is="modal.component" v-bind="$attrs" />
    </cp-fullscreen-modal>
  </div>
</template>

<script>
import PrioritiesIndex from "./PrioritiesIndex";
import PrioritiesShow from "./PrioritiesShow";
import PrioritiesHistory from "./PrioritiesHistory";
import AssignmentsHistory from "./AssignmentsHistory";
import AssignmentsCreate from "./AssignmentsCreate";
import AssignmentsShow from "./AssignmentsShow";

const createEditBackPath = ({ priorityId, assignmentId }) => {
  let to = `/priorities/${priorityId}`;
  if (assignmentId) {
    to += `/assignments/${assignmentId}`;
  }
  return to;
};

const modals = {
  "priorities.show": {
    component: PrioritiesShow,
    title: "Priority Details",
    backPath: () => `/priorities`,
  },
  "priorities.history": {
    component: PrioritiesHistory,
    title: "History",
    backPath: ({ id }) => `/priorities/${id}`,
  },
  "assignments.history": {
    component: AssignmentsHistory,
    title: "Assignment History",
    backPath: ({ priorityId }) => `/priorities/${priorityId}`,
  },
  "assignments.create": {
    component: AssignmentsCreate,
    title: "Create an Assignment",
    backPath: createEditBackPath,
  },
  "assignments.edit": {
    component: AssignmentsCreate,
    title: "Edit Assignment",
    backPath: createEditBackPath,
  },
  "assignments.show": {
    component: AssignmentsShow,
    title: "Assignment Details",
    backPath: ({ priorityId }) => `/priorities/${priorityId}`,
  },
};

export default {
  components: { PrioritiesIndex },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    modal() {
      return modals[this.$route.name] || {};
    },
    backPath() {
      return this.modal && this.modal.backPath(this.$route.params);
    },
  },
  watch: {
    "$route.name": {
      handler(val) {
        if (modals.hasOwnProperty(val)) {
          this.$nextTick(() => (this.open = true));
        } else {
          this.$nextTick(() => (this.open = false));
        }
      },
      immediate: true,
    },
  },
};
</script>
