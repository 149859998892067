<template>
  <cp-card v-if="priority.id" class="priorities--history">
    <h2 class="text-subtitle-1 font-weight-bold mb-3">{{ priority.body }}</h2>
    <div class="assignments">
      <div class="header">
        <v-row align="center" justify="space-between" no-gutters>
          <v-col style="width: calc(100% - 360px);">
            Assignment
          </v-col>
          <v-col style="max-width: 240px;">
            Assigned To
          </v-col>
          <v-col style="max-width: 180px;">
            Completed
          </v-col>
        </v-row>
      </div>
      <div class="items">
        <div
          v-for="assignment in assignments"
          :key="assignment.id"
          class="pattern__single-line-click"
        >
          <router-link
            :to="{
              name: 'assignments.history',
              params: { priorityId: id, assignmentId: assignment.id },
            }"
            class="assignment"
          >
            <cp-card dense flushBody outlined class="background--gray py-2">
              <v-row
                align="center"
                justify="space-between"
                no-gutters
                class="font-weight-bold"
              >
                <v-col style="width: calc(100% - 360px);">
                  <div class="swift-letter-boxed d-inline-block mr-2">
                    <cp-icon icon="swift-bunny-icon" />
                  </div>
                  {{ assignment.action_item_title }}
                </v-col>
                <v-col style="max-width: 240px;">
                  {{ assignment.assigned_to }}
                </v-col>
                <v-col style="max-width: 180px;">
                  {{ completedDate(assignment) }}
                </v-col>
                <v-col class="single-line-click__last" cols="auto">
                  <cp-icon class="last__icon" icon="chevron-right" />
                </v-col>
              </v-row>
            </cp-card>
          </router-link>
        </div>
      </div>
    </div>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "PrioritiesHistory",

  props: {
    id: { required: true },
  },

  computed: {
    ...mapState({
      priority: state => state.priorities.priority,
    }),

    assignments() {
      return this.priority.assignments.filter(assignment =>
        ["follow_up_skipped", "follow_up_completed"].includes(assignment.status)
      );
    },
  },

  created() {
    this.$store.dispatch("priorities/fetchOne", this.id).then(() => {
      this.$store.dispatch("priorities/fetchAssignments", {
        priority: this.priority,
      });
    });
  },

  methods: {
    completedDate(assignment) {
      return moment(assignment.completed_at).format("L");
    },
  },
};
</script>
