import survey from "./survey";
import engage from "./engage";
import dashboard from "./dashboard";
import priorities from "./priorities";
import assignments from "./assignments";
import reports from "./reports";
import translations from "./translations";
import action_items from "./action_items";

export default {
  survey,
  engage,
  dashboard,
  priorities,
  assignments,
  reports,
  translations,
  action_items,
  root: {
    state: {
      initial_page_load: true,
    },

    getters: {
      copyright() {
        const year = new Date().getFullYear();

        return `<a href="mailto:support@swiftbunny.com" class="white--text">support@swiftbunny.com</a> | <a href="#" class="white--text">${translations.terms.privacy_policy}</a></br>© ${year} Swift Bunny LLC All Rights Reserved.`;
      },
    },

    mutations: {
      setInitialPageLoad(state, value) {
        this.state.initial_page_load = value;
      },
    },
  },
};
