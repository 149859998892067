import { Api } from "@cp/lib";
import prioritiesData from "./static/priorities";
import priorityData from "./static/priority";
import breakdownData from "./static/dashboardBreakdown";
import assignmentsData from "./static/assignments";
import { mergeAttributes } from "@/lib/utils.js";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  namespaced: true,

  state: {
    all: [],
    date_range: {},
    survey_filters: [],
    priority: { action_items: [] },
    breakdown: {
      position_categories: [],
      regions: [],
      ranges: {
        position_categories: { min: 0, max: 100 },
        regions: { min: 0, max: 100 },
      },
    },
  },

  mutations: {
    clearPriority(state) {
      state.priority = { action_items: [] };
      state.breakdown = {
        position_categories: [],
        regions: [],
        ranges: {
          position_categories: { min: 0, max: 100 },
          regions: { min: 0, max: 100 },
        },
      };
    },

    setPriorities(state, { priorities, date_range, survey_filters }) {
      state.date_range = date_range;
      state.survey_filters = survey_filters;
      state.all = priorities.map(priority => {
        return mergeAttributes(priority, {
          factor: {
            code: priority.attributes.key_factor_code,
            name: priority.attributes.key_factor_name,
          },
          assignments: [],
        });
      });
    },

    setPriority(state, { priority }) {
      state.priority = mergeAttributes(priority, {
        percentile_rank: priority.percentile_rank
          ? priority.percentile_rank + "th"
          : "-",
        response_rate: priority.response_rate
          ? priority.response_rate + "%"
          : "-",
        factor: {
          code: priority.attributes.key_factor_code,
          name: priority.attributes.key_factor_name,
        },
        assignments: [],
      });
    },

    setAssignments(state, { priority, assignments }) {
      priority.assignments = mergeAttributes(assignments);
    },

    setBreakdown(state, breakdown) {
      state.breakdown = breakdown;
    },
  },

  actions: {
    fetchAll(
      { state, commit, rootState },
      { filters = {}, current_user_only = false }
    ) {
      return bApi
        .authorize()
        .get("/priorities", {
          survey_slug: filters.survey_slug,
          factor_code: filters.factor_code,
          region_id: filters.region_id,
          current_user_only: current_user_only,
        })
        .then(({ data, date_range, surveys }) => {
          commit("setPriorities", {
            priorities: data,
            date_range: date_range,
            survey_filters: surveys,
          });
        });
    },

    async fetchOne({ commit }, id) {
      await bApi
        .authorize()
        .get(`/priorities/${id}`)
        .then(({ data }) => commit("setPriority", { priority: data }));
    },

    fetchAssignments({ commit }, { priority, current_user_only = false }) {
      bApi
        .authorize()
        .get(`/priorities/${priority.id}/assignments`, {
          current_user_only: current_user_only,
        })
        .then(({ data }) =>
          commit("setAssignments", { priority, assignments: data })
        );
    },

    fetchBreakdown({ commit }, { id, filters }) {
      bApi
        .authorize()
        .get(`/priorities/${id}/breakdown`, filters)
        .then(({ data }) => commit("setBreakdown", data));
    },

    clearPriority({ commit }) {
      commit("clearPriority");
    },
  },
};
