import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"md":"6","sm":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold pb-4"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('cp-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('cp-btn',_vm._g({attrs:{"x-small":"","icon":"","color":"primary"}},on),[_c('cp-icon',{staticStyle:{"width":"14px"},attrs:{"icon":"info-outlined"}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])],1),_vm._l((_vm.priorities),function(priority,i){return _c('router-link',{key:priority.id,staticStyle:{"line-height":"1.2","text-decoration":"none"},attrs:{"to":{
      name: 'priorities.show',
      params: { id: priority.id },
    },"title":priority.body}},[_c(VRow,{staticClass:"ing-priority-row",attrs:{"align":"center","dense":""}},[_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"cols":"auto"}},[_c(VSheet,{staticClass:"pa-2 px-3 text-left",staticStyle:{"color":"#ffffff","width":"58px","white-space":"nowrap"},attrs:{"rounded":"","color":("background--swift-" + (priority.factor.name.toLowerCase()))}},[_vm._v(" "+_vm._s(Math.round(priority.aiq))+"  "+_vm._s(priority.factor.code)+" ")])],1),_c(VCol,{staticClass:"flex-grow-1 flex-shrink-1"},[_vm._v(" "+_vm._s(priority.body)+" ")]),_c(VCol,{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"cols":"auto"}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),(i != _vm.priorities.length - 1)?_c(VDivider,{staticClass:"my-2"}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }