<template>
  <v-col md="6" sm="12">
    <div class="text-subtitle-1 font-weight-bold pb-4">
      {{ title }}
      <cp-tooltip right>
        <template #activator="{on}">
          <cp-btn x-small icon color="primary" v-on="on">
            <cp-icon icon="info-outlined" style="width: 14px;" />
          </cp-btn>
        </template>
        {{ tooltip }}
      </cp-tooltip>
    </div>

    <router-link
      v-for="(priority, i) in priorities"
      :key="priority.id"
      :to="{
        name: 'priorities.show',
        params: { id: priority.id },
      }"
      :title="priority.body"
      style="line-height: 1.2; text-decoration: none;"
    >
      <v-row align="center" dense class="ing-priority-row">
        <v-col cols="auto" class="flex-grow-0 flex-shrink-1">
          <v-sheet
            rounded
            class="pa-2 px-3 text-left"
            :color="`background--swift-${priority.factor.name.toLowerCase()}`"
            style="color: #ffffff; width: 58px; white-space: nowrap;"
          >
            {{ Math.round(priority.aiq) }}&nbsp;&nbsp;{{ priority.factor.code }}
          </v-sheet>
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-1">
          {{ priority.body }}
        </v-col>
        <v-col cols="auto" class="flex-grow-0 flex-shrink-1">
          <v-icon>mdi-chevron-right</v-icon>
        </v-col>
      </v-row>
      <v-divider v-if="i != priorities.length - 1" class="my-2" />
    </router-link>
  </v-col>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    tooltip: { type: String, default: null },
    priorities: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped>
.ing-priority-row {
  transition: background-color 0.25s ease-in-out;
  border-radius: 6px;
  color: $gray-4;

  &:hover {
    color: $primary;
    background-color: $gray-1;

    &::v-deep .v-icon.v-icon {
      color: $primary;
    }
  }
}
</style>
