<template>
  <cp-card :title-color="assignment.completed_at ? '#009a44' : '#0070A8'">
    <template #title>
      <cp-icon
        :icon="assignment.completed_at ? 'assignment-complete' : 'assignment'"
        x-large
        color="rgba(255, 255, 255, .5)"
      />
      <div class="d-inline-block ml-4" style="vertical-align: middle;">
        <h6 class="white--text">
          {{ assignment.action_item.title }}
        </h6>
        <div class="text-body-2 white--text">
          Addressing Priority:
          <a
            :href="`/priorities/${assignment.topic.id}`"
            target="_blank"
            class="white--text font-weight-bold"
          >
            {{ assignment.topic.body }}
          </a>
        </div>
      </div>
    </template>

    <template #actions>
      <router-link
        v-if="canEdit"
        #default="{navigate}"
        :to="{
          name: 'assignments.edit',
          params: { id: assignment.id },
        }"
      >
        <cp-btn color="white" text @click="navigate" rounded>
          <v-icon small class="mr-2">mdi-pencil</v-icon>
          Edit
        </cp-btn>
      </router-link>
      <v-icon v-if="assignment.completed_at" color="white" large>
        mdi-check-decagram
      </v-icon>
    </template>

    <section class="content pa-0">
      <timeline
        v-if="
          assignment.follow_up_statuses.includes(assignment.current_status) &&
            assignment.status != 'follow_up_completed'
        "
        id="timeline"
        class="mt-5"
        :steps="assignment.follow_up_statuses"
        :current-step-index="
          assignment.follow_up_statuses.indexOf(assignment.current_status)
        "
        :current-step-detail-text="timelineDetailText"
      />
      <timeline
        v-else-if="
          !['follow_up_skipped', 'follow_up_completed'].includes(
            assignment.status
          )
        "
        id="timeline"
        class="mt-5"
        :steps="assignment.statuses"
        :current-step-index="
          assignment.statuses.indexOf(assignment.current_status)
        "
        :current-step-detail-text="timelineDetailText"
      />

      <assignment-results
        v-if="shouldShowScoreSummary"
        :assignment="assignment"
      />

      <v-row class="pa-4 pt-0" align="center">
        <v-col align="right">
          <h1 v-if="assignment.status == 'refused'">
            {{ assignee.full_name }} has refused this assignment.
            <span v-if="isProgramManager"
              >Edit this assignment to select a new Action Team Member.</span
            >
          </h1>
          <template v-if="canAccept">
            <cp-dialog width="500" header-background inline-activator>
              <template #activator>
                <cp-btn color="error" text large>
                  Refuse
                </cp-btn>
              </template>
              <template #title>
                Refusing Assignment
              </template>
              <template #body>
                While not required, please considering leaving a note to inform
                your Program Manager why you won't be accepting this assignment.
                <cp-textarea
                  v-model="statusUpdateNote"
                  outlined
                  label="Reason for Refusal"
                  hide-details
                  class="mt-2 mb-2"
                />
              </template>
              <template #actions="{close}">
                <v-col cols="auto" class="ml-auto">
                  <cp-btn color="error" text large @click="close">
                    Cancel
                  </cp-btn>
                </v-col>
                <v-col cols="auto">
                  <cp-btn
                    color="primary"
                    large
                    @click="updateStatus('refused', close)"
                  >
                    Refuse Assignment
                  </cp-btn>
                </v-col>
              </template>
            </cp-dialog>
            <cp-btn color="primary" large @click="updateStatus('in_progress')">
              Accept
            </cp-btn>
          </template>

          <template v-if="canSubmit">
            <cp-dialog width="500" header-background inline-activator>
              <template #activator>
                <cp-btn color="primary" large>
                  Submit for Approval
                </cp-btn>
              </template>
              <template #title>
                Submitting Assignment
              </template>
              <template #body>
                Are you sure you're ready to mark your assignment completed, and
                notify your Program Manager?
              </template>
              <template #actions="{close}">
                <v-col cols="auto" class="ml-auto">
                  <cp-btn color="error" text @click="close">
                    Cancel
                  </cp-btn>
                </v-col>
                <v-col cols="auto">
                  <cp-btn
                    color="primary"
                    @click="updateStatus('awaiting_review', close)"
                  >
                    Submit Assignment
                  </cp-btn>
                </v-col>
              </template>
            </cp-dialog>
          </template>

          <template v-if="canApprove || canStartFollowup">
            <cp-dialog
              width="500"
              header-background
              inline-activator
              title="Rejecting Assignment"
            >
              <template v-if="canApprove" #activator>
                <cp-btn color="error" text large>
                  Reject
                </cp-btn>
              </template>
              <template #title>
                Rejecting Assignment
              </template>
              <template #body>
                While not required, please considering leaving a note to inform
                your Action Team Member why you aren't approving this
                assignment.
                <cp-textarea
                  v-model="statusUpdateNote"
                  outlined
                  label="Reason for Rejecting"
                  hide-details
                  class="mt-2 mb-2"
                />
              </template>
              <template #actions="{close}">
                <v-col cols="auto" class="ml-auto">
                  <cp-btn color="error" text large @click="close">
                    Cancel
                  </cp-btn>
                </v-col>
                <v-col cols="auto">
                  <cp-btn
                    color="primary"
                    large
                    @click="updateStatus('reject', close)"
                  >
                    Reject Assignment
                  </cp-btn>
                </v-col>
              </template>
            </cp-dialog>
            <cp-dialog
              width="500"
              header-background
              inline-activator
              title="Completing the Assignment"
            >
              <template v-if="canApprove" #activator>
                <cp-btn color="primary" large>
                  Approve
                </cp-btn>
              </template>
              <template #title>
                Completing the Assignment
              </template>
              <template #body="{close}">
                <v-stepper v-model="followUpStep" class="elevation-0">
                  <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                      <p>
                        Once you have approved the Assignment as complete, you
                        may choose to conduct an optional Follow-Up Survey. This
                        brief survey is sent only to employees in the targeted
                        regions and positions and asks them to evaluate the
                        Priority which was impacted by this Assignment. The
                        purpose of the Follow-Up Survey is to determine if the
                        actions taken had the desired impact.
                      </p>
                      <v-row>
                        <v-col cols="auto" class="ml-auto">
                          <cp-btn color="error" text @click="close">
                            Cancel
                          </cp-btn>
                        </v-col>
                        <v-col cols="auto">
                          <cp-btn
                            color="primary"
                            @click="
                              updateStatus(
                                'ready_for_follow_up',
                                advanceStepper
                              )
                            "
                          >
                            Approve Assignment
                          </cp-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0">
                      <p>
                        If you'd like to conduct a follow-up survey, we
                        recommend waiting a period of
                        {{ assignment.action_item.follow_up_delay }} days before
                        the survey goes out. This allows time for your employees
                        to experience the results of this Assignment before
                        being asked to measure it again. You may enter a custom
                        time period, however, if you'd rather adjust the send
                        date.
                      </p>

                      <cp-input
                        v-model="followUpDelay"
                        autofocus
                        label="Custom Days to delay"
                        type="number"
                        min="1"
                        placeholder="Number of Days"
                        outlined
                        hide-details
                        oninput="validity.valid||(value='');"
                      />

                      <h1 class="mt-3 mb-3">
                        Follow-Up Survey Send Date:
                        <strong>{{ followUpDay }}</strong>
                      </h1>

                      <v-row>
                        <v-col cols="auto" class="ml-auto">
                          <cp-btn color="error" text @click="close">
                            Cancel
                          </cp-btn>
                        </v-col>
                        <v-col cols="auto">
                          <cp-btn
                            color="primary"
                            @click="updateStatus('awaiting_follow_up', close)"
                          >
                            Schedule Follow-Up
                          </cp-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </template>
            </cp-dialog>
          </template>

          <template v-if="canStartFollowup">
            <cp-dialog width="500" header-background inline-activator>
              <template #activator>
                <cp-btn color="error" text large>
                  Skip Follow-Up
                </cp-btn>
              </template>
              <template #title>
                Skip Follow-Up?
              </template>
              <template #body>
                Skipping the follow-up process will place this assignment into
                the history of this Priority. Are you sure you want to do this?
              </template>
              <template #actions="{ close }">
                <v-col cols="auto" class="ml-auto">
                  <cp-btn color="error" text large @click="close">
                    Cancel
                  </cp-btn>
                </v-col>
                <v-col cols="auto">
                  <cp-btn
                    color="primary"
                    large
                    @click="updateStatus('follow_up_skipped', close)"
                  >
                    Yes, Skip Follow-Up
                  </cp-btn>
                </v-col>
              </template>
            </cp-dialog>

            <cp-dialog
              width="500"
              header-background
              inline-activator
              title="Configuring the Follow-Up"
            >
              <template #activator>
                <cp-btn color="primary" large>
                  Configure Follow-Up
                </cp-btn>
              </template>
              <template #title>
                Configuring the Follow-Up
              </template>
              <template #body="{close}">
                <p>
                  If you'd like to conduct a follow-up survey, we recommend
                  waiting a period of
                  {{ assignment.action_item.follow_up_delay }} days before the
                  survey goes out. This allows time for your employees to
                  experience the results of this Assignment before being asked
                  to measure it again. You may enter a custom time period,
                  however, if you'd rather adjust the send date.
                </p>

                <cp-input
                  v-model="followUpDelay"
                  autofocus
                  label="Custom Days to delay"
                  type="number"
                  min="1"
                  placeholder="Number of Days"
                  outlined
                  hide-details
                  oninput="validity.valid||(value='');"
                />

                <h1 class="mt-3 mb-3">
                  Follow-Up Survey Send Date:
                  <strong>{{ followUpDay }}</strong>
                </h1>

                <v-row>
                  <v-col cols="auto" class="ml-auto">
                    <cp-btn color="error" text @click="close">
                      Cancel
                    </cp-btn>
                  </v-col>
                  <v-col cols="auto">
                    <cp-btn
                      color="primary"
                      @click="updateStatus('awaiting_follow_up', close)"
                    >
                      Schedule Follow-Up
                    </cp-btn>
                  </v-col>
                </v-row>
              </template>
            </cp-dialog>
          </template>

          <template v-if="readyForFinalReview">
            <cp-dialog
              width="500"
              header-background
              inline-activator
              title="Filing Away the Assignment"
            >
              <template #activator>
                <cp-btn color="primary" large>
                  Acknowledge Results
                </cp-btn>
              </template>
              <template #title>
                Filing Away the Assignment
              </template>
              <template #body>
                <h1>
                  You will always be able to review the results of this
                  assignment underneath the History section of this Priority.
                </h1>
              </template>
              <template #actions="{ close }">
                <v-col cols="auto" class="ml-auto">
                  <cp-btn color="error" text @click="close">
                    Cancel
                  </cp-btn>
                </v-col>
                <v-col cols="auto">
                  <cp-btn
                    color="primary"
                    @click="updateStatus('follow_up_completed', close)"
                  >
                    Complete Assignment Follow-Up
                  </cp-btn>
                </v-col>
              </template>
            </cp-dialog>
          </template>

          <template v-if="showAdvanceBtn">
            <cp-btn color="primary" large @click="advanceFollowUpProcess()">
              Advance Time (QA ONLY)
            </cp-btn>
          </template>
        </v-col>
      </v-row>
    </section>
  </cp-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { isProduction } from "@cp/constants";

export default {
  props: {
    assignment: { type: Object, default: null },
  },

  data() {
    return {
      followUpStep: 1,
      followUpDelay: null,
      statusUpdateNote: null,
      scoreDisplayStatuses: [
        "follow_up_skipped",
        "awaiting_final_review",
        "follow_up_completed",
      ],
    };
  },

  computed: {
    ...mapState("auth", ["hasToken"]),
    ...mapGetters("auth", ["userIs"]),
    ...mapGetters("auth", {
      userId: "userClientCompoundId",
    }),
    isProgramManager() {
      return this.userIs("engage-program-manager");
    },

    creator() {
      return this.assignment.creator;
    },

    assignee() {
      return this.assignment.assignee;
    },

    timelineDetailText() {
      let noun = "";
      let verb = "";
      let text = null;
      let validStatus =
        this.assignment.statuses.includes(this.assignment.current_status) &&
        this.assignment.status != "ready_for_follow_up";
      let validFollowUpStatus = this.assignment.follow_up_statuses.includes(
        this.assignment.current_status
      );

      if (validStatus) {
        switch (this.assignment.status) {
          case "assigned":
            noun =
              this.userId == this.assignee.id ? "You" : this.assignee.full_name;
            verb = "Accept";
            break;
          case "in_progress":
            noun =
              this.userId == this.assignee.id ? "You" : this.assignee.full_name;
            verb = "Submit";
            break;
          case "awaiting_review":
            noun =
              this.userId == this.creator.id ? "You" : this.creator.full_name;
            verb = "Approve";
            break;
        }

        text = `Waiting on ${noun} to ${verb}`;
      } else if (validFollowUpStatus) {
        switch (this.assignment.status) {
          case "awaiting_follow_up":
            let sending_tomorrow =
              Math.abs(this.assignment.follow_up_days_remaining) < 1;

            let days_remaining_wording = `${
              this.assignment.follow_up_days_remaining
            } ${this.$pluralize(
              "day",
              Math.abs(this.assignment.follow_up_days_remaining)
            )} to go!`;

            if (sending_tomorrow) {
              days_remaining_wording = "Sending Tomorrow!";
            }

            text = `${this.assignment.follow_up_delay_override ||
              this.assignment.action_item
                .follow_up_delay} day delay. ${days_remaining_wording}`;
            break;
          case "awaiting_results":
            let ready_tomorrow =
              Math.abs(this.assignment.awaiting_results_days_remaining) < 1;

            let days_until_ready_wording = `${
              this.assignment.awaiting_results_days_remaining
            } ${this.$pluralize(
              "day",
              Math.abs(this.assignment.awaiting_results_days_remaining)
            )} to go!`;

            if (ready_tomorrow) {
              days_until_ready_wording = "Ready Tomorrow!";
            }

            text = `Collecting Results. ${days_until_ready_wording}`;
            break;
          case "awaiting_final_review":
            noun =
              this.userId == this.creator.id ? "You" : this.creator.full_name;
            text = `Waiting on ${noun} to Review Results`;
            break;
        }
      }

      return text;
    },

    followUpDay() {
      return moment()
        .add(
          this.followUpDelay || this.assignment.action_item.follow_up_delay,
          "day"
        )
        .format("dddd, LL");
    },

    canEdit() {
      if (
        this.isProgramManager &&
        !["ready_for_follow_up", "follow_up_skipped"].includes(
          this.assignment.status
        ) &&
        !this.assignment.follow_up_statuses.includes(
          this.assignment.current_status
        )
      ) {
        return true;
      }

      return false;
    },

    userIsAssignee() {
      return this.userId === this.assignee.id;
    },

    canAccept() {
      if (this.assignment.status !== "assigned") return false;
      return this.isProgramManager || this.userIsAssignee;
    },

    canSubmit() {
      if (this.assignment.status !== "in_progress") return false;
      return this.isProgramManager || this.userIsAssignee;
    },

    canApprove() {
      if (this.assignment.status !== "awaiting_review") return false;
      return this.isProgramManager;
    },

    canStartFollowup() {
      if (this.assignment.status !== "ready_for_follow_up") return false;
      return this.isProgramManager;
    },

    showAdvanceBtn() {
      if (isProduction || this.hasToken || !this.isProgramManager) return false;
      return ["awaiting_follow_up", "awaiting_results"].includes(
        this.assignment.status
      );
    },

    readyForFinalReview() {
      if (
        this.isProgramManager &&
        this.assignment.status == "awaiting_final_review"
      ) {
        return true;
      }

      return false;
    },

    shouldShowScoreSummary() {
      if (this.scoreDisplayStatuses.includes(this.assignment.status)) {
        return true;
      }

      return false;
    },
  },

  created() {
    if (this.$route.query.action) {
      switch (this.$route.query.action) {
        case "approve":
          if (
            this.assignment.status == "awaiting_review" &&
            this.isProgramManager
          ) {
            this.updateStatus("ready_for_follow_up");
          }
          break;
      }
    }
  },

  methods: {
    updateStatus(status, callbackFunction = false) {
      this.$store
        .dispatch("updateAssignment", {
          data: {
            assignment: {
              status: status,
              follow_up_delay_override: this.followUpDelay,
              note_attributes: {
                body: this.statusUpdateNote,
              },
            },
          },
          id: this.assignment.id,
        })
        .then(() => {
          if (callbackFunction) {
            callbackFunction();
          }
        });

      this.statusUpdateNote = null;
    },

    advanceFollowUpProcess() {
      this.$store.dispatch("advanceFollowUpProcess", this.assignment.id);
    },

    advanceStepper() {
      this.followUpStep++;
    },

    daysRemaining(date) {
      let currentDate = moment().endOf("day");
      let resultDate = moment(date).endOf("day");
      let days = resultDate.diff(currentDate, "days");

      return days;
    },
  },
};
</script>

<style lang="scss">
.messages__wrapper {
  position: relative;
  display: inline-block;

  .icon {
    margin-right: 8px;
  }
}

.messages__badge {
  position: absolute;
  top: -4px;
  right: -4px;
  color: $white;
  background-color: $red;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  border: 2px solid $white;
  box-sizing: content-box;
  z-index: 1;
}
</style>
