<template>
  <v-col lg="8" cols="12">
    <div class="survey-question__input">
      <div class="input input--text-box">
        <v-text-field
          v-model="value"
          class="input__field"
          hide-details
          single-line
          type="number"
          flat
          background-color="white"
          filled
          min="0"
        />
      </div>
    </div>
  </v-col>
</template>

<script>
import InteractsWithResponse from "@mixins/InteractsWithResponse";
const responseKey = "numeric_entry";

export default {
  mixins: [InteractsWithResponse],

  data() {
    return {
      responseKey,
      value: this.responseValue(responseKey),
    };
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.addResponse(this.value);
      }
    },
  },
};
</script>
