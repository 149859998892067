<template>
  <div>
    <div v-for="(item, index) in localItems" :key="index">
      <single-bar-chart-horizontal :item="item">
        <template v-if="index == items.data.length - 1" #steps>
          <div class="bar__range">
            <v-row no-gutters justify="space-between">
              <span v-for="value in steps" :key="value">{{ value }}</span>
            </v-row>
          </div>
        </template>
      </single-bar-chart-horizontal>
    </div>
  </div>
</template>

<script>
import GetsMinMax from "@mixins/GetsMinMax";

export default {
  mixins: [GetsMinMax],

  props: {
    items: { type: Object, required: true, default: () => {} },
  },

  computed: {
    localItems() {
      return this.items.data.map(item => {
        return {
          ...item,
          percentage: {
            sbx: this.getPercentage(item.sbx),
            value: this.getPercentage(item.current_value),
          },
          previousPercentage: this.getPercentage(item.previous_value),
        };
      });
    },

    min() {
      // we want min to be more than 5 less than items.min.
      //                          and divisible by 5
      return this.items.min - 5 - (this.items.min % 5);
    },

    max() {
      return this.items.max;
    },

    values() {
      return [
        ...this.items.data.map(i => i.current_value),
        ...this.items.data.map(i => i.sbx),
      ].filter(current_value => current_value);
    },

    steps() {
      const steps = [];

      for (let i = this.min; i <= this.max; i += this.step) {
        steps.push(i);
      }

      return steps;
    },
  },

  methods: {
    getPercentage(value) {
      return value ? ((value - this.min) * 100) / (this.max - this.min) : 0;
    },
  },
};
</script>
